import React, { useState, useEffect, useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import GanttChartView from './ganttChartView';
import dateUtils from './dateUtils';
 
import { CustomAlert } from '../ui/customAlert';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import TemplateSelectionInterface from './TemplateSelectionInterfacePlanif';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { ChevronRight, ChevronDown, Plus, Trash2, ArrowUp, ArrowDown, Minimize, Maximize, ArrowLeftCircle, Save, BarChart2, GanttChart, GanttChartIcon, LucideGanttChart, GanttChartSquareIcon, SaveAllIcon, Copy, Undo2Icon, PlusCircle, PlusCircleIcon, Settings2, Check, AlertCircle, HelpCircle, X, RefreshCcwDot, User, UserIcon, Users, Search, Loader2 } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';



  // Define interfaces for the data structure
  interface Tarea {
    id: number;
    nombre: string;
    responsable:  User[];
    progreso: number;
    fecha_inicio: string;
    fecha_termino: string;
    descriptor: string;
    organismo: string;
    dependencia: number | null;
    enabled: boolean;
    orden: number;
    isClosed: boolean;
  }

  interface Agrupador {
    nombre: string;
    enabled: boolean;
    descriptor: string;
    organismo: string;
    tareas: Tarea[];
    orden: number;
  }


  interface ProcessData {
    nombre: string;
    proceso: string;
    subprocesos: Subproceso[];
  }

  interface DependencyChain {
    task: Task;
    taskIndex: number;
    dependents: DependencyChain[];
  }

// Actualizar la interfaz Task
interface Task {
  id: number;
  name: string;
  responsible: User[]; // Cambiar de string a User[]
  progress: number;
  start: string;
  end: string;
  descriptor: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId?: number }[];
  enabled: boolean; 
  isTramitacion?: boolean;
  subtasks?: SubTask[];
  hidden?: boolean;
  orden: number;
  isClosed: boolean;
}



// Actualizar la interfaz SubTask
interface SubTask {
  id: number;
  name: string;
  responsible: User[];
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean; 
  hidden?: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  isClosed: boolean;
}



interface Group {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: Task[];
  enabled: boolean; // Nueva propiedad
  descriptor: string; 
  organismo: string;
  orden: number;

}

interface TasksState {
  name: string;
  groups: Group[];
}

interface EditingField {
  type: 'title' | 'group' | 'task' | 'subtask' | null;
  groupIndex: number | null;
  taskIndex: number | null;
  subtaskIndex?: number | null;
  field: string | null;
}


interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
}

interface Subproceso {
  id: number;
  nombre: string;
  agrupadores: Agrupador[];
  subproceso: string;
  color?: string; // Agregamos el campo color

}

const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31); // Ajusta este valor para cambiar la velocidad de la animación

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded-b relative border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  </div>
  );
};






interface ResolutionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (type: string, customType?: string) => void;
}



const ResolutionModal: React.FC<ResolutionModalProps> = ({ isOpen, onClose, onSave }) => {
  const [selectedType, setSelectedType] = useState<string>('');
  const [customType, setCustomType] = useState<string>('');

  const handleSave = () => {
    if (selectedType === 'OTRO' && customType) {
      onSave(selectedType, customType);
    } else if (selectedType) {
      onSave(selectedType);
    }
    onClose();
  };

  const resolutionTypes = [
    { type: 'APROBADO', icon: <Check className="w-5 h-5 text-green-500" />, color: 'bg-green-100 hover:bg-green-200' },
    { type: 'RECHAZADO', icon: <X className="w-5 h-5 text-red-500" />, color: 'bg-red-100 hover:bg-red-200' },
    { type: 'DESISTIMIENTO', icon: <AlertCircle className="w-5 h-5 text-yellow-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { type: 'SILENCIO ADMINISTRATIVO POSITIVO', icon: <Check className="w-5 h-5 text-green-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { type: 'SILENCIO ADMINISTRATIVO NEGATIVO', icon: <X className="w-5 h-5 text-red-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { type: 'OTRO', icon: <HelpCircle className="w-5 h-5 text-blue-500" />, color: 'bg-blue-100 hover:bg-blue-200' },
  ];

  return (
    <Dialog width='500px' isOpen={isOpen} onClose={onClose}>
      <DialogContent className="bg-white rounded-xl shadow-xl">
        <DialogHeader>
          <DialogTitle>
            <div className="text-2xl font-bold text-gray-800">Tipo de Resolución</div>
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 mt-4">
          {resolutionTypes.map(({ type, icon, color }) => (
            <div key={type} className={`flex items-center p-3 rounded-lg transition-all duration-200 ${selectedType === type ? `${color} ring-2 ring-offset-2 ring-blue-500` : 'hover:bg-gray-100'}`}>
              <input
                type="checkbox"
                id={type}
                checked={selectedType === type}
                onChange={() => setSelectedType(type)}
                className="sr-only"
              />
              <label htmlFor={type} className="flex items-center cursor-pointer w-full">
                <div className="mr-3">{icon}</div>
                <span className="text-lg font-medium text-gray-700">{type}</span>
              </label>
            </div>
          ))}
          {selectedType === 'OTRO' && (
            <div className="mt-3 pl-8">
              <input
                type="text"
                value={customType}
                onChange={(e) => setCustomType(e.target.value)}
                placeholder="Especifique otro tipo"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <div className="mt-6 flex justify-end space-x-2">
            <Button onClick={onClose} variant="outline">
              Cancelar
            </Button>
            <Button 
              onClick={handleSave} 
              className="bg-teal-500 text-white hover:bg-teal-600 transition-colors duration-200"
              disabled={!selectedType || (selectedType === 'OTRO' && !customType)}
            >
              Guardar
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};





interface TaskStatusFieldProps {
  task: {
    isClosed: boolean;
    progress: number;
  };
  subprocessColor: string;
  groupIndex: number;
  taskIndex: number;
  onSave: (type: string, groupIndex: number, taskIndex: number, field: string, value: any) => void;
  disabled?: boolean;
}

const TaskStatusField: React.FC<TaskStatusFieldProps> = ({
  task,
  subprocessColor,
  groupIndex,
  taskIndex,
  onSave,
  disabled = false
}) => {
  // Determinar el estilo basado en el estado
  const getStatusStyle = () => {
    if (task.isClosed) {
      
      return { 
        backgroundImage: `repeating-conic-gradient(${subprocessColor}  0% 25%, #ffffff 0% 50%)`,
        backgroundSize: '10px 10px',
        border: `1px solid ${subprocessColor}`,
        borderRadius: '50%',
      };
    }
    return {
      backgroundColor: '#e5e7eb',
      border: '1px solid #9ca3af',
      borderRadius: '50%',
    };
  };

  const handleToggleStatus = () => {
    if (disabled) return;
     
    const newStatus = !task.isClosed;
    onSave('task', groupIndex, taskIndex, 'isClosed', newStatus);
    // También actualizar el progreso
    onSave('task', groupIndex, taskIndex, 'progress', newStatus ? 100 : 0);
  };

  return (
    <div
      onClick={handleToggleStatus}
      className={`w-6 h-6 mx-auto cursor-pointer transition-all duration-200 flex items-center justify-center hover:opacity-80 ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      style={getStatusStyle()}
      title={task.isClosed ? "Tarea cerrada" : "Tarea abierta"}
    >
      </div>
  );
};


// Primero, actualicemos la interfaz de props
interface SubtaskStatusFieldProps {
  subtask: {
    isClosed: boolean;
    progress: number;
  };
  subprocessColor: string;
  groupIndex: number;
  taskIndex: number;
  subtaskIndex: number;
  onSave: (type: 'title' | 'group' | 'task' | 'subtask', groupIndex: number, taskIndex: number, field: string, value: any, subtaskIndex?: number) => void;
  disabled?: boolean;
}

const SubtaskStatusField: React.FC<SubtaskStatusFieldProps> = ({
  subtask,
  subprocessColor,
  groupIndex,
  taskIndex,
  subtaskIndex,
  onSave,
  disabled = false
}) => {
  const getStatusStyle = () => {
    if (subtask.isClosed) {
      return {
        backgroundImage: `repeating-conic-gradient(${subprocessColor}  0% 25%, #ffffff 0% 50%)`,
        backgroundSize: '10px 10px',
        border: `1px solid ${subprocessColor}`,
        borderRadius: '50%',
      };
    }
    return {
      backgroundColor: '#e5e7eb',
      border: '1px solid #9ca3af',
      borderRadius: '50%',
    };
  };

  const handleToggleStatus = () => {
    if (disabled) return;
    
    const newStatus = !subtask.isClosed;
    // Asegurarse de que el tipo coincida con la definición
    onSave('subtask', groupIndex, taskIndex, 'isClosed', newStatus, subtaskIndex);
    onSave('subtask', groupIndex, taskIndex, 'progress', newStatus ? 100 : 0, subtaskIndex);

    // Si es necesario, actualizar también el estado de la tarea padre
    const allSubtasksClosed = true; // Aquí deberías implementar la lógica para verificar si todas las subtareas están cerradas
    if (allSubtasksClosed) {
      onSave('task', groupIndex, taskIndex, 'isClosed', true);
      onSave('task', groupIndex, taskIndex, 'progress', 100);
    }
  };

  return (
    <div
      onClick={handleToggleStatus}
      className={`w-6 h-6 mx-auto cursor-pointer transition-all duration-200 flex items-center justify-center hover:opacity-80 ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      style={getStatusStyle()}
      title={subtask.isClosed ? "Subtarea cerrada" : "Subtarea abierta"}
    />
  ); 
};


const getResolutionIcon = (resolutionType: string) => {
  switch (resolutionType) {
    case 'APROBADO':
      return <Check className="w-6 h-6 text-green-500" />;
    case 'RECHAZADO':
      return <X className="w-5 h-5 text-red-500" />;
    case 'DESISTIMIENTO':
      return <AlertCircle className="w-5 h-5 text-yellow-500" />;
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return <Check className="w-5 h-5 text-green-500" />;
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return <X className="w-5 h-5 text-red-500" />;
    case 'No especificado':
      return <Settings2 className="w-5 h-5 text-black" />;
    default:
      return <HelpCircle className="w-5 h-5 text-blue-500" />;
  }
};

const getResolutionColor = (resolutionType: string) => {
  switch (resolutionType) {
    case 'APROBADO':
      return "text-green-500";
    case 'RECHAZADO':
      return "text-red-500";
    case 'DESISTIMIENTO':
      return "text-yellow-500";
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return "text-green-500";
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return "text-red-500";
    default:
      return "text-black";
  }
};


interface MultiUserAutocompleteProps {
  users: User[];
  selectedUsers: User[];
  onSelect: (users: User[]) => void;
  placeholder?: string;
}


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}




const MultiUserAutocomplete: React.FC<MultiUserAutocompleteProps> = ({ 
  users, 
  selectedUsers, 
  onSelect, 
  placeholder 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id)
      ));
    } else {
      const filtered = users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id) &&
        (`${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.departamento.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, users, selectedUsers]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (user: User) => {
    const newSelectedUsers = [...selectedUsers, user];
    onSelect(newSelectedUsers);
    setSearchTerm('');
    setIsOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleRemoveUser = (userId: number) => {
    const newSelectedUsers = selectedUsers.filter(user => user.id !== userId);
    onSelect(newSelectedUsers);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchTerm === '' && selectedUsers.length > 0) {
      handleRemoveUser(selectedUsers[selectedUsers.length - 1].id);
    }
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <div className="p-2 border border-gray-300 rounded min-h-[42px] flex flex-wrap gap-2 bg-white">
        {selectedUsers.map((user) => (
          <div
            key={user.id}
            className="inline-flex items-center gap-1 bg-blue-100 text-blue-800 rounded px-2 py-1 text-sm"
          >
            <span>{`${user.firstname} ${user.lastname}`}</span>
            <button
              type="button"
              onClick={() => handleRemoveUser(user.id)}
              className="text-blue-600 hover:text-blue-800"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder={selectedUsers.length === 0 ? placeholder : ''}
          className="flex-grow outline-none text-sm min-w-[120px]"
        />
      </div>

      {isOpen && filteredUsers.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              onClick={() => handleSelect(user)}
              className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              <div className="font-medium">{`${user.firstname} ${user.lastname}`}</div>
              <div className="text-xs text-gray-600">
                <span className="mr-2">{user.email}</span>
                <span className="text-gray-500">{user.departamento}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};





interface UserSelectorModalProps {
  users: User[];
  selectedUsers: User[];
  onUsersChange: (users: User[]) => void;
  enabled?: boolean;
  taskId: number;   // Añadido para identificar la tarea
  groupId: number;
}

const UserSelectorModal: React.FC<UserSelectorModalProps> = ({
  users,
  selectedUsers: initialSelectedUsers = [],
  onUsersChange,
  enabled = true,
  taskId,
  groupId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isNewTask = taskId < 0; // Helper para identificar tareas nuevas
  const [localSelectedUsers, setLocalSelectedUsers] = useState<User[]>(Array.isArray(initialSelectedUsers) ? initialSelectedUsers : []);


 

  // Función para abrir el modal y determinar si es una tarea nueva
  const handleOpenModal = () => {
    if (!enabled) return;
    setIsOpen(true);
  };

  const loadAssignedUsers = async () => {
    try {
      setIsLoading(true);
  
      if (isNewTask) {
        setLocalSelectedUsers(initialSelectedUsers);
        return;
      }
  
      // For existing tasks, first check if there are any locally selected users
      if (initialSelectedUsers && initialSelectedUsers.length > 0) {
        setLocalSelectedUsers(initialSelectedUsers);
        return;
      }
  
      // If no local values, fetch from database
      const response = await fetch(
        `http://localhost:3000/php/pages/adm_planificacion/get_task_users.php?taskId=${taskId}&groupId=${groupId}`
      );
  
      if (!response.ok) {
        throw new Error('Error al cargar los usuarios asignados');
      }
  
      const data = await response.json();
  
      if (data.success && Array.isArray(data.users)) {
        setLocalSelectedUsers(data.users);
        onUsersChange(data.users);
      }
    } catch (error) {
      console.error('Error loading assigned users:', error);
      toast.error('Error al cargar los usuarios asignados');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadAssignedUsers();
    }
  }, [isOpen, taskId, groupId]);

  const handleCloseModal = () => {
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);

    // Si es una tarea nueva, solo actualizamos el estado local
    if (isNewTask) {
      onUsersChange(localSelectedUsers);
      handleCloseModal();
      return;
    }

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_users.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskId,
          groupId,
          userIds: localSelectedUsers.map(user => user.id)
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error al guardar los responsables');
      }

      toast.success('Responsables actualizados exitosamente');
      onUsersChange(localSelectedUsers);
      handleCloseModal();
    } catch (error) {
      console.error('Error saving users:', error);
      toast.error(error instanceof Error ? error.message : 'Error al guardar los responsables');
    } finally {
      setIsSaving(false);
    }
  };

  // Filtrado de usuarios
  const filteredUsers = useMemo(() => {
    return users.filter((user: User) => {
      const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
      const searchTerms = searchTerm.toLowerCase().split(' ');
      return searchTerms.every(term => searchString.includes(term));
    });
  }, [users, searchTerm]);

  return (
    <>
      <div 
        onClick={handleOpenModal} 
        className={`cursor-pointer flex items-center ml-8 ${enabled ? 'hover:opacity-80' : 'opacity-50 cursor-not-allowed'}`}
        title={localSelectedUsers.length > 0 
          ? `Responsables: ${localSelectedUsers.map(u => `${u.firstname} ${u.lastname}`).join(', ')}` 
          : 'Sin responsables asignados'}
      >
        <UserIcon 
          className={`w-5 h-5 mr-2 ${localSelectedUsers.length > 0 ? 'text-green-500' : 'text-gray-400'}`}
        />
      </div>



      <Dialog isOpen={isOpen} onClose={handleCloseModal} width="600px">
        <div className="overflow-hidden">
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex items-center space-x-2">
              <Users className="w-6 h-6 text-white" />
              <DialogTitle>
                <span className="text-xl font-semibold text-white">
                  Seleccionar Responsables
                </span>
              </DialogTitle>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {localSelectedUsers.length} usuario(s) seleccionado(s)
            </p>
          </div>

          <DialogContent className="px-6 py-4">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando usuarios...</span>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar por nombre, email o departamento..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                </div>

                {/* Sección de usuarios seleccionados */}
                <div className="bg-teal-50 p-4 rounded-lg border border-teal-100 mb-4">
                  <h3 className="text-sm font-medium text-teal-800 mb-2">Usuarios Seleccionados</h3>
                  <div className="flex flex-wrap gap-2">
                    {localSelectedUsers.map((user) => (
                      <div
                        key={user.id}
                        className="inline-flex items-center gap-2 bg-white text-teal-700 px-3 py-1 rounded-full text-sm border border-teal-200 hover:bg-teal-50 transition-colors"
                      >
                        <UserIcon className="w-4 h-4" />
                        <span>{`${user.firstname} ${user.lastname}`}</span>
                        <button
                          onClick={() => setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id))}
                          className="ml-1 text-teal-400 hover:text-teal-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    {localSelectedUsers.length === 0 && (
                      <p className="text-sm text-teal-600 italic">No hay usuarios seleccionados</p>
                    )}
                  </div>
                </div>

                {/* Lista de usuarios disponibles */}
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    {filteredUsers.length} usuario(s) encontrado(s)
                  </h3>
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto">
                    {filteredUsers.map((user) => (
                      <button
                        key={user.id}
                        onClick={() => {
                          const isSelected = localSelectedUsers.some(u => u.id === user.id);
                          if (isSelected) {
                            setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id));
                          } else {
                            setLocalSelectedUsers(prev => [...prev, user]);
                          }
                        }}
                        className={`flex items-center justify-between w-full p-3 rounded-lg border transition-all ${
                          localSelectedUsers.some(u => u.id === user.id)
                            ? 'bg-teal-50 border-teal-200 text-teal-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50'
                        }`}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-teal-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
          </DialogContent>

          <DialogFooter>
            <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 border-t border-gray-200">
              <Button
                onClick={handleCloseModal}
                variant="outline"
                className="px-4 py-2"
                disabled={isSaving}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSave}
                className="bg-teal-500 text-white hover:bg-teal-600 px-4 py-2"
                disabled={isSaving || isLoading}
              >
                {isSaving ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Guardando...
                  </>
                ) : (
                  'Confirmar Selección'
                )}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};


const Planificacion: React.FC = () => {
  const [tasks, setTasks] = useState<TasksState>({
    name: "Plantilla 1",
    groups: []
  });
  const [editingField, setEditingField] = useState<EditingField>({ type: null, groupIndex: null, taskIndex: null, field: null });
  const [editValue, setEditValue] = useState("");
  const [isGloballyExpanded, setIsGloballyExpanded] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [showSelection, setShowSelection] = useState(true);
  const [processData, setProcessData] = useState<ProcessData | null>(null);

  const [labelMessage, setLabelMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [saveAsError, setSaveAsError] = useState<string | null>(null);
const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
const [selectedProcess, setSelectedProcess] = useState('');
const [processes, setProcesses] = useState<Process[]>([]);
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});
const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
const [users, setUsers] = useState<User[]>([]);
const [suggestedSubprocesses, setSuggestedSubprocesses] = useState<{ [key: string]: string }>({});
const [isSimagiTemplate, setIsSimagiTemplate] = useState(false);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaSubprocesos, setPlantillaSubprocesos] = useState<string[]>([]);
const [showGanttModal, setShowGanttModal] = useState(false);


const [expandedTramitacion, setExpandedTramitacion] = useState<{ [key: number]: { [key: number]: boolean } }>({});


const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
const [currentResolutionSubtask, setCurrentResolutionSubtask] = useState<{ groupIndex: number, taskIndex: number, subtaskIndex: number } | null>(null);



const ensureDependsOnArray = (dependsOn: any): { groupId: number; taskId: number; subtaskId: number }[] => {
  if (!dependsOn) return [];
  if (Array.isArray(dependsOn)) return dependsOn;
  if (typeof dependsOn === 'number') return [{ groupId: 0, taskId: 0, subtaskId: dependsOn }];
  return [];
};


  // Add function to fetch users
  const fetchUsers = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showLabelMessage('error', 'Error al cargar la lista de usuarios');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);



const getCurrentDate = (): string => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};


const handleResolutionTypeChange = (groupIndex: number, taskIndex: number, subtaskIndex: number, type: string, customType?: string) => {
  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const subtask = newTasks.groups[groupIndex].tasks[taskIndex].subtasks[subtaskIndex];
    subtask.resolutionType = type === 'OTRO' ? customType : type;
    return newTasks;
  });
};

const openResolutionModal = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
  setCurrentResolutionSubtask({ groupIndex, taskIndex, subtaskIndex });
  setResolutionModalOpen(true);
};



const toggleTramitacion = (groupIndex: number, taskIndex: number) => {
  setExpandedTramitacion(prev => ({
    ...prev,
    [groupIndex]: {
      ...(prev[groupIndex] || {}),
      [taskIndex]: !(prev[groupIndex]?.[taskIndex] || false)
    }
  }));
};



useEffect(() => {
  setSelectedCodigoProyecto('');
    setSelectedNombreProyecto('');
    setSubprocesos([]);
  const fetchProcesses = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };
  fetchProcesses();
}, []);




// Función para asignar colores únicos a subprocesos
const assignSubprocessColors = useCallback(() => {
  const colors: {[key: string]: string} = {};
  subprocesos.forEach((subproceso, index) => {
    if (!colors[subproceso.nombre]) {
      colors[subproceso.nombre] = subproceso.color || "";
    }
  });
  setSubprocessColors(colors);

}, [subprocesos]);

useEffect(() => {
  assignSubprocessColors();
 
}, [subprocesos, assignSubprocessColors]);



const fetchSubprocesos = async (codigo: string) => {
  try {
    const response = await fetch(`http://localhost:3000/php/pages/transmittal/get_subprocesos.php?codigo=${codigo}`);
    const data = await response.json();
   
    if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
      const subprocesosWithColors = data.subprocesos.map((subproceso: Subproceso, index: number) => ({
        ...subproceso,
        color: subproceso.color || ""
      }));
      setSubprocesos(subprocesosWithColors);
    } else {
      console.error('Error al obtener los subprocesos', data);
      setSubprocesos([]);
    }
  } catch (error) {
    console.error('Error de red', error);
    setSubprocesos([]);
  }
};




  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setLabelMessage({ type, message });
    setTimeout(() => {
      setLabelMessage(null);
    }, duration);
  };


  const [selectedProject, setSelectedProject] = useState<string>('');



  const updateProcessTemplate = async (processId: string, templateName: string) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/update_process_template.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ processId, templateName }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar la plantilla del proceso');
      }
  
      const result = await response.json();
    //  console.log('Plantilla del proceso actualizada:', result);
      showLabelMessage('success', 'Plantilla del proceso actualizada exitosamente');
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar la plantilla del proceso');
    }
  };
 


  const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    // Aquí podrías cargar los subprocesos correspondientes al proyecto seleccionado
  };
 

  const renderOrganismRow = (group: Group, groupIndex: number) => {
    const descriptor = group.descriptor || '';
    const subprocessColor = subprocessColors[group.subprocess] || "#CCCCCC";
  
    return (
      <div className="ml-5 px-4 text-sm text-white">
        <div style={{ backgroundColor: darkenColor(subprocessColor, 60) }} className="text-white inline-flex items-center rounded-full px-3 text-sm font-semibold ml-2">
          <span className="mr-2 text-white">{descriptor === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}</span>
          {renderEditableField('group', groupIndex, null, 'organism', group.organismo)}
        </div>
      </div>
    );
  };


  const updateTemplateName = async (newName: string) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_template_name.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nombreOriginal: tasks.name,
          nuevoNombre: newName,
          proceso: selectedCodigoProyecto
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar el nombre de la plantilla');
      }
  
      const result = await response.json();
      if (result.success) {
    //    console.log('Nombre de la plantilla actualizado:', result);
        showLabelMessage('success', 'Nombre de la plantilla actualizado exitosamente');
      } else {
        throw new Error(result.error || 'Error al actualizar el nombre de la plantilla');
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar el nombre de la plantilla');
    }
  };

    // Añadir un efecto para actualizar los subprocesos de los grupos cuando se carga la plantilla
    useEffect(() => {
      
      if (plantillaSubprocesos.length > 0 && subprocesos.length > 0) {
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) => {
            const plantillaSubproceso = plantillaSubprocesos[index] || "";
            const matchingSubprocess = subprocesos.find(sp => sp.subproceso === plantillaSubproceso);
            return {
              ...group,
              subprocess: matchingSubprocess ? matchingSubprocess.subproceso : ""
            };
          })
        }));
      }
    }, [plantillaSubprocesos, subprocesos]);


  const updateSelectedProcess = useCallback(async (processCode: string) => {
    const selectedProcess = processes.find(p => p.codigo === processCode);
    if (selectedProcess) {
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      await fetchSubprocesos(selectedProcess.codigo);
      setIsProcessLoaded(true);
    }
  }, [processes]);


  // Actualiza la función handleTemplateSelected
  const handleProcessSelected = async (processId: number, processName: string, processCode: string) => {
    await fetchProcessData(processId);
    setShowSelection(false);
  };

  

  const renderSubprocessSelect = (group: Group, groupIndex: number) => {
  
  
    const getTextColor = (backgroundColor: string): string => {
      // Convert the hex color to RGB
      const r = parseInt(backgroundColor.slice(1, 3), 16);
      const g = parseInt(backgroundColor.slice(3, 5), 16);
      const b = parseInt(backgroundColor.slice(5, 7), 16);
  
      // Calculate the luminosity
      const luma = 0.299 * r + 0.587 * g + 0.114 * b;
  
      // Return the text color based on luminosity
      return luma < 128 ? 'white' : 'black';
  };
  
    const subprocessColor = subprocessColors[group.subprocess] || "";
    const textColor = getTextColor(subprocessColor);
  
    return (
      <div className="flex flex-col p-2 rounded-lg" style={{backgroundColor: subprocessColor}}>
        <span className={`font-bold flex mr-2 text-${textColor}`}>Subproceso:</span>
        <select
          value={group.subprocess}
          onChange={(e) => handleSubprocessChange(groupIndex, e)}
          className={`border rounded-md p-2 ${!group.enabled ? 'bg-gray-200 text-gray-500' : 'bg-white'}`}
          disabled={!group.enabled}
        >
          <option value="">Sin subproceso</option>
          {subprocesos.map((subprocess) => (
            <option 
              key={subprocess.id} 
              value={subprocess.nombre}
            >
              {subprocess.nombre}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const handleSubprocessChange = (groupIndex: number, e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSubprocess = e.target.value;
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, index) => 
        index === groupIndex ? { ...group, subprocess: selectedSubprocess } : group
      )
    }));
    // Actualiza los colores de los subprocesos si es necesario
    setSubprocessColors(prevColors => ({
      ...prevColors,
      [selectedSubprocess]: prevColors[selectedSubprocess] || getSubprocessColor(selectedSubprocess)
    }));
  };


/*
  const convertProcessDataToTasksState = (processData: any): TasksState => {
    const groups = processData.subprocesos.flatMap((subproceso: any, index: number) => 
      subproceso.agrupadores.map((agrupador: any): Group => {
    //    console.log(`Procesando grupo ${groupIndex}, agrupador: ${agrupador.nombre}`);
        return {
        name: agrupador.nombre,
        expanded: true,
        subprocess: subproceso.nombre,
        agrupador: agrupador.nombre,
        enabled: agrupador.enabled !== undefined ? agrupador.enabled : true,
        descriptor: agrupador.descriptor || 'GESTIÓN',
        organismo: agrupador.organismo || '',
        tasks: agrupador.tareas.map((tarea: any): Task => {
    //      console.log(`Tarea: ${tarea.nombre}, Dependencia: ${tarea.dependencia}`);
          return {
          id: tarea.id,
          name: tarea.nombre,
          responsible: tarea.responsable || '',
          progress: tarea.progreso || 0,
          start: tarea.fecha_inicio,
          end: tarea.fecha_termino,
          descriptor: tarea.descriptor || '',
          duration: tarea.duration || '',
          organism: tarea.organismo || '',
          enabled: tarea.enabled !== undefined ? tarea.enabled : true,
          dependsOn: tarea.dependencia 
            ? (typeof tarea.dependencia === 'string' 
                ? tarea.dependencia.split(',').map((id: string) => ({
                    groupId: index,  // Usamos el índice del subproceso como groupId
                    taskId: parseInt(id.trim(), 10)
                  }))
                : [{ groupId: index, taskId: tarea.dependencia }])
            : [], // Si no hay dependencia, devuelve un array vacío
      };
    })
  };
})
);
console.log('Estado de tareas convertido:', { name: processData.nombre, groups });
return { name: processData.nombre, groups };
};
*/

// Update the property mapping for subtasks
const convertProcessDataToTasksState = (processData: any): TasksState => {
  const groups = processData.subprocesos
    .flatMap((subproceso: any, index: number) =>
      subproceso.agrupadores.map((agrupador: any): Group => ({
        name: agrupador.nombre,
        expanded: true,
        subprocess: subproceso.nombre,
        agrupador: agrupador.nombre,
        enabled: agrupador.enabled !== undefined ? agrupador.enabled : true,
        descriptor: agrupador.descriptor || 'GESTIÓN',
        organismo: agrupador.organismo || '',
        orden: agrupador.orden || 0,
        tasks: agrupador.tareas
          .map((tarea: any): Task => {
            // Convert responsables string to User array if needed
            let responsables: User[] = [];
            if (tarea.responsable) {
              if (typeof tarea.responsable === 'string') {
                try {
                  responsables = JSON.parse(tarea.responsable);
                } catch (e) {
                  console.warn('Error parsing responsables:', e);
                  responsables = [];
                }
              } else if (Array.isArray(tarea.responsable)) {
                responsables = tarea.responsable;
              }
            }

            return {
              id: tarea.id,
              name: tarea.nombre,
              responsible: responsables,
              progress: tarea.progreso || 0,
              start: tarea.fecha_inicio,
              end: tarea.fecha_termino,
              descriptor: tarea.descriptor || '',
              duration: tarea.duracion || 0,
              isClosed: tarea.isClosed !== undefined ? tarea.isClosed : false,
              organism: tarea.organismo || '',
              enabled: tarea.enabled !== undefined ? tarea.enabled : true,
              dependsOn: tarea.dependencia
                ? (typeof tarea.dependencia === 'string'
                    ? tarea.dependencia.split(',').map((id: string) => ({
                        groupId: index,
                        taskId: parseInt(id.trim(), 10)
                      }))
                    : [{ groupId: index, taskId: tarea.dependencia }])
                : [],
              isTramitacion: tarea.nombre === 'TRAMITACIÓN',
              orden: tarea.orden || 0,
              subtasks: tarea.subtasks
                ? tarea.subtasks
                    .map((subtask: any): SubTask => {
                      // Handle subtask responsables similarly
                      let subtaskResponsables: User[] = [];
                      if (subtask.responsable) {
                        if (typeof subtask.responsable === 'string') {
                          try {
                            subtaskResponsables = JSON.parse(subtask.responsable);
                          } catch (e) {
                            console.warn('Error parsing subtask responsables:', e);
                            subtaskResponsables = [];
                          }
                        } else if (Array.isArray(subtask.responsable)) {
                          subtaskResponsables = subtask.responsable;
                        }
                      }

                      // Properly map subtask fields using the correct property names
                      return {
                        id: subtask.id,
                        name: subtask.nombre || '',           // Map nombre to name
                        responsible: subtaskResponsables,
                        progress: subtask.progreso || 0,      // Map progreso to progress
                        start: subtask.fecha_inicio || '',    // Map fecha_inicio to start
                        end: subtask.fecha_termino || '',     // Map fecha_termino to end
                        duration: subtask.duracion || 0,      // Map duracion to duration
                        organism: subtask.organismo || '',
                        dependsOn: Array.isArray(subtask.dependsOn) 
                          ? subtask.dependsOn.map((dep: any) => dep.subtaskId)    
                          : [],
                        enabled: subtask.enabled !== undefined ? subtask.enabled : true,
                        type: subtask.tipo || subtask.type || subtask.st_type || 'OTROS',        // Map tipo to type
                        isClosed: subtask.isClosed !== undefined ? subtask.isClosed : false,
                        resolutionType: subtask.resolucion_tipo, // Map resolucion_tipo to resolutionType
                        orden: subtask.orden || 0
                      };
                    })
                    .sort((a: SubTask, b: SubTask) => a.orden - b.orden)
                : []
            };
          })
          .sort((a: Task, b: Task) => a.orden - b.orden)
      }))
    )
    .sort((a: Group, b: Group) => a.orden - b.orden);

  return {
    name: processData.nombre,
    groups: groups
  };
};
// Función auxiliar para parsear las dependencias
const parseDependencies = (dependencias: any, groupId: number, taskId: number): { groupId: number; taskId: number; subtaskId: number }[] => {
  if (!dependencias) return [];
  if (typeof dependencias === 'string') {
    try {
      const parsed = JSON.parse(dependencias);
      if (Array.isArray(parsed)) {
        return parsed.map(id => ({ groupId, taskId, subtaskId: id }));
      }
    } catch (e) {
      console.error('Error parsing dependencies:', e);
    }
  }
  if (Array.isArray(dependencias)) {
    return dependencias.map(id => ({ groupId, taskId, subtaskId: id }));
  }
  console.warn('Unexpected dependencies format:', dependencias);
  return [];
};

// Actualiza la función fetchProcessData para manejar correctamente los subprocesos
const fetchProcessData = async (processId: number) => {
  try {
    
    const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?id=${processId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log('Datos recibidos:', JSON.stringify(data, null, 2));
    
    setProcessData(data);
    // Extraer subprocesos del proceso cargado y asignar colores si no los tienen
    const subprocessesWithColors: Subproceso[] = data.subprocesos.map((sp: Subproceso) => ({
      ...sp,
      color: sp.color || getSubprocessColor(sp.nombre) // Usa getSubprocessColor si no hay color definido
    }));
    setSubprocesos(subprocessesWithColors);
    
    const newSubprocessColors: {[key: string]: string} = {};
    subprocessesWithColors.forEach(sp => {
      newSubprocessColors[sp.nombre] = sp.color || "";
    });
    
    setSubprocessColors(newSubprocessColors);
      // Usar la nueva función para convertir los datos del proceso
      const tasksState = convertProcessDataToTasksState(data);
      setTasks(tasksState);

    setSelectedCodigoProyecto(data.proceso);
    setSelectedNombreProyecto(data.nombre);
  } catch (error) {
    console.error('Error fetching process data:', error);
  }
};
const calculateDuration = (start: string, end: string): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  return Number((diffTime / (1000 * 60 * 60 * 24 * 7)).toFixed(2));
};


  const formatDuration = (duration: number): string => {
    if (duration % 1 === 0) {
      // Si no tiene decimales (es un número entero), devuelve el número como entero
      return duration.toString();
    } else {
      // Si tiene decimales, muestra dos decimales
      return duration.toFixed(2);
    }
  };
  
  const calculateNewEndDate = (start: string, durationWeeks: number): string => {
    const startDate = new Date(start);
    const daysToAdd = durationWeeks * 7;
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + daysToAdd);
    return endDate.toISOString().split('T')[0];
  };

  const updateGroup = (groupIndex: number, field: string, value: any) => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, index) =>  
        index === groupIndex ? { ...group, [field]: value } : group
      )
    }));
  };

// Función auxiliar para actualizar una tarea individual
const updateTask = (groupIndex: number, taskIndex: number, field: string, value: any) => {
  setTasks(prevTasks => ({
    ...prevTasks,
    groups: prevTasks.groups.map((group, gIndex) => 
      gIndex === groupIndex
        ? {
            ...group,
            tasks: group.tasks.map((task, tIndex) => 
              tIndex === taskIndex ? { ...task, [field]: value } : task
            )
          }
        : group
    )
  }));
};
  const toggleGroup = (index: number) => {
    updateGroup(index, 'expanded', !tasks.groups[index].expanded);
  };

  const updateDependenciesOnMove = (groupIndex: number, oldIndex: number, newIndex: number) => {
    setTasks(prevTasks => {
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const group = newTasks.groups[groupIndex];
      const movedTaskId = group.tasks[oldIndex].id;

      group.tasks.forEach((task: Task) => {
        task.dependsOn = task.dependsOn.map(dep => {
          if (dep.groupId === groupIndex) {
            if (dep.taskId === movedTaskId) {
              return { ...dep, taskId: group.tasks[newIndex].id };
            } else if (oldIndex < newIndex) {
              if (dep.taskId > group.tasks[oldIndex].id && dep.taskId <= group.tasks[newIndex].id) {
                return { ...dep, taskId: dep.taskId - 1 };
              }
            } else if (oldIndex > newIndex) {
              if (dep.taskId >= group.tasks[newIndex].id && dep.taskId < group.tasks[oldIndex].id) {
                return { ...dep, taskId: dep.taskId + 1 };
              }
            }
          }
          return dep;
        });
      });

      return newTasks;
    });
  };
  const isGroupNameUnique = (name: string, currentGroupIndex: number): boolean => {
    return !tasks.groups.some((group, index) => 
      index !== currentGroupIndex && group.agrupador.toUpperCase() === name.toUpperCase()
    );
  };

  const isTaskNameUnique = (name: string, groupIndex: number, currentTaskIndex: number): boolean => {
    return !tasks.groups[groupIndex].tasks.some((task, index) => 
      index !== currentTaskIndex && task.name.toUpperCase() === name.toUpperCase()
    );
  };

  const checkDependencies = (changedTaskPosition: number, groupIndex: number) => {
    const group = tasks.groups[groupIndex];
    if (!group) {
      console.error(`No se encontró el grupo con índice ${groupIndex}`);
      return;
    }
  
    if (changedTaskPosition < 1 || changedTaskPosition > group.tasks.length) {
      console.error(`Posición de tarea inválida: ${changedTaskPosition}`);
      return;
    }
  
    const changedTask = group.tasks[changedTaskPosition - 1];
    console.log(`Verificando dependencias para la tarea "${changedTask.name}" (posición ${changedTaskPosition} en el grupo ${groupIndex + 1})`);
  
    const findDependents = (taskPosition: number): DependencyChain[] => {
      return group.tasks.reduce((acc: DependencyChain[], task, index) => {
        const hasDependency = task.dependsOn.some(dep => 
          (dep.groupId === 0 || dep.groupId === groupIndex + 1) && dep.taskId === taskPosition
        );
  
        if (hasDependency) {
          const dependents = findDependents(index + 1);
          acc.push({
            task,
            taskIndex: index,
            dependents
          });
        }
  
        return acc;
      }, []);
    };
  
    const dependencyChain = findDependents(changedTaskPosition);
  
    const printDependencyChain = (chain: DependencyChain[], level: number = 0) => {
      chain.forEach(({ task, taskIndex, dependents }) => {
        console.log(`${'  '.repeat(level)}- Tarea "${task.name}" (tarea ${taskIndex + 1})`);
        if (dependents.length > 0) {
          printDependencyChain(dependents, level + 1);
        }
      });
    };
  
    if (dependencyChain.length > 0) {
      console.log(`Cadena de dependencias para la tarea "${changedTask.name}":`);
      printDependencyChain(dependencyChain);
    } else {
      console.log(`Ninguna tarea en el grupo "${group.agrupador}" depende de la tarea "${changedTask.name}"`);
    }
  
    // Información de depuración adicional
   // console.log('Información de depuración:');
    group.tasks.forEach((task, index) => {
     // console.log(`Tarea ${index + 1}: "${task.name}", Dependencias:`, JSON.stringify(task.dependsOn));
    });
  };


  
  const handleTramitacionDependencyChange = (groupIndex: number, taskIndex: number, newDependencies: Array<{ groupId: number; taskId: number }>) => {
    setTasks(prevTasks => {
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const task = newTasks.groups[groupIndex].tasks[taskIndex];
      
      task.dependsOn = newDependencies;
  
      if (newDependencies.length > 0) {
        // Calcular la nueva fecha de inicio para TRAMITACIÓN
        const maxEndDate = Math.max(...newDependencies.map(dep => {
          const depTask = newTasks.groups[dep.groupId].tasks[dep.taskId - 1];
          return new Date(depTask.end).getTime();
        }));
        const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0]; // Añadir un día
        
        // Actualizar la fecha de inicio de TRAMITACIÓN
        task.start = newStart;
        
        // Actualizar las subtareas
        updateSubtasksStartDate(task);
      }
  
      updateTramitacionDates(newTasks, groupIndex, taskIndex);
      return newTasks;
    });
  };


  
  const handleSave = (type: 'title' | 'group' | 'task' | 'subtask', groupIndex: number | null, taskIndex: number | null, field: string, value: any, subtaskIndex?: number) => {
    if (type === 'title') {
      setTasks(prevTasks => ({ ...prevTasks, name: value }));
      updateTemplateName(value);
    } else if (type === 'group' && groupIndex !== null) {
      if (field === 'agrupador') {
        const newName = value.toUpperCase();
        if (!isGroupNameUnique(newName, groupIndex)) {
          showLabelMessage('error', 'El nombre del agrupador ya existe. Por favor, elija otro nombre.');
          return;
        }
        updateGroup(groupIndex, field, newName);
      } else if (field === 'organism') {
        const upperValue = value.toUpperCase();
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) =>
            index === groupIndex
              ? {
                  ...group,
                  organismo: upperValue, // Update the group organism to uppercase
                  tasks: group.tasks.map(task => ({
                    ...task,
                    organism: upperValue // Update task organism to uppercase
                  }))
                }
              : group
          )
        }));
      } else {
        updateGroup(groupIndex, field, value);
      }
    } else if (type === 'task' && groupIndex !== null && taskIndex !== null) {
      if (field === 'name') {
        const newName = value.toUpperCase();
        if (!isTaskNameUnique(newName, groupIndex, taskIndex)) {
          showLabelMessage('error', 'El nombre de la tarea ya existe en este grupo. Por favor, elija otro nombre.');
          return;
        }
        updateTask(groupIndex, taskIndex, field, newName);
      } else if (type === 'task' && field === 'isClosed' && groupIndex !== null && taskIndex !== null) {
        setTasks(prevTasks => {
          const newTasks = JSON.parse(JSON.stringify(prevTasks)); // Deep copy
          const group = newTasks.groups[groupIndex];
          const task = group.tasks[taskIndex];
          const currentDate = new Date().toISOString().split('T')[0];
      
          if (value) { // Si la tarea se está cerrando
            task.isClosed = true;
            task.progress = 100;
            
          
      // Verificar si la fecha de inicio es una fecha futura
      if (new Date(task.start) > new Date(currentDate)) {
        // Establecer la fecha de inicio y de término en la fecha actual si la fecha de inicio aún no ha llegado
        task.start = currentDate;
        task.end = currentDate;
      } else {
        // Establecer la fecha de término como la fecha actual si la fecha de inicio ya ha pasado
        task.end = currentDate;
      }

      // Función recursiva para actualizar fechas de tareas dependientes
       // Recursivamente actualizar las tareas que dependen de esta tarea
       const updateDependentTasks = (taskId: number) => {
        group.tasks.forEach((t: Task, idx: number) => {
          const depends = t.dependsOn.some(
            (dep: { groupId: number; taskId: number }) => 
              dep.groupId === groupIndex && dep.taskId === taskId
          );
          
          if (depends) {
            const depNewStart = group.tasks[taskId - 1].end; // Usar directamente la fecha de término
            const depDuration = calculateDuration(t.start, t.end);
            
            t.start = depNewStart;
            t.end = calculateNewEndDate(depNewStart, depDuration);
            
            // Continuar con la cadena de dependencias
           updateDependentTasks(idx + 1);
            updateDependentTasksRecursively(group.tasks, taskIndex);
         
           
          }  
        });
      };

      // Iniciar la actualización recursiva desde la tarea cerrada
      updateDependentTasks(taskIndex + 1);
   
    } else { // Si la tarea se está reabriendo
      task.isClosed = false;
      task.progress = 0;

      // Restablecer la fecha de inicio y término a las fechas originales
      const originalStart = task.originalStart || task.start; // Usar originalStart si está definido
      const originalDuration = calculateDuration(task.start, task.end); // Duración previamente definida
      task.start = originalStart;
      task.end = calculateNewEndDate(originalStart, originalDuration);

      // Función recursiva para actualizar las tareas dependientes
     // Función recursiva para actualizar las tareas dependientes
const updateDependentTasks = (taskId: number) => {
  group.tasks.forEach((dependentTask: Task, dependentIndex: number) => {
    const hasDependency = dependentTask.dependsOn.some((dep: { groupId: number; taskId: number }) => 
      dep.groupId === groupIndex && dep.taskId === taskId
    );

    if (hasDependency) {
      // La tarea dependiente debe comenzar al final de la tarea actualizada
      dependentTask.start = task.end;
      
      // Mantener la duración original de la tarea dependiente
      const depDuration = calculateDuration(dependentTask.start, dependentTask.end);
      dependentTask.end = calculateNewEndDate(dependentTask.start, depDuration);

      // Continuar la actualización recursiva de dependientes
      updateDependentTasks(dependentIndex + 1);
    }  
  });
};

      // Iniciar la actualización recursiva desde la tarea reabierta
      updateDependentTasks(taskIndex + 1);
    }

    return newTasks;
  });
}
       
       if (field === 'start' || field === 'end') {
        handleDateChange(groupIndex, taskIndex, field as 'start' | 'end', value);
        
      } else if (field === 'duration') {
        const newDuration = parseInt(value);
        if (!isNaN(newDuration)) {
          const task = tasks.groups[groupIndex].tasks[taskIndex];
          const newEnd = calculateNewEndDate(task.start, newDuration);
          handleDateChange(groupIndex, taskIndex, 'end', newEnd);
       //   checkDependencies(taskIndex+1, groupIndex);

        }
      } else if (field === 'dependsOn') {
        
        let newDependencies: { groupId: number; taskId: number }[];
        
        if (typeof value === 'string') {
          
          newDependencies = value.split(',')
            .map(pos => pos.trim())
            .filter(pos => pos !== '')
            .map(pos => ({ groupId: groupIndex, taskId: parseInt(pos) }))
            .filter(dep => !isNaN(dep.taskId) && dep.taskId > 0 && dep.taskId <= tasks.groups[groupIndex].tasks.length);
        } else if (Array.isArray(value)) {
          newDependencies = value.map(pos => ({ groupId: groupIndex, taskId: parseInt(pos) }))
            .filter(dep => !isNaN(dep.taskId) && dep.taskId > 0 && dep.taskId <= tasks.groups[groupIndex].tasks.length);
        } else {
          
          newDependencies = [];
        }
      
        newDependencies = newDependencies.filter(dep => dep.taskId !== taskIndex + 1);
      
        setTasks(prevTasks => {
          const updatedTasks = JSON.parse(JSON.stringify(prevTasks));
          const group = updatedTasks.groups[groupIndex];
          const task = group.tasks[taskIndex];
      
          // Si la tarea es TRAMITACIÓN, aplicamos la lógica especial
          if (task.isTramitacion) {
            task.dependsOn = newDependencies;
      
            if (newDependencies.length > 0) {
              // Calcular la nueva fecha de inicio para TRAMITACIÓN
              const maxEndDate = Math.max(...newDependencies.map(dep => {
                const depTask = group.tasks[dep.taskId - 1];
                return new Date(depTask.end).getTime();
              }));
              const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0];
              
              // Calcular la diferencia en días entre la fecha actual y la nueva
              const currentStartDate = new Date(task.start).getTime();
              const newStartDate = new Date(newStart).getTime();
              const daysDifference = (newStartDate - currentStartDate) / (1000 * 60 * 60 * 24);
              
              task.start = newStart;
              
              // Actualizar las subtareas manteniendo sus intervalos relativos
              if (task.subtasks) {
                task.subtasks.forEach((subtask: SubTask) => {
                  // Mover cada subtarea la misma cantidad de días
                  const subtaskStart = new Date(subtask.start);
                  const subtaskEnd = new Date(subtask.end);
                  
                  subtaskStart.setDate(subtaskStart.getDate() + daysDifference);
                  subtaskEnd.setDate(subtaskEnd.getDate() + daysDifference);
                  
                  subtask.start = subtaskStart.toISOString().split('T')[0];
                  subtask.end = subtaskEnd.toISOString().split('T')[0];
                });
          
                // Recalcular dependencias entre subtareas
                const processedSubtasks = new Set<number>();
                
                const updateDependentSubtasks = (currentSubtaskIndex: number) => {
                  if (processedSubtasks.has(currentSubtaskIndex)) return;
                  processedSubtasks.add(currentSubtaskIndex);
                  
                  if (task.subtasks) {
                    const currentSubtask = task.subtasks[currentSubtaskIndex];
                    
                    task.subtasks.forEach((dependentSubtask: SubTask, index: number) => {
                      // Verifica que dependsOn exista y sea un array antes de usar .some
                      if (Array.isArray(dependentSubtask.dependsOn) && dependentSubtask.dependsOn.some(dep => dep.subtaskId === currentSubtaskIndex + 1)) {
                        const duration = calculateDuration(dependentSubtask.start, dependentSubtask.end);
                        const previousSubtask = task.subtasks[currentSubtaskIndex];
                        
                        const newStart = previousSubtask.isClosed
                          ? previousSubtask.end
                          : addDaysToDate(previousSubtask.end, 1);
                        
                        dependentSubtask.start = newStart;
                        dependentSubtask.end = calculateNewEndDate(newStart, duration);
                        
                        updateDependentSubtasks(index);
                      }
                    });
                  }
                };

          
                // Actualizar cada subtarea que tenga dependencias
                task.subtasks.forEach((_: SubTask, index: number) => {
                  if (!processedSubtasks.has(index)) {
                    updateDependentSubtasks(index);
                  }
                });
              }
      
              // Actualizar fecha de término de TRAMITACIÓN
              if (task.subtasks && task.subtasks.length > 0) {
                const latestSubtaskEnd = new Date(Math.max(
                  ...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())
                ));
                task.end = latestSubtaskEnd.toISOString().split('T')[0];
                task.duration = calculateDuration(task.start, task.end);
              }
            }
            task.dependsOn = newDependencies;
      
            if (newDependencies.length > 0) {
              // Calcular la nueva fecha de inicio para TRAMITACIÓN
              const maxEndDate = Math.max(...newDependencies.map(dep => {
                const depTask = group.tasks[dep.taskId - 1];
                return new Date(depTask.end).getTime();
              }));
              const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0];
              
              task.start = newStart;
              
              // Actualizar las subtareas
              if (task.subtasks) {
                task.subtasks.forEach((subtask: SubTask) => {
                  if (subtask.dependsOn.length === 0) {
                    const duration = calculateDuration(subtask.start, subtask.end);
                    subtask.start = newStart;
                    subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
                  }
                });
              }
              
              // Actualizar fecha de término de TRAMITACIÓN
              if (task.subtasks && task.subtasks.length > 0) {
                const latestSubtaskEnd = new Date(Math.max(...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())));
               // task.end = latestSubtaskEnd.toISOString().split('T')[0];
                //task.duration = calculateDuration(task.start, task.end);
              }
              
            forceUpdateDates(groupIndex)
            }  
          } else {
            task.dependsOn = newDependencies;
            
            if (newDependencies.length > 0) {
              // Calcular la nueva fecha de inicio basada en las dependencias
              const maxEndDate = Math.max(...newDependencies.map(dep => {
                const depTask = group.tasks[dep.taskId - 1];
                return new Date(depTask.end).getTime();
              }));
      
              // Verificar si alguna dependencia está cerrada
              const hasClosedDependency = newDependencies.some(dep => 
                group.tasks[dep.taskId - 1].isClosed
              );
      
              // Determinar nueva fecha de inicio
              const newStart = hasClosedDependency
                ? new Date(maxEndDate).toISOString().split('T')[0] // Mismo día si depende de tarea cerrada
                : addDaysToDate(new Date(maxEndDate).toISOString().split('T')[0], 1); // Día siguiente si no
      
              const duration = calculateDuration(task.start, task.end);
              task.start = newStart;
              task.end = calculateNewEndDate(newStart, task.duration);
      
              // Actualizar recursivamente las tareas dependientes
              const updateDependentTasks = (currentTaskIndex: number, visited = new Set<number>()) => {
                if (visited.has(currentTaskIndex)) return;
                visited.add(currentTaskIndex);
      
                group.tasks.forEach((dependentTask: Task, index: number) => {
                  if (dependentTask.dependsOn.some(dep => dep.taskId === currentTaskIndex + 1)) {
                    const parentTask = group.tasks[currentTaskIndex];
                    
                    // Determinar fecha de inicio basada en si la tarea padre está cerrada
                    const newStart = parentTask.isClosed
                      ? parentTask.end // Mismo día si la tarea padre está cerrada
                      : addDaysToDate(parentTask.end, 1); // Día siguiente si no está cerrada
                    
                    if (dependentTask.isTramitacion) {
                      // Para TRAMITACIÓN, solo actualizar fecha de inicio y mantener la duración existente
                      dependentTask.start = newStart;
                      
                      // Actualizar las fechas de las subtareas manteniendo sus duraciones relativas
                      if (dependentTask.subtasks) {
                        const startDiff = new Date(newStart).getTime() - new Date(dependentTask.start).getTime();
                        dependentTask.subtasks.forEach(subtask => {
                          const newSubtaskStart = new Date(new Date(subtask.start).getTime() + startDiff);
                          const duration = calculateDuration(subtask.start, subtask.end);
                          subtask.start = newSubtaskStart.toISOString().split('T')[0];
                          subtask.end = calculateNewEndDate(subtask.start, duration);
                        });
                        
                        // Actualizar la fecha de término de TRAMITACIÓN basada en la última subtarea
                        const latestSubtaskEnd = Math.max(
                          ...dependentTask.subtasks
                            .filter(st => st.enabled)
                            .map(st => new Date(st.end).getTime())
                        );
                        dependentTask.end = new Date(latestSubtaskEnd).toISOString().split('T')[0];
                      }
                    } else {
                      // Para tareas normales, actualizar inicio y recalcular fin basado en duración
                      const duration = calculateDuration(dependentTask.start, dependentTask.end);
                      dependentTask.start = newStart;
                      dependentTask.end = calculateNewEndDate(newStart, dependentTask.duration);
                    }
                    
                    // Actualizar recursivamente las tareas que dependen de esta
                    updateDependentTasks(index, visited);
                  }
                });
              };
      
              // Iniciar la actualización recursiva desde la tarea actual
              updateDependentTasks(taskIndex);
            }
          }
      
          return updatedTasks;
        });
      

        // Forzar re-renderización
        setTimeout(() => setTasks(prevTasks => ({ ...prevTasks })), 0);

      } else {
        updateTask(groupIndex, taskIndex, field, value);
      }
    } else  if (type === 'subtask' && groupIndex !== null && taskIndex !== null && subtaskIndex !== undefined) {
      handleSubtaskChange(groupIndex, taskIndex, subtaskIndex, field, value);

      setTasks(prevTasks => {
        const newTasks = JSON.parse(JSON.stringify(prevTasks));
        const subtask = newTasks.groups[groupIndex].tasks[taskIndex].subtasks[subtaskIndex];
        
      if (field === 'name' || field === 'responsible' || field === 'organism') {
      subtask[field] = value;
    } else  if (field === 'isClosed') {
      setTasks(prevTasks => {
        const newTasks = { ...prevTasks };
        const task = newTasks.groups[groupIndex].tasks[taskIndex];
        task.isClosed = value;
        // Si la tarea se está cerrando, verificar sus dependencias
        if (value) {
          task.progress = 100;
          // Aquí podrías agregar lógica adicional para manejar las dependencias
        } else {
          task.progress = 0;
        }
        return newTasks;
      });
     
    } else if (field === 'dependsOn') {
      
      // Verifica si `value` es una cadena antes de dividir
      subtask.dependsOn = typeof value === 'string' 
        ? value.split(',').map((dep: string) => {
            const subtaskId = parseInt(dep.trim(), 10);
            return { 
              groupId: groupIndex, 
              taskId: taskIndex,
              subtaskId: subtaskId
            };
          }).filter((dep: { groupId: number; taskId: number; subtaskId: number }) => 
            dep.subtaskId > 0 && 
            dep.subtaskId <= newTasks.groups[groupIndex].tasks[taskIndex].subtasks.length &&
            dep.subtaskId !== subtaskIndex + 1
          )
        : value; // Si `value` no es una cadena, asigna un array vacío
    }
     else if (field === 'enabled') {
      subtask.enabled = value;
    }

  
        return newTasks;
      });
    }


    setEditingField({ type: null, groupIndex: null, taskIndex: null, field: null });
    setEditValue("");

    };

  

  const handleOverwriteConfirm = () => {
    setIsOverwriteConfirmOpen(false);
    handleSaveAs(true);
  };
  
  const handleOverwriteCancel = () => {
    setIsOverwriteConfirmOpen(false);
  };
/*
const handleDescriptorChange = (groupIndex: number, newDescriptor: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  setTasks(prevTasks => ({
    ...prevTasks,
    groups: prevTasks.groups.map((group, index) => 
      index === groupIndex
        ? {
            ...group,
            descriptor: newDescriptor,
            tasks: newDescriptor === 'PERMISOLOGÍA'
              ? group.tasks.map(task => 
                  task.name === 'TRAMITACIÓN'
                    ? { ...task, hidden: false }
                    : task
                ).concat(
                  group.tasks.some(task => task.name === 'TRAMITACIÓN')
                    ? []
                    : [{
                        id: group.tasks.length + 1,
                        name: 'TRAMITACIÓN',
                        responsible: '',
                        progress: 99,
                        start: new Date().toISOString().split('T')[0],
                        end: new Date().toISOString().split('T')[0],
                        descriptor: newDescriptor,
                        duration: 1,
                        organism: group.organismo,
                        dependsOn: [],
                        enabled: true,
                        isTramitacion: true,
                        subtasks: [],
                        hidden: false
                      }]
                )
              : group.tasks.map(task => 
                  task.name === 'TRAMITACIÓN'
                    ? { ...task, hidden: true, descriptor: newDescriptor }
                    : { ...task, descriptor: newDescriptor }
                )
          }
        : group
    )
  }));
};
*/
const handleDescriptorChange = (groupIndex: number, newDescriptor: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  setTasks(prevTasks => {
    const group = prevTasks.groups[groupIndex];
    const newTaskName = generateUniqueTaskName(group.tasks);
    const newOrder = Math.max(...group.tasks.map(t => t.orden || 0), 0) + 1;

    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Formatear la fecha inicial a YYYY-MM-DD
    const startDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');

    // Crear fecha final (una semana después) en zona horaria local
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 7);
    oneWeekLater.setHours(0, 0, 0, 0);
    
    // Formatear la fecha final a YYYY-MM-DD
    const endDate = oneWeekLater.getFullYear() + '-' + 
      String(oneWeekLater.getMonth() + 1).padStart(2, '0') + '-' + 
      String(oneWeekLater.getDate()).padStart(2, '0');

    const maxOrden = Math.max(...group.tasks.map((task: Task) => task.orden), 0);

    const newTask: Task = {
      id: group.tasks.length + 1,
      name: 'TRAMITACIÓN',
      responsible: [],
      progress: 0,
      start: startDate,
      end: endDate,
      descriptor: 'PERMISOLOGÍA',
      duration: 1,
      isClosed: false,
      organism: group.organismo,
      dependsOn: [],
      enabled: true,
      isTramitacion: true,
      
      subtasks: [{
        id: 1,
        name: 'INGRESO',
        responsible: [],
        progress: 0,
        start: startDate,
        end: endDate,
        duration: 1,
        organism: group.organismo,
        dependsOn: [],
        enabled: true,
        isClosed: false,
        type: 'INGRESO',
        orden: 1
      }],
      hidden: false,
      orden: newOrder
    };

    const updatedGroup = {
      ...group,
      descriptor: newDescriptor,
      tasks: newDescriptor === 'PERMISOLOGÍA'
        ? group.tasks
            .map(task => 
              task.name === 'TRAMITACIÓN' ? { ...task, hidden: false } : task
            )
            .concat(group.tasks.some(task => task.name === 'TRAMITACIÓN') ? [] : [newTask])
        : group.tasks.map(task => 
            task.name === 'TRAMITACIÓN'
              ? { ...task, hidden: true, descriptor: newDescriptor }
              : { ...task, descriptor: newDescriptor }
          )
    };

    return {
      ...prevTasks,
      groups: prevTasks.groups.map((g, idx) => (idx === groupIndex ? updatedGroup : g))
    };
  });
};



/*
  const handleSaveToBackend = async () => {
    try {
      const planificacionData = {
        nombre: tasks.name,
        proceso: selectedCodigoProyecto,
        agrupadores: tasks.groups.flatMap(group => 
          group.subprocess ? [{
            nombre: group.agrupador,
            enabled: group.enabled,
            descriptor: group.descriptor,
            organismo: group.organismo,
            subproceso_id: group.subprocess,
            tareas: group.tasks.map(task => ({
              nombre: task.name,
              responsable: task.responsible,
              progreso: task.progress,
              fecha_inicio: task.start,
              fecha_termino: task.end,
              descriptor: task.descriptor,
              duration: calculateDuration(task.start, task.end), // Añadimos la duración
              organismo: task.organism,
              dependencia: task.dependsOn.map(dep => dep.taskId),
              enabled: task.enabled
            }))
          }] : []
        )
      };
  
      console.log('Datos enviados al servidor:', JSON.stringify(planificacionData, null, 2));
  
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_planificacion.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(planificacionData),
      });
  
      const responseText = await response.text();
      console.log('Respuesta del servidor (texto):', responseText);
  
      let result;
      try {
        result = JSON.parse(responseText);
        console.log('Respuesta del servidor (parseada):', result);
      } catch (e) {
        console.error('Error al parsear la respuesta JSON:', e);
        throw new Error('Respuesta no válida del servidor');
      }
  
      if (!response.ok) {
        throw new Error(result.error || 'Error desconocido');
      }
  
      if (result.success) {
        showLabelMessage('success', result.message || 'Planificación guardada exitosamente');
      } else {
        throw new Error(result.error || 'Error desconocido al guardar la planificación');
      }
    } catch (error) {
      console.error('Error completo:', error);
      showLabelMessage('error', `Error al guardar la planificación: ${error}`);
    }
  };*/

  const handleSaveToBackend = async () => {
    try {
      const planificacionData = {
        nombre: tasks.name,
        proceso: selectedCodigoProyecto,
        agrupadores: tasks.groups.map(group => ({
          nombre: group.agrupador,
          enabled: group.enabled,
          descriptor: group.descriptor,
          organismo: group.organismo,
          subproceso_id: group.subprocess,
          orden: group.orden,
          tareas: group.tasks.map(task => ({
            nombre: task.name,
            // Asegurarse de que solo se envíen los IDs de los responsables
            responsable: Array.isArray(task.responsible) 
              ? task.responsible.map(user => ({ id: user.id }))
              : [],
            progreso: task.progress,
            fecha_inicio: task.start,
            fecha_termino: task.end,
            duracion: calculateDuration(task.start, task.end),
            descriptor: task.descriptor,
            organismo: task.organism,
            dependencia: task.dependsOn.map(dep => dep.taskId),
            enabled: task.enabled,
            orden: task.orden,
            isClosed: task.isClosed,
            isTramitacion: task.isTramitacion,
            subtasks: task.isTramitacion && task.subtasks ? task.subtasks.map(subtask => ({
              nombre: subtask.name,
              tipo: subtask.type,
              type: subtask.type,
              responsable: Array.isArray(subtask.responsible)
                ? subtask.responsible.map(user => ({ id: user.id }))
                : [],
              organismo: subtask.organism,
              progreso: subtask.progress,
              fecha_inicio: subtask.start,
              fecha_termino: subtask.end,
              orden: subtask.orden,
              duracion: subtask.duration,
              dependencias: Array.isArray(subtask.dependsOn) 
                ? subtask.dependsOn.map(dep => dep.subtaskId)
                : [],
              enabled: subtask.enabled,
              isClosed: subtask.isClosed,
              resolucion_tipo: subtask.resolutionType
            })) : []
          }))
        }))
      };
  
      console.log('Datos enviados al servidor:', JSON.stringify(planificacionData, null, 2));
  
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_planificacion.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(planificacionData),
      });
  
      const result = await response.json();
      
      if (result.success) {
        showLabelMessage('success', result.message || 'Planificación guardada exitosamente');
      } else {
        throw new Error(result.error || 'Error desconocido al guardar la planificación');
      }
    } catch (error) {
      console.error('Error completo:', error);
      showLabelMessage('error', `Error al guardar la planificación: ${error}`);
    }
  };

  const findTaskById = (groupIndex: number, taskId: number): Task | null => {
    const group = tasks.groups[groupIndex];
    if (group) {
      return group.tasks.find(task => task.id === taskId) || null;
    }
    return null;
  };

  const addDaysToDate = (dateString: string, daysToAdd: number): string => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  };


  const updateDependentTaskDates = (
    tasks: Task[],
    startTaskIndex: number,
    newStartDate: string
  ): Task[] => {
    const updatedTasks = [...tasks];
    
    // Crear un mapa de tareas visitadas para evitar ciclos infinitos
    const visitedTasks = new Set<number>();
    
    const updateDates = (taskIndex: number, newStart: string) => {
      if (visitedTasks.has(taskIndex)) return;
      visitedTasks.add(taskIndex);
      
      const task = updatedTasks[taskIndex];
      const originalDuration = calculateDuration(task.start, task.end);
      
      // Actualizar fechas de la tarea actual
      task.start = newStart;
      task.end = calculateNewEndDate(newStart, originalDuration);
      
      // Buscar y actualizar tareas que dependen de esta
      updatedTasks.forEach((dependentTask, index) => {
        if (dependentTask.dependsOn.some(dep => dep.taskId === taskIndex + 1)) {
          // La nueva fecha de inicio será un día después de la fecha de término de la tarea actual
          const nextStart = calculateNewEndDate(task.end, 1);
          updateDates(index, nextStart);
        }
      });
    };
    
    updateDates(startTaskIndex, newStartDate);
    return updatedTasks;
  };
  
  const updateDependentTasks = (
    updatedGroups: Group[],
    groupIndex: number,
    taskIndex: number,
    shift: number
  ) => {
    const group = updatedGroups[groupIndex];
    if (!group) return;
  
    // Usar un Set para rastrear las tareas procesadas y evitar ciclos
    const processedTasks = new Set<string>();
    const taskQueue: Array<{ task: Task; index: number }> = [];
    
    // Función para actualizar una sola tarea
    const updateSingleTask = (task: Task, currentIndex: number) => {
      const taskId = `${groupIndex}-${currentIndex}`;
      if (processedTasks.has(taskId)) return;
      processedTasks.add(taskId);
  
      // Encontrar todas las tareas que dependen de la tarea actual
      group.tasks.forEach((dependentTask, dependentIndex) => {
        const isDependentOn = dependentTask.dependsOn.some(
          dep => dep.groupId === groupIndex && dep.taskId === currentIndex + 1
        );
  
        if (isDependentOn && !processedTasks.has(`${groupIndex}-${dependentIndex}`)) {
          const duration = calculateDuration(dependentTask.start, dependentTask.end);
          const predecessorTask = group.tasks[currentIndex];
          const newStart = calculateNewEndDate(predecessorTask.end, 1);
  
          if (newStart !== dependentTask.start) {
            dependentTask.start = newStart;
            dependentTask.end = calculateNewEndDate(newStart, duration);
  
            // Agregar la tarea dependiente a la cola para procesar sus dependientes
            taskQueue.push({ task: dependentTask, index: dependentIndex });
          }
        }
      });
    };
  
    // Iniciar con la tarea modificada
    updateSingleTask(group.tasks[taskIndex], taskIndex);
  
    // Procesar la cola de tareas dependientes
    while (taskQueue.length > 0) {
      const { task, index } = taskQueue.shift()!;
      updateSingleTask(task, index);
    }
  
    // Actualizar TRAMITACIÓN y sus subtareas si es necesario
    group.tasks.forEach((task, index) => {
      if (task.isTramitacion && task.subtasks) {
        const hasDependencies = task.dependsOn.length > 0;
        if (hasDependencies) {
          const maxEndDate = Math.max(
            ...task.dependsOn.map(dep => {
              const depTask = group.tasks[dep.taskId - 1];
              return new Date(depTask.end).getTime();
            })
          );
          const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0];
          
          if (newStart !== task.start) {
            task.start = newStart;
            if (task.subtasks) {
              updateTramitacionSubtasksStartDate(task);
            }
            const latestSubtaskEnd = Math.max(
              ...task.subtasks.map(st => new Date(st.end).getTime())
            );
            task.end = new Date(latestSubtaskEnd).toISOString().split('T')[0];
          }
        }
      }
    });
  };
  

  const renderEditableField = (
    type: 'title' | 'group' | 'task' | 'subtask',
    groupIndex: number | null,
    taskIndex: number | null,
    field: string,
    value: any,
    subtaskIndex?: number
  ) => {
    const isEditing = editingField.type === type &&
                      editingField.groupIndex === groupIndex &&
                      editingField.taskIndex === taskIndex &&
                      editingField.subtaskIndex === subtaskIndex &&
                      editingField.field === field;
  
    const group = groupIndex !== null ? tasks.groups[groupIndex] : null;
    const task = taskIndex !== null && group ? group.tasks[taskIndex] : null;
    const subtask = subtaskIndex !== undefined && task?.subtasks ? task.subtasks[subtaskIndex] : null;
  
    const isTaskClosed = type === 'task' && taskIndex !== null &&
                         tasks.groups[groupIndex!].tasks[taskIndex].isClosed;
  
    const isDisabled = (type === 'group' && !group?.enabled) || 
                       (type === 'task' && (!group?.enabled || !task?.enabled || isTaskClosed)) ||
                       (type === 'task' && task?.isTramitacion && (field === 'start' || field === 'end' || field === 'duration'));
   
    const startEditing = () => {
      if (isDisabled) return;
  
      let initialValue = value;
      if ((type === 'task' || type === 'subtask') && (field === 'start' || field === 'end')) {
        initialValue = formatDateForEdit(value);
      } else if (field === 'dependsOn') {
        
        if (type === 'subtask' && subtask) {
          initialValue = Array.isArray(value) ? value.map(dep => dep.subtaskId).join(', ') : value;
          
        } else if (value === 0) {
          initialValue = ''; // Asegurarse de que 0 sea tratado como vacío
        } else {
          initialValue = Array.isArray(value) ? value.map(dep => dep.taskId).join(', ') : value;
          
        }
      } else if (field === 'duration') {
        if (type === 'task' && task) {
          initialValue = formatDuration(calculateDuration(task.start, task.end));
        } else if (type === 'subtask' && subtask) {
          initialValue = formatDuration(calculateDuration(subtask.start, subtask.end));
        }
      }
      
      // Ensure the initial value is uppercase for certain fields like organism
      if (field === 'organism' || field === 'name') {
        initialValue = initialValue.toUpperCase();
      }
   // **Lógica para seleccionar todo automáticamente**
   setTimeout(() => {
    const input = inputRef.current;
    if (input) {
      input.select(); // Seleccionar todo el contenido del campo
    }
  }, 0);

      setEditingField({ type, groupIndex, taskIndex, subtaskIndex, field });
      setEditValue(initialValue);
    };
  
    if (isEditing) {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Force uppercase while editing for fields like 'organism'
        const newValue = field === 'organism' || field === 'name' ? e.target.value.toUpperCase() : e.target.value;
        setEditValue(newValue);
      };
  
      const saveValue = () => {
        handleSave(type, groupIndex, taskIndex, field, editValue, subtaskIndex);
      };
  
      const handleBlur = () => {
        saveValue(); // Save on blur
      };
  
      return (
        <input
          ref={inputRef}
          type={field === 'start' || field === 'end' ? 'date' : 'text'}
          value={editValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              saveValue();
            }
          }}
          className="border-none font-bold text-center bg-white text-black rounded"
          style={{
            width: `${Math.max(editValue.length * 11, 50)}px`,
            maxWidth: '100%',
          }}
          autoFocus
        />
      );
    }
  
    let displayValue: any = value;
    if ((type === 'task' || type === 'subtask') && (field === 'start' || field === 'end')) {
      displayValue = formatDateForDisplay(value);
    } else if (field === 'dependsOn') {
      if (type === 'subtask' && Array.isArray(value)) {
        displayValue = value.map(dep => dep.subtaskId).join(', ');
      } else if (Array.isArray(value)) {
        displayValue = value.map(dep => dep.taskId).join(', ');
      } else if (value === 0) {
        displayValue = ''; 
      } else {
        displayValue = value;
        
      }
    } else if (field === 'duration') {
      if (type === 'task' && task) {
        displayValue = `${formatDuration(calculateDuration(task.start, task.end))} semanas`;
      } else if (type === 'subtask' && subtask) {
        displayValue = `${formatDuration(calculateDuration(subtask.start, subtask.end))} semanas`;
      } else {
        displayValue = `${formatDuration(value)} semanas`;
      }
    }
  
    // Ensure display value is in uppercase for specified fields
    if (field === 'organism' || field === 'name') {
      displayValue = displayValue.toString().toUpperCase();
    }
  
    const isEmpty = !displayValue || (typeof displayValue === 'string' && displayValue.trim() === '');
  
    return (
      <span
        className={`cursor-pointer hover:bg-gray-100 hover:text-black p-1 rounded ${
          isDisabled ? 'cursor-not-allowed text-gray-500' : ''
        } ${isEmpty ? 'min-w-[50px]' : ''} inline-flex items-center h-[20px]`}
        onClick={startEditing}
      >
        {isEmpty ? '\u00A0' : displayValue}
      </span>
    );
  };
  




                    
                    const filterEnabledTasksAndGroups = (tasksState: TasksState): TasksState => {
                      
                      const filteredGroups = tasksState.groups
                        .filter(group => group.enabled)
                        .map(group => {
                          const subprocessColor = subprocessColors[group.subprocess] || getSubprocessColor(group.subprocess);
                          return {
                            ...group,
                            color: subprocessColor,
                            tasks: group.tasks
                              .filter(task => task.enabled)
                              .map(task => ({
                                ...task,
                                progress: 99,
                                color: darkenColor(subprocessColor, 60),
                                
                              }))
                          };
                        })
                        .filter(group => group.tasks.length > 0);
                    
                      // Si no hay grupos o tareas habilitadas, creamos una tarea ficticia
                      if (filteredGroups.length === 0) {
                    // Crear fecha actual en zona horaria local
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    
                    // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
                    const formattedDate = today.getFullYear() + '-' + 
                      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
                      String(today.getDate()).padStart(2, '0');
                    
                    const defaultColor = "#CCCCCC";
                    
                    filteredGroups.push({
                      name: "No hay tareas habilitadas",
                      expanded: true,
                      subprocess: "",
                      agrupador: "Sin agrupador",
                      enabled: true,
                      descriptor: 'GESTIÓN',
                      organismo: '',
                      color: defaultColor,
                      orden: 1, // Añadimos el campo orden al grupo ficticio
                      tasks: [{
                        id: 0,
                        name: "Sin tareas",
                        responsible: [],
                        progress: 0,
                        start: formattedDate,
                        end: formattedDate,
                        descriptor: "",
                        duration: 1,
                        isClosed: false,
                        organism: "",
                        dependsOn: [],
                        enabled: true,
                        color: darkenColor(defaultColor, 60),
                        orden: 1
                      }]
                    });
                  }
                    console.log(JSON.stringify(filteredGroups))
                      return {
                        ...tasksState,
                        groups: filteredGroups
                      };
                    };


                    const generateUniqueGroupName = (groups: Group[]): string => {
                      let counter = 1;
                      let newName = `AGRUPADOR ${counter}`;
                      while (groups.some(group => group.agrupador === newName)) {
                        counter++;
                        newName = `AGRUPADOR ${counter}`;
                      }
                      return newName;
                    };
                  
                  
                    const generateUniqueTaskName = (tasks: Task[]): string => {
                      let counter = 1;
                      let newName = `TAREA ${counter}`;
                      while (tasks.some(task => task.name === newName)) {
                        counter++;
                        newName = `TAREA ${counter}`;
                      }
                      return newName;
                    };
                  
                  
                    const addNewGroup = () => {
  setTasks(prevTasks => {
    const newGroupName = generateUniqueGroupName(prevTasks.groups);
    const newOrder = Math.max(...prevTasks.groups.map(g => g.orden), 0) + 1;
    return {
      ...prevTasks,
      groups: [
        ...prevTasks.groups,
        {
          name: newGroupName,
          expanded: true,
          subprocess: "Nuevo Subproceso",
          agrupador: newGroupName,
          tasks: [],
          enabled: true,
          descriptor: 'GESTIÓN',
          organismo: '',
          orden: newOrder
        }
      ]
    };
  });
};  
const addNewTask = (groupIndex: number) => {
  if (!tasks.groups[groupIndex].enabled) {
    console.warn("No se puede añadir una tarea a un grupo deshabilitado");
    return;
  }

  setTasks(prevTasks => {
    const group = prevTasks.groups[groupIndex];
    const newTaskName = generateUniqueTaskName(group.tasks);
    const startDate = new Date().toISOString().split('T')[0];
    const nextEndDate = new Date();
    nextEndDate.setDate(new Date().getDate() + 7);
    const endDate = nextEndDate.toISOString().split('T')[0];
    const newOrder = Math.max(...group.tasks.map(t => t.orden), 0) + 1;

    const newTask: Task = {
      id: -Date.now(), // Usar número negativo para IDs temporales
      name: newTaskName,
      responsible: [], // Array vacío explícito
      progress: 0,
      start: startDate,
      end: endDate,
      organism: '',
      isClosed: false,
      duration: 1,
      descriptor: '',
      dependsOn: [],
      enabled: true,
      orden: newOrder
    };
    
    return {
      ...prevTasks,
      groups: prevTasks.groups.map((g, index) => 
        index === groupIndex
          ? { ...g, tasks: [...g.tasks, newTask] }
          : g
      )
    };
  });
};

                    const copyGroup = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const groupToCopy = prevTasks.groups[groupIndex];
                        const newGroup: Group = {
                          ...groupToCopy,
                          agrupador: `${groupToCopy.agrupador} (Copia)`,
                          tasks: groupToCopy.tasks.map(task => ({
                            ...task,
                            id: Math.max(...prevTasks.groups.flatMap(g => g.tasks.map(t => t.id))) + 1,
                            responsible: [], // Resetear los responsables en la copia
                            dependsOn: []
                          }))
                        };
                        
                        return {
                          ...prevTasks,
                          groups: [
                            ...prevTasks.groups.slice(0, groupIndex + 1),
                            newGroup,
                            ...prevTasks.groups.slice(groupIndex + 1)
                          ]
                        };
                      });
                    };


                    const resetGroupTasks = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        const group = newGroups[groupIndex];
                        
                        // Crear fecha actual en zona horaria local
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        
                        // Formatear la fecha inicial a YYYY-MM-DD
                        const formattedToday = today.getFullYear() + '-' + 
                          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
                          String(today.getDate()).padStart(2, '0');
                        
                        // Crear fecha final (una semana después) en zona horaria local
                        const oneWeekLater = new Date(today);
                        oneWeekLater.setDate(today.getDate() + 7);
                        oneWeekLater.setHours(0, 0, 0, 0);
                        
                        // Formatear la fecha final a YYYY-MM-DD
                        const formattedOneWeekLater = oneWeekLater.getFullYear() + '-' + 
                          String(oneWeekLater.getMonth() + 1).padStart(2, '0') + '-' + 
                          String(oneWeekLater.getDate()).padStart(2, '0');
                    
                        group.tasks = group.tasks.map((task, index) => ({
                          ...task,
                          name: `TAREA ${index + 1}`,
                          start: formattedToday,
                          end: formattedOneWeekLater,
                          dependsOn: [],
                        }));
                    
                        return { ...prevTasks, groups: newGroups };
                      });
                    };
                  
                    const deleteGroup = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newGroups = prevTasks.groups.filter((_, index) => index !== groupIndex);
                        // Reassign IDs to remaining groups
                        return {
                          ...prevTasks,
                          groups: newGroups.map((group, index) => ({
                            ...group,
                            id: index + 1
                          }))
                        };
                      });
                    };
                  
                    const deleteTask = (groupIndex: number, taskIndex: number) => {
                      if (!tasks.groups[groupIndex].enabled) {
                        console.warn("No se puede eliminar una tarea en un grupo deshabilitado");
                        return;
                      }
                      
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        const deletedTaskId = taskIndex + 1; // ID de la tarea eliminada
                    
                        // Eliminar la tarea
                        group.tasks.splice(taskIndex, 1);
                    
                        // Actualizar IDs y dependencias de las tareas restantes
                        group.tasks = group.tasks.map((task: Task, index: number) => ({
                          ...task,
                          id: index + 1,
                          dependsOn: task.dependsOn
                            .map(dep => {
                              // Si la dependencia es con la tarea eliminada, la removemos
                              if (dep.taskId === deletedTaskId) {
                                return null;
                              }
                              // Si la dependencia es con una tarea posterior a la eliminada, actualizamos su ID
                              if (dep.taskId > deletedTaskId) {
                                return { ...dep, taskId: dep.taskId - 1 };
                              }
                              return dep;
                            })
                            .filter((dep): dep is { groupId: number; taskId: number } => dep !== null)
                        }));
                    
                        // Actualizar fechas de las tareas dependientes
                        const processedTasks = new Set<number>();
                        
                        const updateDependentDates = (currentTaskIndex: number) => {
                          if (processedTasks.has(currentTaskIndex)) return;
                          processedTasks.add(currentTaskIndex);
                    
                          const task = group.tasks[currentTaskIndex];
                          if (!task.dependsOn.length) return;
                    
                          // Calcular la nueva fecha de inicio basada en las dependencias restantes
                          const maxEndDate = Math.max(
                            ...task.dependsOn.map((dep: any) => {
                              const depTask = group.tasks[dep.taskId - 1];
                              return new Date(depTask.end).getTime();
                            })
                          );
                    
                          // Verificar si depende de alguna tarea cerrada
                          const dependsOnClosedTask = task.dependsOn.some((dep: any) => 
                            group.tasks[dep.taskId - 1].isClosed
                          );
                    
                          // Determinar nueva fecha de inicio
                          const newStart = dependsOnClosedTask
                            ? new Date(maxEndDate).toISOString().split('T')[0]
                            : addDaysToDate(new Date(maxEndDate).toISOString().split('T')[0], 1);
                    
                          if (newStart !== task.start) {
                            // Mantener la duración original al actualizar las fechas
                            const duration = calculateDuration(task.start, task.end);
                            task.start = newStart;
                            task.end = calculateNewEndDate(newStart, duration);
                    
                            // Si es una tarea de TRAMITACIÓN, actualizar sus subtareas
                            if (task.isTramitacion && task.subtasks) {
                              updateTramitacionSubtasksStartDate(task);
                              
                              // Actualizar la fecha de término de TRAMITACIÓN basada en la última subtarea
                              if (task.subtasks.length > 0) {
                                const latestSubtaskEnd = new Date(Math.max(
                                  ...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())
                                ));
                                task.end = latestSubtaskEnd.toISOString().split('T')[0];
                              }
                            }
                    
                            // Actualizar recursivamente las tareas que dependen de esta
                            group.tasks.forEach((_:SubTask, index: number) => {
                              if (!processedTasks.has(index) && 
                                  group.tasks[index].dependsOn.some((dep: any) => dep.taskId === currentTaskIndex + 1)) {
                                updateDependentDates(index);
                              }
                            });
                          }
                        };
                    
                        // Actualizar las fechas de todas las tareas que tenían dependencias
                        group.tasks.forEach((_: SubTask, index: number) => {
                          if (!processedTasks.has(index)) {
                            updateDependentDates(index);
                          }
                        });
                    
                        return newTasks;
                      });
                    };
                    
                    const addTramitacionTask = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const todayFormatted = today.toISOString().split('T')[0];
                        
                        const oneWeekAfter = new Date(today);
                        oneWeekAfter.setDate(today.getDate() + 7);
                        const endDate = oneWeekAfter.toISOString().split('T')[0];
                    
                        const newTask: Task = {
                          id: group.tasks.length + 1,
                          name: 'TRAMITACIÓN',
                          responsible: [],
                          progress: 0,
                          start: todayFormatted,
                          end: endDate,
                          descriptor: 'PERMISOLOGÍA',
                          duration: 1,
                          organism: group.organismo,
                          dependsOn: [],
                          enabled: true,
                          isTramitacion: true,
                          orden: Math.max(...group.tasks.map((task: Task) => task.orden || 0), 0) + 1,
                          isClosed: false,
                          subtasks: [{
                            id: 1, // Asegurar que el ID sea 1
                            name: 'INGRESO',
                            responsible: [],
                            progress: 0,
                            start: todayFormatted,
                            end: endDate,
                            duration: 1,
                            organism: group.organismo,
                            dependsOn: [],
                            enabled: true,
                            type: 'INGRESO',
                            orden: 1,
                            isClosed: false
                          }]
                        };
                    
                        group.tasks.push(newTask);
                        return newTasks;
                      });
                    };
                    const addSpecificSubtask = (groupIndex: number, taskIndex: number, type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN') => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const task = newTasks.groups[groupIndex].tasks[taskIndex];
                        
                        if (!task.subtasks) task.subtasks = [];
                    
                        task.subtasks = task.subtasks.map((subtask: SubTask, index: number) => ({
                          ...subtask,
                          id: index + 1
                        }));
                        
    // Verificar reglas de secuencia
    if (task.subtasks.length > 0) {
      const lastSubtask = task.subtasks[task.subtasks.length - 1];
      
      // Verificar reglas según el tipo de la última subtarea
      switch (lastSubtask.type) {
        case 'INGRESO':
          if (type !== 'OBSERVACIÓN' && type !== 'RESOLUCIÓN') {
            showLabelMessage('error', 'Después de INGRESO solo se puede agregar OBSERVACIÓN o RESOLUCIÓN');
            return prevTasks;
          }
          break;
        case 'OBSERVACIÓN':
          if (type !== 'RESPUESTA' && type !== 'RESOLUCIÓN') {
            showLabelMessage('error', 'Después de OBSERVACIÓN solo se puede agregar RESPUESTA o RESOLUCIÓN');
            return prevTasks;
          }
          break;
        case 'RESPUESTA':
          if (type !== 'OBSERVACIÓN' && type !== 'RESOLUCIÓN') {
            showLabelMessage('error', 'Después de RESPUESTA solo se puede agregar OBSERVACIÓN o RESOLUCIÓN');
            return prevTasks;
          }
          break;
        case 'RESOLUCIÓN':
          showLabelMessage('error', 'No se pueden agregar más subtareas después de RESOLUCIÓN');
          return prevTasks;
      }

      // Prevenir secuencias consecutivas del mismo tipo
      if (type === lastSubtask.type && type !== 'RESOLUCIÓN') {
        showLabelMessage('error', `No se pueden agregar dos ${type} consecutivas`);
        return prevTasks;
      }
    }

    // Convertir la fecha de inicio de la tramitación a objeto Date
    const [yearStart, monthStart, dayStart] = task.start.split('-').map(Number);
    const tramitacionStartDate = new Date(yearStart, monthStart - 1, dayStart);
    tramitacionStartDate.setHours(0, 0, 0, 0);
    
    const oneWeekLater = new Date(tramitacionStartDate);
    oneWeekLater.setDate(tramitacionStartDate.getDate() + 7);
    oneWeekLater.setHours(0, 0, 0, 0);
    
    const start = tramitacionStartDate.toISOString().split('T')[0];
    const end = oneWeekLater.toISOString().split('T')[0];

    // Verificar si ya existe INGRESO o RESOLUCIÓN
    if ((type === 'INGRESO' || type === 'RESOLUCIÓN') && 
        task.subtasks.some((st: SubTask) => st.type === type)) {
      showLabelMessage('error', `Ya existe una tarea ${type}`);
      return prevTasks;
    }

    let similarSubtaskCount = task.subtasks.filter((st: SubTask) => st.type === type).length;
    let newSubtaskName = '';

    if (type === 'OBSERVACIÓN') {
      similarSubtaskCount += 1;
      newSubtaskName = `OBSERVACIÓN ${similarSubtaskCount}`;
    } else if (type === 'RESPUESTA') {
      similarSubtaskCount += 1;
      newSubtaskName = `RESPUESTA ${similarSubtaskCount}`;
    } else {
      newSubtaskName = type;
    }

    // Asignar responsables según el tipo
    let responsible: User[] = [];
    if (type === 'INGRESO' || type === 'RESPUESTA') {
      responsible = task.responsible || [];
    }

    // Asignar dependeOn con `groupId`, `taskId`, y `subtaskId` de la última subtarea si existe
    const lastSubtask = task.subtasks.length > 0 ? task.subtasks[task.subtasks.length - 1] : null;
    const newSubtask: SubTask = {
      id: task.subtasks.length + 1,
      name: newSubtaskName,
      responsible: responsible,
      progress: 0,
      start: start,
      end: end,
      duration: 1,
      organism: task.organism,
      dependsOn: lastSubtask ? [{ groupId: groupIndex, taskId: taskIndex, subtaskId: lastSubtask.id }] : [],
      enabled: true,
      type: type,
      isClosed: false,
      orden: task.subtasks.length + 1
    };

    // Agregar la nueva subtarea en la posición adecuada
    if (type === 'INGRESO') {
      task.subtasks.unshift(newSubtask);
    } else if (type === 'RESOLUCIÓN') {
      task.subtasks.push(newSubtask);
    } else {
      const resolutionIndex = task.subtasks.findIndex((st: SubTask) => st.type === 'RESOLUCIÓN');
      if (resolutionIndex !== -1) {
        task.subtasks.splice(resolutionIndex, 0, newSubtask);
      } else {
        task.subtasks.push(newSubtask);
      }
    }
    

    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};


                    
const moveGroup = (groupIndex: number, direction: 'up' | 'down') => {
  setTasks(prevTasks => {
    const newGroups = [...prevTasks.groups];
    if (direction === 'up' && groupIndex > 0) {
      [newGroups[groupIndex], newGroups[groupIndex - 1]] = [newGroups[groupIndex - 1], newGroups[groupIndex]];
      [newGroups[groupIndex].orden, newGroups[groupIndex - 1].orden] = [newGroups[groupIndex - 1].orden, newGroups[groupIndex].orden];
    } else if (direction === 'down' && groupIndex < newGroups.length - 1) {
      [newGroups[groupIndex], newGroups[groupIndex + 1]] = [newGroups[groupIndex + 1], newGroups[groupIndex]];
      [newGroups[groupIndex].orden, newGroups[groupIndex + 1].orden] = [newGroups[groupIndex + 1].orden, newGroups[groupIndex].orden];
    }
    return { ...prevTasks, groups: newGroups };
  });
};
                  
                   // Función mejorada para mover tareas
                   const moveTask = (groupIndex: number, taskIndex: number, direction: 'up' | 'down') => {
                    console.log(`Attempting to move task in group ${groupIndex}, task ${taskIndex} ${direction}`);
                    
                    setTasks(prevTasks => {
                      const newGroups = [...prevTasks.groups];
                      const group = newGroups[groupIndex];
                      
                      if (!group.enabled) {
                        console.warn("No se puede mover una tarea en un grupo deshabilitado");
                        return prevTasks;
                      }

                      const newIndex = direction === 'up' ? taskIndex - 1 : taskIndex + 1;

                      if (newIndex < 0 || newIndex >= group.tasks.length) {
                        console.warn(`Invalid move: task ${taskIndex} cannot be moved ${direction}`);
                        return prevTasks;
                      }

                      // Crear una copia de las tareas del grupo
                      let newTasks = [...group.tasks];

                      // Intercambiar las tareas
                      [newTasks[taskIndex], newTasks[newIndex]] = [newTasks[newIndex], newTasks[taskIndex]];

                      // Actualizar las dependencias
                      newTasks = updateDependenciesAfterMove(newTasks, taskIndex, newIndex);

                      // Actualizar los IDs de las tareas
                      newTasks = newTasks.map((task, index) => ({
                        ...task,
                        id: index + 1
                      }));

                      // Actualizar el grupo con las nuevas tareas
                      newGroups[groupIndex] = {
                        ...group,
                        tasks: newTasks
                      };

                      console.log(`Task moved successfully. New tasks order:`, newTasks.map(t => t.id));

                      return {
                        ...prevTasks,
                        groups: newGroups
                      };
                    });
                  };
                
                  const updateDependenciesAfterMove = (tasks: Task[], oldIndex: number, newIndex: number): Task[] => {
                    return tasks.map(task => ({
                      ...task,
                      dependsOn: task.dependsOn.map(dep => {
                        if (dep.taskId === oldIndex + 1) {
                          return { ...dep, taskId: newIndex + 1 };
                        } else if (oldIndex < newIndex) {
                          if (dep.taskId > oldIndex + 1 && dep.taskId <= newIndex + 1) {
                            return { ...dep, taskId: dep.taskId - 1 };
                          }
                        } else if (oldIndex > newIndex) {
                          if (dep.taskId >= newIndex + 1 && dep.taskId < oldIndex + 1) {
                            return { ...dep, taskId: dep.taskId + 1 };
                          }
                        }
                        return dep;
                      })
                    }));
                  };
                    const formatDateForDisplay = (dateString: string) => {
                      if (!dateString) return '';
                      const date = new Date(dateString + 'T00:00:00Z');
                      if (isNaN(date.getTime())) return dateString;
                      return date.toLocaleDateString('es-ES', { 
                        day: '2-digit', 
                        month: '2-digit', 
                        year: 'numeric',
                        timeZone: 'UTC'    
                      });
                    };
                  
                    const formatDateForEdit = (dateString: string) => {
                      if (!dateString) return '';
                      let date = new Date(dateString + 'T00:00:00Z');
                      
                      if (isNaN(date.getTime())) {
                        const parts = dateString.split('-');
                        if (parts.length === 3) {
                          date = new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])));
                        }
                      }
                      
                      if (isNaN(date.getTime())) return '';
                      
                      return date.toISOString().split('T')[0];
                    };
                  
                    const toggleGlobalExpansion = () => {
                      setIsGloballyExpanded(!isGloballyExpanded);
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map(group => ({
                          ...group,
                          expanded: !isGloballyExpanded
                        }))
                      }));
                    };


                    const updateDependentSubtasksRecursively = (task: Task, changedSubtaskIndex: number) => {
                      // Check if subtasks are defined
                      if (!task.subtasks || !task.subtasks[changedSubtaskIndex]) return;
                    
                      const changedSubtask = task.subtasks[changedSubtaskIndex];
                      const processedSubtasks = new Set<number>();
                    
                      const updateSubtask = (subtaskIndex: number) => {
                        if (processedSubtasks.has(subtaskIndex) || !task.subtasks) return;
                        processedSubtasks.add(subtaskIndex);
                    
                        const currentSubtask = task.subtasks[subtaskIndex];
                        const dependencies = currentSubtask.dependsOn;
                    
                        // Find the latest end date among all dependencies
                        const latestEndDate = Math.max(
                          ...dependencies.map(dep => {
                            const depSubtask = task.subtasks ? task.subtasks[dep.subtaskId - 1] : undefined;
                            return depSubtask ? new Date(depSubtask.end).getTime() : 0;
                          })
                        );
                    
                        const dependsOnClosedSubtask = dependencies.some(dep => {
                          const depSubtask = task.subtasks ? task.subtasks[dep.subtaskId - 1] : undefined;
                          return depSubtask?.isClosed ?? false;
                        });
                    
                        // Calculate new start date
                        let newStart;
                        if (dependsOnClosedSubtask) {
                          // If it depends on a closed subtask, it starts on the same day
                          newStart = new Date(latestEndDate).toISOString().split('T')[0];
                        } else {
                          // Otherwise, it starts the next day
                          const nextDay = new Date(latestEndDate);
                          nextDay.setDate(nextDay.getDate() + 1);
                          newStart = nextDay.toISOString().split('T')[0];
                        }
                    
                        if (new Date(newStart).getTime() !== new Date(currentSubtask.start).getTime()) {
                          const duration = calculateDuration(currentSubtask.start, currentSubtask.end);
                          currentSubtask.start = newStart;
                          currentSubtask.end = calculateNewEndDate(newStart, duration);
                    
                          // Find and update subtasks that depend on this one
                          task.subtasks?.forEach((subtask, index) => {
                            if (subtask.dependsOn.some(dep => dep.subtaskId === subtaskIndex + 1)) {
                              updateSubtask(index);
                            }
                          });
                        }
                      };
                    
                      // Update all subtasks that directly depend on the modified subtask
                      task.subtasks?.forEach((subtask, index) => {
                        if (subtask.dependsOn.some(dep => dep.subtaskId === changedSubtaskIndex + 1)) {
                          updateSubtask(index);
                        }
                      });
                    };
                    

                    const handleSubtaskChange = (
                      groupIndex: number,
                      taskIndex: number,
                      subtaskIndex: number,
                      field: string,
                      value: string
                    ) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const task = newTasks.groups[groupIndex].tasks[taskIndex];
                        const subtask = task.subtasks[subtaskIndex];
                    
                        // Auxiliary function to recursively update dependent subtasks
                        const updateDependentSubtasks = (currentSubtaskIndex: number, visited = new Set<number>()) => {
                          if (visited.has(currentSubtaskIndex)) return;
                          visited.add(currentSubtaskIndex);
                    
                          const currentSubtask = task.subtasks[currentSubtaskIndex];
                    
                          task.subtasks.forEach((dependentSubtask: SubTask, index: number) => {
                            // Verifica si `dependsOn` es un array antes de usar `.some`
                            if (Array.isArray(dependentSubtask.dependsOn) &&
                                dependentSubtask.dependsOn.some(dep => dep.subtaskId === currentSubtaskIndex + 1)) {
                              const duration = calculateDuration(dependentSubtask.start, dependentSubtask.end);
                              const parentSubtask = task.subtasks[currentSubtaskIndex];
                          
                              // Determine start date based on whether the parent subtask is closed
                              const newStart = parentSubtask.isClosed
                                ? parentSubtask.end // Same day if the parent subtask is closed
                                : addDaysToDate(parentSubtask.end, 1); // Next day if not closed
                              
                              dependentSubtask.start = newStart;
                              dependentSubtask.end = calculateNewEndDate(newStart, duration);
                              
                              // Recursively update subtasks dependent on this one
                              updateDependentSubtasks(index, visited);
                            }
                          });
                        };
                    
                        if (field === 'start' || field === 'end') {
                          const updatedDate = new Date(value);
                          const otherField = field === 'start' ? 'end' : 'start';
                          const otherDate = new Date(subtask[otherField]);
                        
                          // Validar dependencias de TRAMITACIÓN
                          const tramitacionHasDependencies = task.dependsOn && task.dependsOn.length > 0;
                          if (tramitacionHasDependencies) {
                            const tramitacionStartDate = new Date(task.start);
                            if (updatedDate < tramitacionStartDate) {
                              showLabelMessage('error', 'La fecha de la subtarea no puede ser anterior a la fecha de inicio de TRAMITACIÓN');
                              return prevTasks;
                            }
                          }
                        
                          // Si se está modificando la fecha de inicio, mantener la duración
                          if (field === 'start') {
                            // Validar que la nueva fecha de inicio no sea posterior a la fecha de término
                            if (updatedDate > otherDate) {
                              showLabelMessage('error', 'La fecha de inicio no puede ser mayor a la fecha de término');
                              return prevTasks;
                            }
                            
                            // Mantener la duración actual y calcular nueva fecha de término
                            subtask.start = value;
                            const currentDuration = subtask.duration;
                            subtask.end = calculateNewEndDate(value, currentDuration);
                          } 
                          // Si se está modificando la fecha de término
                          else if (field === 'end') {
                            // Validar que la nueva fecha de término no sea anterior a la fecha de inicio
                            if (updatedDate < new Date(subtask.start)) {
                              showLabelMessage('error', 'La fecha de término no puede ser menor a la fecha de inicio');
                              return prevTasks;
                            }
                            
                            // Actualizar fecha de término y calcular nueva duración
                            subtask.end = value;
                            subtask.duration = calculateDuration(subtask.start, value);
                          }
                        
                          // Actualizar subtareas dependientes
                          updateDependentSubtasks(subtaskIndex);
                        
                        } else if (field === 'duration') {
                          const newDuration = parseFloat(value);
                          if (!isNaN(newDuration)) {
                            subtask.duration = newDuration;
                            subtask.end = calculateNewEndDate(subtask.start, newDuration);
                            updateDependentSubtasks(subtaskIndex);
                          }
                        
                        } else if (field === 'dependsOn') {
                          // Verifica si `value` es una cadena antes de intentar usar `split`
                          const newDependencies = typeof value === 'string' 
                            ? value.split(',')
                              .map(dep => {
                                const subtaskId = parseInt(dep.trim(), 10);
                                return { groupId: groupIndex, taskId: taskIndex, subtaskId };
                              })
                              .filter(dep => 
                                dep.subtaskId > 0 && 
                                dep.subtaskId <= task.subtasks.length && 
                                dep.subtaskId !== subtaskIndex + 1
                              )
                            : []; // Si `value` no es una cadena, asigna un array vacío
                        
                          subtask.dependsOn = newDependencies;
                    
                          // Update start date based on new dependencies
                          if (newDependencies.length > 0) {
                            const maxEndDate = Math.max(...newDependencies.map(dep => {
                              const depSubtask = task.subtasks[dep.subtaskId - 1];
                              const isClosed = depSubtask.isClosed;
                              const endDate = new Date(depSubtask.end).getTime();
                              return endDate;
                            }));
                    
                            const hasClosedDependency = newDependencies.some(dep => 
                              task.subtasks[dep.subtaskId - 1].isClosed
                            );
                    
                            // Determine new start date
                            const newStart = hasClosedDependency
                              ? new Date(maxEndDate).toISOString().split('T')[0] // Same day if dependent on a closed subtask
                              : addDaysToDate(new Date(maxEndDate).toISOString().split('T')[0], 1); // Next day if not
                    
                            if (new Date(newStart) > new Date(subtask.start)) {
                              subtask.start = newStart;
                              subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
                              updateDependentSubtasks(subtaskIndex);
                            }
                          } 
                        } else {
                          subtask[field] = value;
                        }
                    
                        // Update TRAMITACIÓN end date
                        if (task.subtasks && task.subtasks.length > 0) {
                          const enabledSubtasks = task.subtasks.filter((st: SubTask) => st.enabled); // Filtrar subtareas habilitadas
                        
                          if (enabledSubtasks.length > 0) {
                            // Calcular la fecha de inicio más temprana
                            const earliestSubtaskStart = new Date(
                              Math.min(
                                ...enabledSubtasks.map((st: SubTask) => new Date(st.start).getTime())
                              )
                            );
                            task.start = earliestSubtaskStart.toISOString().split('T')[0];
                        
                            // Calcular la fecha de término más tardía
                            const latestSubtaskEnd = new Date(
                              Math.max(
                                ...enabledSubtasks.map((st: SubTask) => new Date(st.end).getTime())
                              )
                            );
                            task.end = latestSubtaskEnd.toISOString().split('T')[0];
                          }
                        }//acaaaaaa
                        forceUpdateDates(groupIndex);
                    
                        return newTasks;
                      });  
                    };
                    
                    
                    const forceUpdateDates = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        
                        const processedTasks = new Set<number>();
                        
                        const updateTaskDates = (taskIndex: number) => {
                          if (processedTasks.has(taskIndex)) return;
                          processedTasks.add(taskIndex);
                          
                          const task = group.tasks[taskIndex];
                          if (!task.enabled || task.isClosed) return;
                          
                          if (task.dependsOn.length > 0) {
                            const maxEndDate = Math.max(...task.dependsOn.map((dep: { taskId: number }) => {
                              const depTask = group.tasks[dep.taskId - 1];
                              return new Date(depTask.end).getTime();
                            }));
                            
                            // Determine if it depends on a closed task
                            const dependsOnClosedTask = task.dependsOn.some((dep: { taskId: number }) => 
                              group.tasks[dep.taskId - 1].isClosed
                            );
                            
                            // Calculate new start date
                            const newStart = dependsOnClosedTask
                              ? new Date(maxEndDate).toISOString().split('T')[0]
                              : new Date(maxEndDate + 86400000).toISOString().split('T')[0];
                            
                            if (newStart !== task.start) {
                              const duration = calculateDuration(task.start, task.end);
                              task.start = newStart;
                              task.end = calculateNewEndDate(newStart, duration);
                              
                              // Update subtasks if TRAMITACIÓN
                              if (task.isTramitacion && task.subtasks) {
                                updateTramitacionSubtasksStartDate(task);
                              }
                              
                              // Find and update tasks that depend on this one
                              group.tasks.forEach((dependentTask: Task, index: number) => {
                                if (dependentTask.dependsOn.some((dep: { taskId: number }) => dep.taskId === taskIndex + 1)) {
                                  updateTaskDates(index);
                                }
                              });
                            }
                          }
                        };
                        
                        // Process all tasks in order
                        group.tasks.forEach((_: Task, index: number) => {
                          if (!processedTasks.has(index)) {
                            updateTaskDates(index);
                          }
                        });
                        
                        
                        return newTasks;
                      });
                    };




                    
                    

                      const handleDateChange = (groupIndex: number, taskIndex: number, field: 'start' | 'end', newDate: string) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        const task = group.tasks[taskIndex];
                        const oldDate = new Date(task[field]);
                        const updatedDate = new Date(newDate);
                    
                        // Si se está modificando la fecha de inicio
                        if (field === 'start') {
                          // Actualizar fecha de inicio y mantener la duración
                          task.start = newDate;
                          const currentDuration = task.duration;
                          task.end = calculateNewEndDate(newDate, currentDuration);
                        } 
                        // Si se está modificando la fecha de término
                        else if (field === 'end') {
                          // Validar que la nueva fecha de término no sea anterior a la fecha de inicio
                          if (updatedDate < new Date(task.start)) {
                            showLabelMessage('error', 'La fecha de término no puede ser menor a la fecha de inicio');
                            return prevTasks;
                          }
                          
                          // Actualizar fecha de término y recalcular duración
                          task.end = newDate;
                         // task.duration = calculateDuration(task.start, newDate);
                        }
                    
                        // Actualizar las fechas de las tareas dependientes
                        const updateDependentTasksRecursively = (tasks: Task[], changedTaskIndex: number) => {
                          const processedTasks = new Set<number>();
                        
                          const updateTask = (taskIndex: number) => {
                            // Si ya procesamos esta tarea, salimos para evitar ciclos
                            if (processedTasks.has(taskIndex)) return;
                            processedTasks.add(taskIndex);
                        
                            const task = tasks[taskIndex];
                        
                            // Encontramos todas las tareas que dependen de esta
                            const dependentTasks = tasks.reduce((acc, currentTask, currentIndex) => {
                              if (currentTask.dependsOn.some(dep => dep.taskId === taskIndex + 1)) {
                                acc.push({ task: currentTask, index: currentIndex });
                              }
                              return acc;
                            }, [] as Array<{ task: Task; index: number }>);
                        
                            // Para cada tarea dependiente
                            dependentTasks.forEach(({ task: dependentTask, index: dependentIndex }) => {
                              // Calculamos la fecha más tardía entre todas sus dependencias
                              const maxEndDate = Math.max(
                                ...dependentTask.dependsOn.map(dep => {
                                  const depTask = tasks[dep.taskId - 1];
                                  return new Date(depTask.end).getTime();
                                })
                              );
                        
                            
                              // Verificamos si depende de alguna tarea cerrada
                              const dependsOnClosedTask = dependentTask.dependsOn.some(dep => 
                                tasks[dep.taskId - 1].isClosed
                              );
                        
                              // Calculamos la nueva fecha de inicio
                              const newStart = dependsOnClosedTask
                                ? new Date(maxEndDate).toISOString().split('T')[0] // Mismo día si depende de tarea cerrada
                                : addDaysToDate(new Date(maxEndDate).toISOString().split('T')[0], 1); // Día siguiente si no
                        
                              // Solo actualizamos si la fecha realmente cambió
                              if (new Date(newStart).getTime() !== new Date(dependentTask.start).getTime()) {
                                const duration = calculateDuration(dependentTask.start, dependentTask.end);
                                dependentTask.start = newStart;
                                dependentTask.end = calculateNewEndDate(newStart, duration);
                        
                                // Recursivamente actualizamos las tareas que dependen de esta
                                updateTask(dependentIndex);
                                if(dependentTask.name === 'TRAMITACIÓN'){
                                  updateSubtasksForTramitacion(dependentTask)
                                }
                              }
                            });
                          };
                          
                        
                          if (task.isTramitacion && task.subtasks) {
                            const taskStart = new Date(task.start).getTime();
                            const originalTaskStart = new Date(task.originalStart || task.start).getTime();
                            
                            const dateDifference = (taskStart - originalTaskStart) / (1000 * 60 * 60 * 24); // difference in days
                  
                            // Adjust each subtask's start and end dates based on the new start date of the TRAMITACIÓN task
                            task.subtasks.forEach((subtask: SubTask) => {
                              const subtaskStartDate = new Date(subtask.start);
                              const subtaskEndDate = new Date(subtask.end);
                  
                              subtaskStartDate.setDate(subtaskStartDate.getDate() + dateDifference);
                              subtaskEndDate.setDate(subtaskEndDate.getDate() + dateDifference);
                  
                              subtask.start = subtaskStartDate.toISOString().split('T')[0];
                              subtask.end = subtaskEndDate.toISOString().split('T')[0];
                            });
                  
                            // Update task's end date based on the latest subtask end date
                            const latestSubtaskEndDate = Math.max(
                              ...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())
                            );
                            task.end = new Date(latestSubtaskEndDate).toISOString().split('T')[0];
                          }
                          
                          // Iniciamos la actualización desde la tarea modificada
                          updateTask(changedTaskIndex);
                        };
                        // Actualizar tareas dependientes
                        updateDependentTasksRecursively(group.tasks, taskIndex);
                        updateDependentSubtasksRecursively(group.tasks, 1)
                        return newTasks;
                      });
                    }; 



// Nueva función para actualizar las subtareas de TRAMITACIÓN
const updateSubtasksForTramitacion = (tramitacionTask: Task) => {
  if (!tramitacionTask.subtasks) return;

  let currentDate = dateUtils.parseLocalDate(tramitacionTask.start);
  
  // Primero actualizar subtareas sin dependencias
  tramitacionTask.subtasks.forEach(subtask => {
    if (!subtask.dependsOn || subtask.dependsOn.length === 0) {
      const duration = dateUtils.calculateDaysDifference(subtask.end, subtask.start);
      subtask.start = dateUtils.formatLocalDate(currentDate);
      subtask.end = dateUtils.addDaysToDate(subtask.start, duration);
      
      currentDate = dateUtils.parseLocalDate(subtask.end);
      currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
    }
  });

  // Luego actualizar subtareas con dependencias
  const processedSubtasks = new Set<number>();
  
  const updateDependentSubtask = (subtaskIndex: number) => {
    if (processedSubtasks.has(subtaskIndex)) return;
    
    const subtask = tramitacionTask.subtasks![subtaskIndex];
    const dependencies = ensureDependsOnArray(subtask.dependsOn);
    
    // Procesar primero las dependencias
    dependencies.forEach(dep => {
      if (!processedSubtasks.has(dep.subtaskId - 1)) {
        updateDependentSubtask(dep.subtaskId - 1);
      }
    });

    if (dependencies.length > 0) {
      const maxEndDate = Math.max(...dependencies.map(dep => {
        const depSubtask = tramitacionTask.subtasks![dep.subtaskId - 1];
        return dateUtils.parseLocalDate(depSubtask.end).getTime();
      }));

      const newStart = dateUtils.addDaysToDate(dateUtils.formatLocalDate(new Date(maxEndDate)), 1);
      
      const duration = dateUtils.calculateDaysDifference(subtask.end, subtask.start);
      subtask.start = newStart;
      subtask.end = dateUtils.addDaysToDate(newStart, duration);
    }

    processedSubtasks.add(subtaskIndex);
  };

  // Ejecutar actualización en subtareas con dependencias
  tramitacionTask.subtasks.forEach((_, index) => {
    updateDependentSubtask(index);
  });
};



const updateDependentTasksRecursively = (tasks: Task[], changedTaskIndex: number) => {
  const changedTask = tasks[changedTaskIndex];
  const processedTasks = new Set<number>();

  const updateTask = (taskIndex: number) => {
    if (processedTasks.has(taskIndex)) return;
    processedTasks.add(taskIndex);

    const currentTask = tasks[taskIndex];
    const dependencies = currentTask.dependsOn;

    // Encontrar la fecha más tardía entre todas las dependencias
    const latestEndDate = Math.max(
      ...dependencies.map(dep => {
        const depTask = tasks[dep.taskId - 1];
        return new Date(depTask.end).getTime();
      })
    );

    const dependsOnClosedTask = dependencies.some(dep => {
      const depTask = tasks[dep.taskId - 1];
      return depTask.isClosed;
    });

    // Calcular nueva fecha de inicio
    let newStart;
    if (dependsOnClosedTask) {
      // Si depende de una tarea cerrada, comienza el mismo día
      newStart = new Date(latestEndDate).toISOString().split('T')[0];
    } else {
      // Si no, comienza un día después
      const nextDay = new Date(latestEndDate);
      nextDay.setDate(nextDay.getDate() + 1);
      newStart = nextDay.toISOString().split('T')[0];
    }

    if (new Date(newStart) !== new Date(currentTask.start)) {
      const duration = calculateDuration(currentTask.start, currentTask.end);
      currentTask.start = newStart;
      currentTask.end = calculateNewEndDate(newStart, currentTask.duration);

      // Buscar y actualizar las tareas que dependen de esta
      tasks.forEach((task, index) => {
        if (task.dependsOn.some(dep => dep.taskId === taskIndex + 1)) {
          updateTask(index);
        }
      });
    }
  };

  // Actualizar todas las tareas que dependen directamente de la tarea modificada
  tasks.forEach((task, index) => {
    if (task.dependsOn.some(dep => dep.taskId === changedTaskIndex + 1)) {
      updateTask(index);
    }
  });
};
                  
                    const handleReturnToSelection = () => {
                      setShowSelection(true);
                          setSelectedCodigoProyecto('');
                          setSelectedNombreProyecto('');
                          setSubprocesos([]);
                      setTasks({
                        name: tasks.name,
                        groups: []
                      });
                    };



                    const lightenColor = (color: string, amount: number): string => {
                      const num = parseInt(color.replace("#", ""), 16);
                      const r = Math.min(255, Math.max(0, (num >> 16) + amount));
                      const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
                      const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
                      return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
                    };
                    
                    // Función para oscurecer colores
                    const darkenColor = (color: string, amount: number): string => {
                      return lightenColor(color, -amount);
                    };
                    
                  
                    const getSubprocessColor = (subprocess: string) => {
                      const predefinedColors = [
                        '#ADD8E6', '#90EE90', '#FFDAB9', '#E6E6FA', '#FFFACD', '#F5DEB3', '#D8BFD8', '#87CEFA', 
                        '#D3FFCE', '#F0E68C', '#E0FFFF', '#FAFAD2', '#FFB6C1', '#FFE4B5', '#98FB98', '#E0B0FF', 
                        '#FFCCCB', '#FFD700', '#B0E0E6', '#FFDEAD', '#FF69B4', '#FFE4E1', '#F0FFF0', '#E6E6FA'
                      ];
                  
                      let hash = 0;
                      for (let i = 0; i < subprocess.length; i++) {
                        hash = subprocess.charCodeAt(i) + ((hash << 5) - hash);
                      }
                  
                      return predefinedColors[Math.abs(hash) % predefinedColors.length];
                    };

                    
                    const handleSaveAsOpen = () => setIsSaveAsOpen(true);
                    const handleSaveAsClose = () => {
                      setIsSaveAsOpen(false);
                      setTemplateName('');
                      setTemplateDescription('');
                      setSaveAsError(null);
                      setIsOverwriteConfirmOpen(false);
                    };


                    
                    const handleSaveAs = async (overwrite: boolean = false) => {
                      try {
                        const plantillaData = {
                          nombre: templateName,
                          descripcion: templateDescription,
                          contenido: JSON.stringify({
                            nombre: templateName,
                            subprocesos: tasks.groups.map(group => {
                              const matchingSubprocess = subprocesos.find(sp => sp.subproceso === group.subprocess);
                              return {
                                nombre: matchingSubprocess ? matchingSubprocess.subproceso : "",
                                agrupadores: [{
                                  nombre: group.agrupador,
                                  enabled: group.enabled,
                                  tareas: group.tasks.map(task => ({
                                    id: task.id,
                                    nombre: task.name,
                                    responsable: task.responsible,
                                    progreso: task.progress,
                                    fecha_inicio: task.start,
                                    fecha_termino: task.end,
                                    descriptor: task.descriptor,
                                    organismo: task.organism,
                                    dependencia: task.dependsOn.length > 0 ? task.dependsOn[0].taskId : null,
                                    enabled: task.enabled
                                  }))
                                }]
                              };
                            })
                          }),
                          tipo: 'custom',
                          proceso: selectedCodigoProyecto,// Añadimos el proceso seleccionado
                          overwrite: overwrite
                        };
                    
               //         console.log('Sending data:', plantillaData);
                    
                        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/insert_plantilla.php', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(plantillaData),
                        });
                    
                        const result = await response.json();
               //         console.log('Received result:', result);
                    
                        if (result.success) {
               //           console.log('Plantilla guardada:', result);
                          handleSaveAsClose();
                          showLabelMessage('success', result.message || 'Planificación guardada exitosamente');
                        } else if (result.error === 'Ya existe una planificación con este nombre' && !overwrite) {
                          setIsOverwriteConfirmOpen(true);
                        } else {
                          throw new Error(result.error || 'Error al guardar la planificación');
                        }
                      } catch (error) {
                        console.error('Error:', error);
                        setSaveAsError('Error al guardar la planificación');
                      }
                    };
                    
    
            
                    const getSubprocessDescriptor = (group: Group): string | null => {
                      if (group.tasks.length === 0) return null;
                      const firstTaskDescriptor = group.tasks[0].descriptor;
                      return group.tasks.every(task => task.descriptor === firstTaskDescriptor) ? firstTaskDescriptor : null;
                    };
                    
                    const handleSavePlanificacion = async () => {
                      try {
                        await handleSaveToBackend();
               //         console.log('Planificación guardada en el backend');
                        showLabelMessage('success', 'Planificación guardada exitosamente');
                      } catch (error) {
                        console.error('Error:', error);
                        showLabelMessage('error', 'Error al guardar la planificación');
                      }
                    };
                  
                    const handleClearPlantilla = () => {
                      setTasks({
                        name: tasks.name,
                        groups: []
                      });
                    };
                  
                    const isPlantillaEmpty = () => {
                      return tasks.groups.length === 0 || tasks.groups.every(group => group.tasks.length === 0);
                    };


                    const toggleGroupEnabled = (groupIndex: number) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex 
                            ? { 
                                ...group, 
                                enabled: !group.enabled, 
                                tasks: group.tasks.map(task => ({ ...task, enabled: !group.enabled }))
                              }
                            : group
                        )
                      }));
                    };
                  

                    const updateDependenciesAfterDisabling = (tasks: Task[], disabledTaskId: number): Task[] => {
                      return tasks.map(task => ({
                        ...task,
                        dependsOn: task.dependsOn.filter(dep => dep.taskId !== disabledTaskId)
                      }));
                    };
//aca

const updateTramitacionSubtasksStartDate = (task: Task) => {
  if (!task.subtasks || task.subtasks.length === 0) return;
  
  const startDate = new Date(task.start);
  let currentDate = new Date(startDate);

  task.subtasks.forEach((subtask) => {
    if (!subtask.dependsOn || subtask.dependsOn.length === 0) {
      const duration = calculateDuration(subtask.start, subtask.end);
      subtask.start = currentDate.toISOString().split('T')[0];
      subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
      
      currentDate = new Date(subtask.end);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });
}; 

const updateTramitacionDates = (tasks: TasksState, groupIndex: number, taskIndex: number) => {
  const group = tasks.groups[groupIndex];
  const task = group.tasks[taskIndex];

  if (!task.isTramitacion || !task.subtasks || task.subtasks.length === 0) return;

  // Si TRAMITACIÓN tiene dependencias
  const hasDependencies = task.dependsOn && task.dependsOn.length > 0;
  if (hasDependencies) {
    // Obtener la fecha más tardía de las dependencias
    const maxEndDate = Math.max(...task.dependsOn.map(dep => {
      const depTask = group.tasks[dep.taskId - 1];
      return dateUtils.parseLocalDate(depTask.end).getTime();
    }));

    // Establecer la nueva fecha de inicio de TRAMITACIÓN
    const newStartDate = dateUtils.addDaysToDate(
      dateUtils.formatLocalDate(new Date(maxEndDate)),
      1
    );
    task.start = newStartDate;
  }

  // Actualizar subtareas
  updateSubtasksStartDate(task);

  // Reordenar subtareas basándose en dependencias
  const sortedSubtasks = topologicalSort(task.subtasks);

  // Ajustar fechas de subtareas basándose en dependencias
  sortedSubtasks.forEach(subtask => {
    if (subtask.dependsOn.length > 0) {
      const maxEndDate = Math.max(...subtask.dependsOn.map(dep => {
        const depSubtask = task.subtasks?.find(st => st.id === dep.subtaskId);
        return depSubtask ? dateUtils.parseLocalDate(depSubtask.end).getTime() : dateUtils.parseLocalDate(task.start).getTime();
      }));

      const newStart = dateUtils.addDaysToDate(
        dateUtils.formatLocalDate(new Date(maxEndDate)),
        1
      );

      const currentStart = dateUtils.parseLocalDate(subtask.start);
      if (dateUtils.parseLocalDate(newStart) > currentStart) {
        const duration = calculateDuration(subtask.start, subtask.end);
        subtask.start = newStart;
        subtask.end = calculateNewEndDate(newStart, subtask.duration);
      }
    }
  });

  // Actualizar fecha de término de TRAMITACIÓN
  const latestSubtaskEnd = Math.max(
    ...task.subtasks
      .filter(st => st.enabled)
      .map(st => dateUtils.parseLocalDate(st.end).getTime())
  );
  

  task.end = dateUtils.formatLocalDate(new Date(latestSubtaskEnd));
 // task.duration = calculateDuration(task.start, task.end);
};



                    const topologicalSort = (subtasks: SubTask[]): SubTask[] => {
                      const sorted: SubTask[] = [];
                      const visited = new Set<number>();
                    
                      const visit = (subtask: SubTask) => {
                        if (visited.has(subtask.id)) return;
                        visited.add(subtask.id);
                    
                        // Asegurarse de que dependsOn sea un array
                        const dependencies = Array.isArray(subtask.dependsOn) ? subtask.dependsOn : [];
                        
                        dependencies.forEach(dep => {
                          const depSubtask = subtasks.find(st => st.id === dep.subtaskId);
                          if (depSubtask) visit(depSubtask);
                        });
                        
                        sorted.unshift(subtask);
                      };
                    
                      subtasks.forEach(subtask => {
                        if (!visited.has(subtask.id)) visit(subtask);
                      });
                    
                      return sorted;
                    };
/*
                   const updateTramitacionSubtasksStartDate = (tasks: TasksState, groupIndex: number, taskIndex: number, newStartDate: string) => {
                      const group = tasks.groups[groupIndex];
                      const task = group.tasks[taskIndex];

                      if (task.isTramitacion && task.subtasks) {
                        // Verificar si TRAMITACIÓN tiene dependencias
                        const hasDependencies = task.dependsOn && task.dependsOn.length > 0;

                        if (!hasDependencies) {
                          const subtasksWithoutDependencies = task.subtasks.filter(subtask => subtask.dependsOn.length === 0);
                          subtasksWithoutDependencies.forEach(subtask => {
                            subtask.start = newStartDate;
                            const duration = calculateDuration(subtask.start, subtask.end);
                            subtask.end = calculateNewEndDate(subtask.start, duration);
                          });
                        }
                      }
                    };
                    */
                   // Función auxiliar para actualizar las subtareas de TRAMITACIÓN
                   

                    const updateSubtasksStartDate = (task: Task) => {
                      if (task.subtasks) {
                        task.subtasks.forEach(subtask => {
                          if (subtask.dependsOn.length === 0) {
                            const duration = calculateDuration(subtask.start, subtask.end);
                            subtask.start = task.start;
                            
                            subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
                          }
                        });
                      }
                    };
                    
                 

                    
const updateSubsequentTasks = (tasks: TasksState, groupIndex: number, tramitacionIndex: number) => {
  const group = tasks.groups[groupIndex];
  const tramitacionTask = group.tasks[tramitacionIndex];
  const tramitacionEndDate = new Date(tramitacionTask.end);
  
  for (let i = tramitacionIndex + 1; i < group.tasks.length; i++) {
    const task = group.tasks[i];
    const taskStartDate = new Date(task.start);
    
    // Verificar si la tarea depende directamente de TRAMITACIÓN
    const dependsOnTramitacion = task.dependsOn.some(dep => 
      dep.groupId === groupIndex && dep.taskId === tramitacionIndex + 1
    );
    
    if (dependsOnTramitacion && taskStartDate <= tramitacionEndDate) {
      // Mover la tarea para que comience un día después del fin de TRAMITACIÓN
      const newStartDate = new Date(tramitacionEndDate);
      newStartDate.setDate(newStartDate.getDate() + 1);
      
      const duration = calculateDuration(task.start, task.end);
      task.start = newStartDate.toISOString().split('T')[0];
      task.end = calculateNewEndDate(task.start, duration);
      
    }
  }
};

const moveSubtask = (groupIndex: number, taskIndex: number, subtaskIndex: number, direction: 'up' | 'down') => {
  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const task = newTasks.groups[groupIndex].tasks[taskIndex];
    
    if (!task.subtasks || task.subtasks.length < 2) return prevTasks;

    if (direction === 'up' && subtaskIndex > 0) {
      [task.subtasks[subtaskIndex], task.subtasks[subtaskIndex - 1]] = [task.subtasks[subtaskIndex - 1], task.subtasks[subtaskIndex]];
      [task.subtasks[subtaskIndex].orden, task.subtasks[subtaskIndex - 1].orden] = [task.subtasks[subtaskIndex - 1].orden, task.subtasks[subtaskIndex].orden];
    } else if (direction === 'down' && subtaskIndex < task.subtasks.length - 1) {
      [task.subtasks[subtaskIndex], task.subtasks[subtaskIndex + 1]] = [task.subtasks[subtaskIndex + 1], task.subtasks[subtaskIndex]];
      [task.subtasks[subtaskIndex].orden, task.subtasks[subtaskIndex + 1].orden] = [task.subtasks[subtaskIndex + 1].orden, task.subtasks[subtaskIndex].orden];
    }

    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};

                  const addSubtask = (groupIndex: number, taskIndex: number) => {
  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const task = newTasks.groups[groupIndex].tasks[taskIndex];

    if (!task.subtasks) task.subtasks = [];

    // Obtener la fecha de inicio de "TRAMITACIÓN" en el formato YYYY-MM-DD
    const [year, month, day] = task.start.split('-').map(Number);
    const tramitacionStartDate = new Date(year, month - 1, day); // Crear la fecha de "TRAMITACIÓN"

    // Calcular la fecha de término sumando 7 días
    const oneWeekLater = new Date(tramitacionStartDate);
    oneWeekLater.setDate(tramitacionStartDate.getDate() + 7); // Sumar 7 días a la fecha de inicio

    // Ajustar ambas fechas a medianoche (00:00:00) para comparar solo las fechas
    tramitacionStartDate.setHours(0, 0, 0, 0);
    oneWeekLater.setHours(0, 0, 0, 0);

    // Formatear las fechas a 'YYYY-MM-DD'
    const start = `${tramitacionStartDate.getFullYear()}-${String(tramitacionStartDate.getMonth() + 1).padStart(2, '0')}-${String(tramitacionStartDate.getDate()).padStart(2, '0')}`;
    const end = `${oneWeekLater.getFullYear()}-${String(oneWeekLater.getMonth() + 1).padStart(2, '0')}-${String(oneWeekLater.getDate()).padStart(2, '0')}`;

    const newSubtask: SubTask = {
      id: task.subtasks.length + 1,
      name: `Subtarea ${task.subtasks.length + 1}`,
      responsible: [],
      progress: 0,
      start: start, // Fecha de inicio es la misma que la de "TRAMITACIÓN"
      end: end,     // Fecha de término es una semana después
      duration: 1,  // Duración de 1 semana
      organism: task.organism,
      dependsOn: [],
      enabled: true,
      isClosed: false,
      type: 'RESOLUCIÓN',
      orden: task.subtasks.length + 1 // Añade esta línea


    };

    task.subtasks.push(newSubtask);
    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};



const deleteSubtask = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const task = newTasks.groups[groupIndex].tasks[taskIndex];
    task.subtasks.splice(subtaskIndex, 1);
    
    // Reasignar IDs
    task.subtasks.forEach((subtask: Task, index: number) => {
      subtask.id = index + 1;
    });

    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};



const handleSubtaskToggle = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const subtask = newTasks.groups[groupIndex].tasks[taskIndex].subtasks[subtaskIndex];
    subtask.enabled = !subtask.enabled;
    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};


                    const toggleTaskEnabled = (groupIndex: number, taskIndex: number) => {
                      setTasks(prevTasks => {
                        const newTasks: TasksState = JSON.parse(JSON.stringify(prevTasks)); // Copia profunda
                        const group = newTasks.groups[groupIndex];
                        const task = group.tasks[taskIndex];
                        const newEnabled = !task.enabled;
                  
                        // Actualizar el estado de la tarea
                        group.tasks[taskIndex] = { ...task, enabled: newEnabled };
                  
                        if (!newEnabled) {
                          // Si la tarea se está deshabilitando, actualizar las dependencias en todas las tareas de todos los grupos
                          newTasks.groups = newTasks.groups.map((g: Group) => ({
                            ...g,
                            tasks: updateDependenciesAfterDisabling(g.tasks, task.id)
                          }));
                        }
                  
                        return newTasks;
                      });
                    };
                  
                  

                    const handleGroupCheckbox = (groupIndex: number, checked: boolean) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex
                            ? {
                                ...group,
                                tasks: group.tasks.map(task => ({ ...task, enabled: checked }))
                              }
                            : group
                        )
                      }));
                    };
                  


                    const FloatingActionBar = () => (
                      <div className="fixed right-4 bottom-5  flex flex-col space-y-2 z-50">
                              <Button
                          onClick={handleSavePlanificacion}
                          className="rounded-s-full w-12 h-12 bg-teal-500 hover:bg-teal-600 transition-colors text-white shadow-lg"
                          title="Guardar"
                        >
                          <Save className="h-6 w-6" />
                        </Button>
                        
                        <Button onClick={addNewGroup}   
                        className="rounded-s-full w-12 h-12 bg-red-300 hover:bg-red-500 transition-colors text-white shadow-lg"
                        title="Añadir agrupador"
                        >
                              <PlusCircleIcon className="w-6 h-6" /> 
                              </Button>
                        
                  
                        
                        <Button
                          onClick={toggleGlobalExpansion}
                          className="rounded-s-full w-12 h-12 bg-purple-500 hover:bg-purple-600 transition-colors text-white shadow-lg"
                          title={isGloballyExpanded ? "Colapsar Todo" : "Expandir Todo"}
                        >
                          {isGloballyExpanded ? <Minimize className="h-6 w-6" /> : <Maximize className="h-6 w-6" />}
                        </Button>
                     
                        <Button
                          onClick={() => setShowGanttModal(true)}
                          className="rounded-s-full w-12 h-12 bg-yellow-500 hover:bg-yellow-700 transition-colors text-white shadow-lg"
                          title="Vista Gantt"
                        >
                          <GanttChartIcon className="h-6 w-6" />
                        </Button>
                      </div>
                    );
                  

                    const renderTableHeaders = (group: Group) => {
                      if (group.tasks.length === 0) return null;
                    
                      const allTasksEnabled = group.tasks.every(task => task.enabled);
                      const subprocessColor = subprocessColors[group.subprocess] || "#8C8C8C";
                    
                      return (
                        <thead style={{ backgroundColor: lightenColor(subprocessColor, 80) }}>
                          <tr>
                            <th className="p-2 text-center w-10"></th>
                            <th className="p-2 text-center w-10">#</th>
                            <th className="p-2 w-1/5 text-left min-w-[200px]">Nombre de las tareas</th>
                            <th className="p-2 text-center">Responsable</th>
                            <th className="p-2 text-center">Fecha de Inicio</th>
                            <th className="p-2 text-center">Fecha de término</th>
                            <th className="p-2 text-center">Duración</th>
                            <th className="p-2 text-center">Dependencias</th>
                            <th className="p-2 text-center">Estado</th>

                            <th className="p-2 text-center">Acciones</th>
                            {group.descriptor === 'PERMISOLOGÍA' && <th className="p-2 text-center">Subtareas</th>}
                          </tr>
                        </thead>
                      );
                    };

                    const StickyGanttButton = () => (
                      <button 
                        onClick={() => setShowGanttModal(true)}
                        className={`fixed bottom-4 right-4 w-12 h-12 text-white rounded-full shadow-lg flex items-center justify-center transition-colors duration-300 z-50`}
                        style={{
                          backgroundColor: darkenColor("#49D4D7", 60)
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = darkenColor("#49D4D7", 60)}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#49D4D7"}
                      >
                        <GanttChartIcon className="w-6 h-6" />
                      </button>
                    );
               //     console.log('Datos filtrados para Gantt:', filterEnabledTasksAndGroups(tasks));

                  
                //    console.log(JSON.stringify(filterEnabledTasksAndGroups(tasks)));
                    const GanttModal = () => (
                      <Dialog className='' isOpen={showGanttModal} onClose={() => setShowGanttModal(false)} width="1200px" height="700px">
                        <DialogContent className="w-full">
                          <DialogHeader>
                            <DialogTitle>Carta Gantt</DialogTitle>
                          </DialogHeader>
                          <div className="">
                            
                          <GanttChartView tasks={filterEnabledTasksAndGroups(tasks)} />
                          </div>
                          <DialogFooter>
                            <Button className="bg-teal-500 text-white hover:bg-teal-800" onClick={() => setShowGanttModal(false)}>Cerrar</Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    );

                    if (showSelection) {
                      return <TemplateSelectionInterface onProcessSelected={handleProcessSelected} />;
                    }
                  
                    return (
                      <Card className="w-full max-w-7xl border-none mx-auto overflow-x-auto">
                        <CardHeader className="flex justify-between items-center w-full">
                          <div className="items-center mb-3 w-full">
                            
                            <Button 
                              onClick={handleReturnToSelection} 
                              variant="default" 
                              size="sm"
                              className="mr-2"
                            >
                              <ArrowLeftCircle className="w-4 h-4 mr-2" /> Volver a Selección
                            </Button>
                            <div className="w-full shadow-lg rounded-xl flex items-center bg-custom-header mt-3">
                              <div className="flex items-center justify-center w-full">
                                <h1 className="text-xl font-bold text-center text-white py-3 px-4 mt-2 rounded inline-block">
                                {tasks.name}
                           
                                </h1>
                              </div>
                            </div>
                           
                          </div>
                       
                        </CardHeader>
                        <CardContent>
                    
                          {/*<div className="flex items-center justify-between mb-4">*/}
                         {/*   <Button onClick={addNewGroup} variant="outline" size="sm">
                              <Plus className="w-4 h-4 mr-2" /> Añadir Nuevo Grupo
                            </Button>*/}
                         {/*   <Button onClick={toggleGlobalExpansion} variant="outline" size="sm">
                              {isGloballyExpanded ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
                              {isGloballyExpanded ? "Colapsar Todo" : "Expandir Todo"}
                            </Button>
                          */}
                        {/*  </div>*/}
                  
                        {tasks.groups.map((group, groupIndex) => {
  const subprocessColor = subprocessColors[group.subprocess] || "#ADADAD";
  

  return (
    <div key={groupIndex} className={`mb-4 border rounded ${!group.enabled ? 'opacity-50' : ''}`}>
      <div className="relative pr-3" style={{ backgroundColor: lightenColor(subprocessColor, 60) }}>
        <div className="flex items-center">
          <div className="py-2 pr-4 flex items-center rounded-lg" style={{ backgroundColor: subprocessColor }}>
            <input
              type="checkbox"
              checked={group.enabled}
              onChange={() => toggleGroupEnabled(groupIndex)}
              className="mr-2 ml-4"
            />
            <Button 
              onClick={() => toggleGroup(groupIndex)}
              variant="ghost"
              size="sm"
              className="mr-2"
            >
              {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
            </Button>
            <div className="flex items-center">
              {renderSubprocessSelect(group, groupIndex)}
            </div>
          </div>

          <div className="flex-grow flex items-center ml-4">
            <div className="flex-wrap items-center">
              <Button 
                onClick={() => toggleGroup(groupIndex)}
                variant="ghost"
                size="sm"
                className="mr-2"
              >
                {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
              </Button>
              <span className="font-bold mr-2">Agrupador:</span>
              {renderEditableField('group', groupIndex, null, 'agrupador', group.agrupador)}
              {renderOrganismRow(group, groupIndex)}
            </div>
          </div>

          <div className="flex items-center space-x-1 ml-4">
            <select
              value={group.descriptor}
              onChange={(e) => handleDescriptorChange(groupIndex, e.target.value as 'GESTIÓN' | 'PERMISOLOGÍA')}
              className={`ml-2 mr-3 px-2 py-1 rounded-full text-xs font-semibold text-white`}
              style={{ backgroundColor: darkenColor(subprocessColor, 60) }}
            >
              <option value="GESTIÓN">GESTIÓN</option>
              <option value="PERMISOLOGÍA">PERMISOLOGÍA</option>
            </select>
            {group.descriptor === 'PERMISOLOGÍA' && !group.tasks.some(task => task.name === 'TRAMITACIÓN') && (
  <Button
    onClick={() => addTramitacionTask(groupIndex)}
    variant="outline"
    size="sm"
    className="p-1 font-bold"
    title="Agregar TRAMITACIÓN"
  >
    T
  </Button>
)}
      <Button
        onClick={() => forceUpdateDates(groupIndex)}
        variant="outline"
        size="sm"
        className="p-1"
        title="Forzar actualización de fechas"
      >
        <RefreshCcwDot className="w-4 h-4" />
      </Button>
            <Button
              onClick={() => moveGroup(groupIndex, 'up')}
              variant="outline"
              size="sm"
              disabled={groupIndex === 0}
              className="p-1"
            >
              <ArrowUp className="w-4 h-4" />
            </Button>
            <Button
              onClick={() => moveGroup(groupIndex, 'down')}
              variant="outline"
              size="sm"
              disabled={groupIndex === tasks.groups.length - 1}
              className="p-1"
            >
              <ArrowDown className="w-4 h-4" />
            </Button>
            <Button
              onClick={() => copyGroup(groupIndex)}
              variant="outline"
              size="sm"
              className="p-1"
              title="Copiar Agrupador"
            >
              <Copy className="w-4 h-4" />
            </Button>
            <Button
              onClick={() => resetGroupTasks(groupIndex)}
              variant="outline"
              size="sm"
              className="p-1"
              title="Resetear tareas"
            >
              <Undo2Icon className="w-4 h-4" />
            </Button>
            <Button
              onClick={() => deleteGroup(groupIndex)}
              variant="outline"
              size="sm"
              className="text-red-500 p-1"
            >
              <Trash2 className="w-4 h-4" />
            </Button>
            
          </div>
        </div>
      </div>

      {group.expanded && (
        <div className="p-2 shadow-lg">
          {group.tasks.length > 0 ? (
            <table className="w-full table-fixed">
              {renderTableHeaders(group)}
              <tbody>
                {group.tasks.filter(task => !task.hidden).map((task, taskIndex) => {
const isExpanded = expandedTramitacion[groupIndex]?.[taskIndex] ?? true;


                    return (
                  <React.Fragment key={taskIndex}>
                    <tr className={`border-b ${!task.enabled ? 'opacity-50' : ''}`}>
                      <td className="p-2 text-center">
                        <input
                          type="checkbox"
                          checked={task.enabled}
                          onChange={() => toggleTaskEnabled(groupIndex, taskIndex)}
                          disabled={!group.enabled}
                          className={`mr-2 ${!group.enabled ? 'cursor-not-allowed' : ''}`}
                        />
                      </td>
                      <td className="p-2 text-center">{taskIndex + 1}</td>
                      <td className="p-2 text-center">
                        <div className="flex items-center">
                        {task.isTramitacion && (
              <Button
                onClick={() => toggleTramitacion(groupIndex, taskIndex)}
                variant="ghost"
                size="sm"
                className="mr-2"
              >
                {isExpanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
              </Button>
            )}

                          {renderEditableField('task', groupIndex, taskIndex, 'name', task.name)}
                        </div>
                      </td>
                      <td className="p-2 text-center">
                      <UserSelectorModal
    users={users}
    selectedUsers={task.id < 0 ? [] : task.responsible} // Forzar array vacío para tareas nuevas
    onUsersChange={(newUsers) => handleSave('task', groupIndex, taskIndex, 'responsible', newUsers)}
    enabled={group.enabled && task.enabled}
    taskId={task.id}
    groupId={groupIndex + 1}
  />
</td>

                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'start', task.start)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'end', task.end)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'duration', null)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'dependsOn', task.dependsOn)}
                      </td>
                      <td className="p-2 text-center">
  <TaskStatusField
    task={{
      ...task, 
      isClosed: task.isClosed ?? false, // Valor por defecto si falta `isClosed`
      progress: task.progress ?? 0       // Valor por defecto si falta `progress`
    }}
    groupIndex={groupIndex}
    taskIndex={taskIndex}
    subprocessColor={subprocessColor}
    onSave={(type, groupIdx, taskIdx, field, value) => 
      handleSave(type as 'task' | 'group' | 'subtask', groupIdx, taskIdx, field, value)
    }
    disabled={!group.enabled || !task.enabled}
  />
</td>

                      <td className="p-2">
                        <div className="flex justify-center items-center space-x-1">
                          <Button
                            onClick={() => moveTask(groupIndex, taskIndex, 'up')}
                            variant="outline"
                            size="sm"
                            disabled={taskIndex === 0}
                            className="p-1"
                          >
                            <ArrowUp className="w-4 h-4" />
                          </Button>
                          <Button
                            onClick={() => moveTask(groupIndex, taskIndex, 'down')}
                            variant="outline"
                            size="sm"
                            disabled={taskIndex === group.tasks.length - 1}
                            className="p-1"
                          >
                            <ArrowDown className="w-4 h-4" />
                          </Button>
                          <Button
                            onClick={() => deleteTask(groupIndex, taskIndex)}
                            variant="outline"
                            size="sm"
                            className="text-red-500 p-1"
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        </div>
                      </td>
                      {group.descriptor === 'PERMISOLOGÍA' && task.isTramitacion && (
        <td className="p-2 text-center">
          <div className="grid grid-cols-2 pl-5  gap-1">
  <Button
    onClick={() => addSpecificSubtask(groupIndex, taskIndex, 'INGRESO')}
    variant="outline"
    size="sm"
    className="p-1"
  >
    IN
  </Button>
  <Button
    onClick={() => addSpecificSubtask(groupIndex, taskIndex, 'OBSERVACIÓN')}
    variant="outline"
    size="sm"
    className="p-1"
  >
    OB
  </Button>
  <Button
    onClick={() => addSpecificSubtask(groupIndex, taskIndex, 'RESPUESTA')}
    variant="outline"
    size="sm"
    className="p-1"
    
  >
    RP
  </Button>
  <Button
    onClick={() => addSpecificSubtask(groupIndex, taskIndex, 'RESOLUCIÓN')}
    variant="outline"
    size="sm"
    className="p-1"
  >
    RS
  </Button>
</div>

        </td>
      )}
                    </tr>

                    {task.isTramitacion && isExpanded && task.subtasks && task.subtasks.map((subtask, subtaskIndex) => (
        <tr key={`subtask-${subtaskIndex}`} className={`bg-gray-100 ${!subtask.enabled ? 'opacity-50' : ''}`}>
        <td className="p-2 text-center">
                          <input
                            type="checkbox"
                            checked={subtask.enabled}
                            onChange={() => handleSubtaskToggle(groupIndex, taskIndex, subtaskIndex)}
                            className="mr-2"
                          />
                        </td>
                        
                        <td className="p-2 text-center">{`${taskIndex + 1}.${subtaskIndex + 1}`}</td>
                        <td className="p-2">
                          {/*   <Button
            onClick={() => openResolutionModal(groupIndex, taskIndex, subtaskIndex)}
            variant="outline"
            size="sm"
            className="p-1"
          >
            <Settings2 className="w-4 h-4" />
          </Button>*/}
      {subtask.type === 'RESOLUCIÓN' ? (
  <div className="flex space-x-2">
    {/* Código para RESOLUCIÓN */}
    <div className="flex ">
      {getResolutionIcon(subtask.resolutionType || 'No especificado')}
      <span 
        className="font-normal cursor-pointer" 
        onClick={() => openResolutionModal(groupIndex, taskIndex, subtaskIndex)}
      >
        <span className={getResolutionColor(subtask.resolutionType || 'No especificado')} >
          &nbsp;<b>RESOLUCIÓN:</b> {subtask.resolutionType || 'No especificado'}
        </span>
      </span>
    </div>
  </div>
) : subtask.type === 'INGRESO' ? (
  <div className="flex space-x-2">
    {/* Código para INGRESO */}
      <span className="font-normal" >
        &nbsp;<b>INGRESO</b>
      </span>
  </div>
) : (
  renderEditableField('subtask', groupIndex, taskIndex, 'name', subtask.name, subtaskIndex)
)}

    </td>
    <td className="p-2 text-center">
  {(() => {
    if (subtask.type === 'OBSERVACIÓN' || 
        (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType && subtask.resolutionType !== 'DESISTIMIENTO')) {
      // Mostrar el organismo
      return <span className="text-sm text-gray-600 italic">{group.organismo || 'Sin organismo'}</span>;
    } else if (subtask.type === 'INGRESO' || 
              subtask.type === 'RESPUESTA' || 
              (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType === 'DESISTIMIENTO')) {
      // Mostrar el icono con los responsables de TRAMITACIÓN
      const hasResponsibles = Array.isArray(task.responsible) && task.responsible.length > 0;
      return (
        <div 
          className={`flex justify-center ${hasResponsibles ? 'cursor-pointer hover:opacity-80' : ''}`}
          title={hasResponsibles ? 
            `Responsables: ${task.responsible.map(u => `${u.firstname} ${u.lastname}`).join(', ')}` : 
            'Sin responsables asignados'}
        >
          <UserIcon 
            className={`w-5 h-5 ${hasResponsibles ? 'text-green-500' : 'text-gray-400'}`}
          />
        </div>
      );
    }
    return null;
  })()}
</td>

                        <td className="p-2  text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'start', subtask.start, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'end', subtask.end, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'duration', subtask.duration, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'dependsOn', subtask.dependsOn, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
      <SubtaskStatusField
        subtask={{
          isClosed: subtask.isClosed ?? false,
          progress: subtask.progress ?? 0
        }}
        groupIndex={groupIndex}
        taskIndex={taskIndex}
        subtaskIndex={subtaskIndex}
        subprocessColor={subprocessColor}
        onSave={handleSave}
        disabled={!group.enabled || !task.enabled || !subtask.enabled}
      />
    </td>

                        <td className="p-2">
                          <div className="flex justify-center items-center space-x-1">
                            <Button
                              onClick={() => moveSubtask(groupIndex, taskIndex, subtaskIndex, 'up')}
                              variant="outline"
                              size="sm"
                              disabled={subtaskIndex === 0}
                              className="p-1"
                            >
                              <ArrowUp className="w-4 h-4" />
                            </Button>
                            <Button
                              onClick={() => moveSubtask(groupIndex, taskIndex, subtaskIndex, 'down')}
                              variant="outline"
                              size="sm"
                              disabled={subtaskIndex === (task.subtasks?.length ?? 0) - 1}
                              className="p-1"
                            >
                              <ArrowDown className="w-4 h-4" />
                            </Button>
                            <Button
                              onClick={() => deleteSubtask(groupIndex, taskIndex, subtaskIndex)}
                              variant="outline"
                              size="sm"
                              className="text-red-500 p-1"
                            >
                              <Trash2 className="w-4 h-4" />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                 );
                })}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-500 py-4">No hay tareas en este agrupador.</p>
          )}
          <Button
            onClick={() => addNewTask(groupIndex)}
            variant="outline"
            size="sm"
            className="mt-2"
          >
            <Plus className="w-4 h-4 mr-2" /> Añadir nueva tarea
          </Button>
        </div>
      )}
    </div>
  );
})}

      </CardContent>
     
    
      <div className="flex justify-center space-x-4 mt-4">
      
        
      {/*  <Button
          onClick={handleClearPlantilla}
          variant="outline"
          size="lg"
          className="bg-red-500 text-white"
        >
          <Trash2 className="w-4 h-4 mr-2" /> Limpiar Plantilla
        </Button>*/}
      </div>

      {labelMessage && (
        <div className="mb-4">
          <LabelMessage type={labelMessage.type} message={labelMessage.message} />
        </div>
      )}
        
      <Dialog isOpen={isSaveAsOpen} onClose={handleSaveAsClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Guardar plantilla como</DialogTitle>
          </DialogHeader>
          {saveAsError && (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <span className="block sm:inline">{saveAsError}</span>
  </div>
)}
          <Input
            placeholder="Nombre de la plantilla"
            value={templateName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value)}
            className='mb-2'
          />
          <Input
            placeholder="Descripción de la plantilla"
            value={templateDescription}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateDescription(e.target.value)}
          />
          
          <DialogFooter>
            <Button onClick={handleSaveAsClose} variant="outline">Cancelar</Button>
            <Button onClick={() => handleSaveAs(false)} className="bg-teal-500 ml-3 border text-white hover:bg-teal-800" variant="default">Guardar</Button>

          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog isOpen={isOverwriteConfirmOpen} onClose={handleOverwriteCancel}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>Confirmar sobrescritura</DialogTitle>
    </DialogHeader>
    <p>Ya existe una plantilla con este nombre. ¿Desea sobrescribirla?</p>
    <DialogFooter>
      <Button onClick={handleOverwriteCancel} variant="outline">Cancelar</Button>
      <Button onClick={handleOverwriteConfirm} className="bg-red-500 ml-3 border text-white hover:bg-red-800" variant="default">Sobrescribir</Button>
    </DialogFooter>
  </DialogContent>
</Dialog>
<FloatingActionBar />
<GanttModal />



<ResolutionModal
        isOpen={resolutionModalOpen}
        onClose={() => setResolutionModalOpen(false)}
        onSave={(type, customType) => {
          if (currentResolutionSubtask) {
            handleResolutionTypeChange(
              currentResolutionSubtask.groupIndex,
              currentResolutionSubtask.taskIndex,
              currentResolutionSubtask.subtaskIndex,
              type,
              customType
            );
          }
          setResolutionModalOpen(false);
        }}
      />

    </Card>
      
  
 
  );
  
};

export default Planificacion;