import React, { useState } from 'react';
import {
  PieChart, Pie, BarChart, Bar, LineChart, Line,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Cell, Treemap, Area, ComposedChart
} from 'recharts';
import { Maximize2, X } from 'lucide-react';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      name: string;
      size?: number;
      activas?: number;
      cerradas?: number;
      value?: number;
    };
  }>;
}

interface TreemapRenderProps {
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  name: string;
}

interface ExpandButtonProps {
  onClick: () => void;
  expanded: boolean;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div className="bg-white p-2 border border-gray-200 rounded shadow-lg">
        <p className="font-bold text-gray-800">{data.name}</p>
        {data.size && <p className="text-gray-600">Total: {data.size} tareas</p>}
        {data.activas && <p className="text-green-600">Activas: {data.activas}</p>}
        {data.cerradas && <p className="text-blue-600">Cerradas: {data.cerradas}</p>}
        {data.value && <p className="text-gray-600">Valor: {data.value}</p>}
      </div>
    );
  }
  return null;
};

const ExpandButton: React.FC<ExpandButtonProps> = ({ onClick, expanded }) => (
  <button 
    onClick={onClick}
    className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-100 transition-colors"
  >
    {expanded ? (
      <X className="w-5 h-5 text-gray-600" />
    ) : (
      <Maximize2 className="w-5 h-5 text-gray-600" />
    )}
  </button>
);


interface SeasonalDataItem {
  tiempo: string;
  nuevas: number;
  cerradas: number;
  activas: number;
  totalActivas: number;
}

const Dashboard: React.FC = () => {
  const [expandedGraph, setExpandedGraph] = useState<string | null>(null);

  const pieData = [
    { name: 'Proceso 1', value: 6, color: '#FF8042' },
    { name: 'Proceso 2', value: 3, color: '#00C49F' },
    { name: 'Proceso 3', value: 2, color: '#FFBB28' },
    { name: 'Proceso 4', value: 4, color: '#0088FE' }
  ];

  const barData = [
    { name: 'Dept 1', abiertas: 7, cerradas: 5 },
    { name: 'Dept 2', abiertas: 4, cerradas: 4 },
    { name: 'Dept 3', abiertas: 3, cerradas: 3 },
    { name: 'Dept 4', abiertas: 2, cerradas: 2 }
  ];

  const lineData = Array.from({ length: 12 }, (_, i) => {
    const p1 = Math.floor(Math.random() * 10 + 15);
    const p2 = Math.floor(Math.random() * 8 + 10);
    const p3 = Math.floor(Math.random() * 6 + 8);
    const p4 = Math.floor(Math.random() * 5 + 5);
    return {
      tiempo: `Mes ${i + 1}`,
      'Proceso 1': p1,
      'Proceso 2': p2,
      'Proceso 3': p3,
      'Proceso 4': p4,
      total: p1 + p2 + p3 + p4
    };
  });

const generateSeasonalPattern = (): SeasonalDataItem[] => {
  return Array.from({ length: 12 }, (_, i) => {
    const baseNuevas = 50 + 
      (i === 2 ? 30 : 0) +
      (i === 6 ? 25 : 0) +
      (i === 9 ? 20 : 0) +
      Math.floor(Math.random() * 10);

    const tasaCierre = 0.8 + 
      ((i === 3 || i === 7 || i === 10) ? 0.1 : 0) + 
      (Math.random() * 0.1);

    const cerradas = Math.floor((baseNuevas * tasaCierre));

    return {
      tiempo: `Mes ${i + 1}`,
      nuevas: baseNuevas,
      cerradas: cerradas,
      activas: baseNuevas - cerradas,
      totalActivas: 0 // Initial value will be updated in the map
    };
  }).map((mes, index, array): SeasonalDataItem => {
    const totalPrevio = index > 0 ? array[index - 1].totalActivas : 0;
    const totalActivas = totalPrevio + mes.activas;
    
    return {
      ...mes,
      totalActivas
    };
  });
};

  const seasonalData = generateSeasonalPattern();

  const treeMapData = {
    name: 'Tareas Totales',
    children: [
      { name: 'Proceso 1', size: 45, color: '#FF8042', activas: 25, cerradas: 20 },
      { name: 'Proceso 2', size: 35, color: '#00C49F', activas: 18, cerradas: 17 },
      { name: 'Proceso 3', size: 28, color: '#FFBB28', activas: 15, cerradas: 13 },
      { name: 'Proceso 4', size: 22, color: '#0088FE', activas: 12, cerradas: 10 }
    ]
  };

  const stackedData = [
    { name: 'Procesos', dept1: 12, dept2: 8, dept3: 6, dept4: 4 },
    { name: 'Usuarios', dept1: 4, dept2: 3, dept3: 3, dept4: 5 },
    { name: 'Tareas', dept1: 24, dept2: 16, dept3: 12, dept4: 8 }
  ];

  const version = "v1.0.2";

  const handleExpand = (graphId: string): void => {
    setExpandedGraph(expandedGraph === graphId ? null : graphId);
  };

  const getGraphContainerClass = (graphId: string): string => {
    if (expandedGraph === null) {
      return "bg-white p-4 rounded-lg shadow relative";
    }
    if (expandedGraph === graphId) {
      return "fixed top-0 left-0 w-full h-full bg-white z-50 p-8 flex flex-col";
    }
    return "hidden";
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen relative">
      {/*<h1 className="text-2xl font-bold mb-6 text-center">Panel de Control de Procesos</h1>*/}
      
      <div className={expandedGraph ? "hidden" : "grid grid-cols-2 lg:grid-cols-3 gap-4"}>
        {/* G1: Distribución de Procesos */}
        <div className={getGraphContainerClass('G1')}>
          <h2 className="text-lg font-semibold mb-2">G1: Distribución Total de Procesos</h2>
          <ExpandButton onClick={() => handleExpand('G1')} expanded={expandedGraph === 'G1'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G1' ? "90%" : 200}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={expandedGraph === 'G1' ? 200 : 60}
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          <div className="text-center mt-2 text-sm text-gray-600">
            Total de Procesos: {pieData.reduce((acc, curr) => acc + curr.value, 0)}
          </div>
        </div>

        {/* G2: Estado Actual */}
        <div className={getGraphContainerClass('G2')}>
          <h2 className="text-lg font-semibold mb-2">G2: Estado Actual de Tareas</h2>
          <ExpandButton onClick={() => handleExpand('G2')} expanded={expandedGraph === 'G2'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G2' ? "90%" : 200}>
            <BarChart data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="abiertas" fill="#82ca9d" />
              <Bar dataKey="cerradas" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* G3: Evolución Temporal */}
        <div className={getGraphContainerClass('G3')}>
          <h2 className="text-lg font-semibold mb-2">G3: Evolución Temporal</h2>
          <ExpandButton onClick={() => handleExpand('G3')} expanded={expandedGraph === 'G3'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G3' ? "90%" : 200}>
            <LineChart data={lineData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="tiempo" />
              <YAxis label={{ value: 'Tareas Activas', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Proceso 1" stroke="#FF8042" />
              <Line type="monotone" dataKey="Proceso 2" stroke="#00C49F" />
              <Line type="monotone" dataKey="Proceso 3" stroke="#FFBB28" />
              <Line type="monotone" dataKey="Proceso 4" stroke="#0088FE" />
            </LineChart>
          </ResponsiveContainer>
        </div>


        {/* G4: Mapa de Distribución */}
        <div className={getGraphContainerClass('G4')}>
          <h2 className="text-lg font-semibold mb-2">G4: Distribución de Tareas</h2>
          <ExpandButton onClick={() => handleExpand('G4')} expanded={expandedGraph === 'G4'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G4' ? "auto" :150}> 
            <Treemap
              data={treeMapData.children}
              dataKey="size"
              aspectRatio={4/3}
              stroke="#fff"
            >
              {(props: TreemapRenderProps) => {
                const { x, y, width, height, index, name } = props;
                return (
                  <g>
                    <rect
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      style={{
                        fill: treeMapData.children[index].color,
                        stroke: '#fff',
                        strokeWidth: 2,
                        strokeOpacity: 1,
                      }}
                    />
                    {width > 50 && height > 30 && (
                      <text
                        x={x + width / 2}
                        y={y + height / 2}
                        textAnchor="middle"
                        fill="#fff"
                        fontSize={12}
                      >
                        {name}
                      </text>
                    )}
                  </g>
                );
              }}
              <Tooltip content={<CustomTooltip />} />
            </Treemap>
          </ResponsiveContainer>
          <div className="text-center mt-2 text-sm text-gray-600">
            Total de Tareas: {treeMapData.children.reduce((acc, curr) => acc + curr.size, 0)}
          </div>
        </div>

        {/* G5: Evolución Temporal con Estacionalidad */}
        <div className={getGraphContainerClass('G5')}>
          <h2 className="text-lg font-semibold mb-2">G5: Evolución Temporal con Estacionalidad</h2>
          <ExpandButton onClick={() => handleExpand('G5')} expanded={expandedGraph === 'G5'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G5' ? "90%" : 200}>
            <ComposedChart data={seasonalData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="tiempo" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="nuevas"
                fill="#82ca9d"
                stroke="#82ca9d"
                fillOpacity={0.3}
                name="Tareas Nuevas"
              />
              <Line
                type="monotone"
                dataKey="totalActivas"
                stroke="#8884d8"
                strokeWidth={2}
                name="Total Activas"
              />
              <Line
                type="monotone"
                dataKey="cerradas"
                stroke="#ff7300"
                strokeWidth={2}
                name="Tareas Cerradas"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        {/* G6: Métricas Consolidadas */}
        <div className={getGraphContainerClass('G6')}>
          <h2 className="text-lg font-semibold mb-2">G6: Métricas Consolidadas</h2>
          <ExpandButton onClick={() => handleExpand('G6')} expanded={expandedGraph === 'G6'} />
          <ResponsiveContainer width="100%" height={expandedGraph === 'G6' ? "90%" : 200}>
            <BarChart data={stackedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="dept1" stackId="a" fill="#FF8042" />
              <Bar dataKey="dept2" stackId="a" fill="#00C49F" />
              <Bar dataKey="dept3" stackId="a" fill="#FFBB28" />
              <Bar dataKey="dept4" stackId="a" fill="#0088FE" />
              </BarChart>
          </ResponsiveContainer>
          <div className="text-center mt-2 text-sm text-gray-600">
            Total por Categoría: {stackedData.reduce((acc, curr) => 
              acc + curr.dept1 + curr.dept2 + curr.dept3 + curr.dept4, 0
            )}
          </div>
        </div>
      </div>

      {/* Versión del dashboard */}
      <div className="absolute bottom-4 left-4 text-sm text-gray-600">
        Versión: {version}
      </div>
    </div>
  );
};

export default Dashboard;

