import React, { useState } from 'react';
import { Button } from '../ui/button';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ReferenceLine
} from 'recharts';
import {
  Check,
  X,
  Plus,
  Clock,
  AlertCircle,
  Info,
  FileQuestion,
  CheckCircle2,
  XCircle,
  AlertTriangle,
  LucideIcon
} from 'lucide-react';

// Define interfaces for type safety
interface EstadoConfig {
  color: string;
  icon: LucideIcon;
}

interface Estados {
  [key: string]: EstadoConfig;
}

interface AdicionalData {
  id: number;
  numero: string;
  asunto: string;
  documentos: string;
  nombre: string;
  dias: number;
  montoSolicitado: number;
  montoAprobado: number;
  estatus: string;
  justificacion: string;
}

function AdicionalDashboard() {
  const ESTADOS: Estados = {
    'En Revisión': { color: 'text-blue-500', icon: Clock },
    'Aprobado': { color: 'text-green-600', icon: CheckCircle2 },
    'Rechazado': { color: 'text-red-600', icon: XCircle },
    'Pendiente Información': { color: 'text-orange-500', icon: FileQuestion },
    'En Proceso': { color: 'text-purple-500', icon: Info },
    'Cerrado': { color: 'text-gray-600', icon: Check },
    'Anulado': { color: 'text-red-800', icon: X },
    'Extemporáneo': { color: 'text-yellow-600', icon: AlertTriangle }
  };

  const [data] = useState([
    { 
      id: 1, 
      numero: 'Adicional N°1', 
      asunto: 'Solicitud de especificaciones técnicas',
      documentos: 'Ver documentos', 
      nombre: 'Adicional 1', 
      dias: 4,
      montoSolicitado: 150.50,
      montoAprobado: 145.80,
      estatus: 'Aprobado',
      justificacion: 'Cumple con especificaciones técnicas requeridas' 
    },
    { 
      id: 2, 
      numero: 'Adicional N°2', 
      asunto: 'Cambio en materiales de construcción',
      documentos: 'Ver documentos', 
      nombre: 'Adicional 2',
      dias: 2,
      montoSolicitado: 280.30,
      montoAprobado: 275.50,
      estatus: 'Aprobado',
      justificacion: 'Materiales cumplen normativa vigente'
    },
    { 
      id: 3, 
      numero: 'Adicional N°3', 
      asunto: 'Actualización de planos estructurales',
      documentos: 'Ver documentos', 
      nombre: 'Adicional 3',
      dias: 1,
      montoSolicitado: 95.70,
      montoAprobado: 95.70,
      estatus: 'Aprobado',
      justificacion: 'Actualización necesaria según normativa'
    },
    { 
      id: 4, 
      numero: 'Adicional N°4', 
      asunto: 'Consulta sobre normativa sísmica',
      documentos: 'Ver documentos', 
      nombre: 'Adicional 4',
      dias: 8,
      montoSolicitado: 320.40,
      montoAprobado: 318.20,
      estatus: 'Aprobado',
      justificacion: 'Aprobado según norma técnica NCh433'
    }
  ]);


  const formatUF = (value: number | null | undefined): string => {
    if (value === null || value === undefined) return '-';
    return value.toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' UF';
  };

  const calcularEstadisticas = () => {
    const dias = data.map(item => item.dias);
    const promedio = dias.reduce((a, b) => a + b, 0) / dias.length;
    const varianza = dias.reduce((a, b) => a + Math.pow(b - promedio, 2), 0) / dias.length;
    const desviacionEstandar = Math.sqrt(varianza);
    
    return {
      promedio: Math.round(promedio * 10) / 10,
      desviacionEstandar: Math.round(desviacionEstandar * 10) / 10
    };
  };

  const estadisticas = calcularEstadisticas();

  const getStatusIcon = (status: string) => {
    const statusConfig = ESTADOS[status];
    if (!statusConfig) return null;
    
    const IconComponent = statusConfig.icon;
    return (
      <div className={`flex items-center ${statusConfig.color}`}>
        <IconComponent className="w-4 h-4 mr-1" />
        {status}
      </div>
    );
  };

  
  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">GESTIÓN ► SOLICITUD DE ADICIONAL</h1>
        <div className="flex gap-4 items-center">
          <select className="p-2 border rounded">
            <option value="">Filtrar por Estado</option>
            {Object.keys(ESTADOS).map(estado => (
              <option key={estado} value={estado}>{estado}</option>
            ))}
          </select>
          <Button className="flex items-center gap-2 bg-blue-900">
            <Plus className="w-4 h-4" />
            Nueva Solicitud
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="p-3 text-left">N° Adicional</th>
                <th className="p-3 text-left">Asunto</th>
                <th className="p-3 text-left">Ver documentos</th>
                <th className="p-3 text-left">Adicional</th>
                <th className="p-3 text-right">Monto Solicitado</th>
                <th className="p-3 text-right">Monto Aprobado</th>
                <th className="p-3 text-center">Días de Respuesta</th>
                <th className="p-3 text-left">Estatus</th>
                <th className="p-3 text-left">Justificación</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="border-b hover:bg-gray-50">
                  <td className="p-3">{item.numero}</td>
                  <td className="p-3">{item.asunto}</td>
                  <td className="p-3">
                    <a href="#" className="text-blue-500 hover:underline">
                      Ver documentos
                    </a>
                  </td>
                  <td className="p-3">Adicional {item.id}</td>
                  <td className="p-3 text-right">{formatUF(item.montoSolicitado)}</td>
                  <td className="p-3 text-right">{formatUF(item.montoAprobado)}</td>
                  <td className="p-3 text-center">{item.dias}</td>
                  <td className="p-3">{getStatusIcon(item.estatus)}</td>
                  <td className="p-3">{item.justificacion}</td>
                </tr>
              ))}
              <tr className="bg-yellow-100">
              <td colSpan={7} className="p-3 font-bold text-right">
  Promedio ± Desv. Est.
</td>

              <td className="p-3 text-center font-bold">
                  {estadisticas.promedio} ± {estadisticas.desviacionEstandar}
                </td>
                <td ></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex flex-row gap-4 mt-8 p-4">
          <div className="flex-1 bg-white p-4 rounded-lg shadow">
            <h2 className="text-lg font-medium mb-4">Días de Respuesta por Adicional</h2>
            <div style={{ width: '100%', height: 300 }}>
              <BarChart width={500} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nombre" />
                <YAxis label={{ value: 'Días', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Bar dataKey="dias" fill="#8884d8" name="Días de Respuesta" />
                <ReferenceLine 
                  y={estadisticas.promedio} 
                  label={{ value: `Promedio: ${estadisticas.promedio}`, position: 'right' }} 
                  stroke="#ff7300" 
                  strokeDasharray="3 3" 
                />
              </BarChart>
            </div>
          </div>

          <div className="flex-1 bg-white p-4 rounded-lg shadow">
            <h2 className="text-lg font-medium mb-4">
              Montos Aprobados
              <span className="block text-sm text-gray-500 mt-1">
                Total Aprobado: {formatUF(data.reduce((sum, item) => sum + (item.montoAprobado || 0), 0))}
              </span>
            </h2>
            <div style={{ width: '100%', height: 300 }}>
              <BarChart 
                width={500} 
                height={300} 
                data={data.map(item => ({
                  adicional: `Adicional ${item.id}`,
                  montoAprobado: item.montoAprobado || 0
                }))}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="adicional" />
                <YAxis label={{ value: 'Monto (UF)', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Bar dataKey="montoAprobado" fill="#82ca9d" name="Monto Aprobado" />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdicionalDashboard;