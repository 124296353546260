import React, { useState, useEffect, useRef } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip, Legend, BarChart, CartesianGrid, XAxis, YAxis, Bar, Cell } from 'recharts';
import { AlertTriangle, Bookmark, Calendar, CheckCircle, ChevronDown, ChevronRight, ChevronUp, Clock, FileQuestion, Loader2, Users, X } from 'lucide-react';
import { CommonComponentProps } from '../../types/common';
import { Card, CardHeader, CardContent } from '../ui/card';
import { motion, AnimatePresence, progress } from 'framer-motion';
import { MdBusiness } from 'react-icons/md';

interface ProcessData {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}
interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}


interface Subproceso {
  id: number;
  nombre: string;
  agrupadores: Agrupador[];
  subproceso: string;
  color?: string;
}

interface Agrupador {
  nombre: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Tarea[];
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
}


interface Tarea {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  organismo: string;
  duracion: number;
  dependencia: string | number | null;
  enabled: boolean;
  isClosed: boolean;
  comments: string;
  orden: number;
  subtasks?: SubTask[];
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
}



interface Task {
  id: number;
  name: string;
  description: string;
  responsible: User[]; 
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
}


enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

interface SubTask {
  id?: number;
  nombre: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progress: number;
  fecha_inicio: string;
  fecha_termino: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolucion_tipo?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  reminder_type?: 'specific-date' | 'relative-time' | 'progress-based' | null;
  reminder_value?: string | null;
}


enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
  
}

interface ChartData {
  nombre: string;
  progress: number;
  totalTasks: number;
  completedTasks: number;
  color: string;
}

interface Process {
  id: number;
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Props extends Partial<CommonComponentProps> {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

// Componente personalizado para las etiquetas del eje
const CustomAxisTick = ({ x, y, payload }: any) => {
  const name = payload.value;
  const lines = name.split(' ');
  
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#4a5568"
        style={{ fontSize: '12px', fontWeight: 'bold' }}
      >
        {lines.map((line: string, i: number) => (
          <tspan x={0} dy={i === 0 ? 0 : 16} key={i}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
};

// Actualizar el CustomTooltip para mostrar más información
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
        <h3 className="font-bold text-gray-800">{data.nombre}</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-600">
            Progreso: <span className="font-medium">{data.progress.toFixed(1)}%</span>
          </p>
          <p className="text-sm text-gray-600">
            Tareas completadas: <span className="font-medium">{data.completedTasks}/{data.totalTasks}</span>
          </p>
        </div>
      </div>
    );
  }
  return null;
};

// Actualizar el CustomLegend para usar los colores personalizados
const CustomLegend = ({ payload }: any) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mt-4">
      {payload.map((entry: any, index: number) => (
        <div key={`legend-${index}`} className="flex items-center">
          <div
            className="w-3 h-3 rounded-full mr-2"
            style={{ backgroundColor: entry.payload.color }}
          />
          <span className="text-sm text-gray-600">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};




interface SubprocessDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  subprocessData: {
    nombre: string;
    agrupadores: Agrupador[];
    color: string;
    progress: number;
    totalTasks: number;
    completedTasks: number;
  };
  selectedProcess: string;
}



const getAgrupadorDates = (agrupador: Agrupador) => {
  const enabledTasks = agrupador.tareas.filter(t => t.enabled);
  if (!enabledTasks.length) return { start: "", end: "" };

  const allDates = enabledTasks.reduce((dates, task) => {
    if (task.fecha_inicio) dates.push(new Date(task.fecha_inicio));
    if (task.fecha_termino) dates.push(new Date(task.fecha_termino));
    return dates;
  }, [] as Date[]);

  if (!allDates.length) return { start: "", end: "" };

  const earliestDate = new Date(Math.min(...allDates.map(d => d.getTime())));
  const latestDate = new Date(Math.max(...allDates.map(d => d.getTime())));

  return {
    start: earliestDate.toISOString().split('T')[0],
    end: latestDate.toISOString().split('T')[0]
  };
};

const calculateAgrupadorProgress = (agrupador: Agrupador) => {
  const enabledTasks = agrupador.tareas.filter(t => t.enabled);
  if (!enabledTasks.length) return 0;

  const completedTasks = enabledTasks.filter(t => t.isClosed).length;
  return (completedTasks / enabledTasks.length) * 100;
};


const SubprocessDetailModal: React.FC<SubprocessDetailModalProps> = ({
  isOpen,
  onClose,
  subprocessData,
  selectedProcess
}) => {
  console.log(JSON.stringify(subprocessData))
  const hasNoData = !subprocessData.agrupadores || subprocessData.agrupadores.length === 0;
  const [expandedAgrupadores, setExpandedAgrupadores] = useState<number[]>(
    Array.from({ length: subprocessData.agrupadores?.length || 0 }, (_, i) => i)
  );
  const [expandedTasks, setExpandedTasks] = useState<number[]>([]);

  const CheckerboardPattern: React.FC<{ color: string }> = ({ color }) => (
    <div
      className="relative w-8 h-8 rounded-full overflow-hidden flex items-center justify-center"
      style={{
        backgroundImage: `repeating-conic-gradient(${color} 0% 25%, #fff 0% 50%)`,
        backgroundSize: "10px 10px",
        border: `1px solid ${color}`,
        borderRadius: "50%",
      }}
    />
  );

  const getSubtaskResponsible = (subtask: SubTask, tarea: Tarea, agrupador: Agrupador) => {
    // Para OBSERVACIÓN, usar el organismo del agrupador
    if (subtask.tipo === 'OBSERVACIÓN') {
      return agrupador.organismo;
    }
    
    // Para RESOLUCIÓN que no sea DESISTIMIENTO, usar el organismo del agrupador
    if (subtask.tipo === 'RESOLUCIÓN' && subtask.resolucion_tipo !== 'DESISTIMIENTO') {
      return agrupador.organismo;
    }
    
    // Para los demás casos, usar los mismos responsables que la tarea TRAMITACIÓN
    return tarea.responsable;
  };
  // Componente mejorado para mostrar responsables
 


  const ResponsibleTooltip = ({
    organismo,
    responsibles,
    color,
  }: {
    organismo: string;
    responsibles: string | { firstname: string; lastname: string }[];
    color: string;
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
  
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowTooltip(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className="relative" ref={tooltipRef}>
        <button
          className="flex items-center space-x-1 hover:text-teal-800 group"
          onClick={(e) => {
            e.stopPropagation();
            setShowTooltip(!showTooltip);
          }}
          style={{ color }}
        >
          <Users className={`w-5 h-5 ${Array.isArray(responsibles) ? 'text-green-500' : 'text-gray-400'}`} />
          <span className="text-sm text-gray-600">{Array.isArray(responsibles) ? responsibles.length : 1}</span>
        </button>
  
        {showTooltip && (
          <div className="absolute z-50 left-1/2 -translate-x-1/2 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 p-3">
            <h4 className="text-sm font-medium mb-2" style={{ color }}>
            {Array.isArray(responsibles) ? 'Responsables:' : 'Organismo:'}
            </h4>
            <ul className="space-y-1">
              {Array.isArray(responsibles) ? (
                responsibles.map((user, idx) => (
                  <li key={idx} className="text-sm text-gray-600">
                    {`${user.firstname} ${user.lastname}`}
                  </li>
                ))
              ) : (
        
                <li className="text-sm text-gray-600">
                        
                  {organismo}</li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };
  


  const EmptyStateMessage: React.FC<{ color: string }> = ({ color }) => (
    <div className="flex flex-col items-center justify-center p-10 text-center">
      <div
        className="w-20 h-20 rounded-full mb-6 flex items-center justify-center"
        style={{ backgroundColor: `${color}10` }}
      >
        <FileQuestion size={40} style={{ color }} />
      </div>
      <h3 className="text-2xl font-bold mb-3" style={{ color }}>
        Sin información disponible
      </h3>
      <p className="text-gray-600 max-w-md mb-6">
        No hay datos disponibles para este subproceso en este momento. Los detalles se mostrarán aquí una vez que se
        agregue información.
      </p>
      <div className="w-32 h-1 rounded-full" style={{ backgroundColor: `${color}30` }} />
    </div>
  );

  const formatDate = (dateString: string) => {
    if (!dateString) return "-";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const toggleAgrupador = (index: number) => {
    setExpandedAgrupadores((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const toggleTask = (taskId: number) => {
    setExpandedTasks((prev) =>
      prev.includes(taskId) ? prev.filter((id) => id !== taskId) : [...prev, taskId]
    );
  };

  

  const getStatusTooltip = (status: SemaphoreStatus): string => {
    switch (status) {
      case SemaphoreStatus.Red:
        return 'Tarea vencida';
      case SemaphoreStatus.Orange:
        return 'Por vencer en 2 días o menos';
      case SemaphoreStatus.Yellow:
        return 'Por vencer en 5 días o menos';
      case SemaphoreStatus.Green:
        return 'En tiempo';
      case SemaphoreStatus.Gray:
        return 'No iniciada o completada';
      default:
        return 'Estado desconocido';
    }
  };
  
const calculateSemaphoreStatus = (start: string, end: string, isClosed: boolean): SemaphoreStatus => {
  if (isClosed) return SemaphoreStatus.Gray;
  if (!start || !end) return SemaphoreStatus.Gray;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(end);
  endDate.setHours(23, 59, 59, 999);

  if (today > endDate) {
    return SemaphoreStatus.Red;
  }

  if (today < startDate) {
    return SemaphoreStatus.Gray;
  }

  const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  if (daysUntilEnd <= 2) {
    return SemaphoreStatus.Orange;
  }

  if (daysUntilEnd <= 5) {
    return SemaphoreStatus.Yellow;
  }

  return SemaphoreStatus.Green;
};

// Y que los colores del semáforo estén bien definidos:

const getStatusColor = (status: SemaphoreStatus): string => {
  const colors = {
    [SemaphoreStatus.Red]: 'bg-red-600',
    [SemaphoreStatus.Orange]: 'bg-orange-500',
    [SemaphoreStatus.Yellow]: 'bg-yellow-400',
    [SemaphoreStatus.Green]: 'bg-green-500',
    [SemaphoreStatus.Gray]: 'bg-gray-400'
  };
  return colors[status];
};
return (
  <AnimatePresence>
    {isOpen && (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black/50">
        <div className="flex items-center justify-center min-h-screen px-4 py-4">
        <motion.div
  className="relative w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden"
  style={{ maxHeight: 'calc(100vh - 4rem)' }} // This limits the modal's overall height
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
   {/* Fixed Header Banner */}
   <div
              className="sticky top-0 left-0 right-0 text-white p-6 rounded-t-lg z-10"
              style={{ backgroundColor: subprocessData.color }}
            >
              <div className="flex items-center justify-between">
                <div className="space-y-2">
                  <div className="flex text-white/80 text-sm">
                    <MdBusiness className="w-5 h-5" /> &nbsp;
                    {selectedProcess} • {subprocessData.nombre}
                  </div>
                  <div className="flex items-center space-x-3">
                    <Bookmark className="w-6 h-6" />
                    <span className="text-2xl font-bold">{subprocessData.nombre}</span>
                  </div>
                  <div className="text-white/80 text-sm flex items-center space-x-4">
                    <span>{subprocessData.agrupadores.length} agrupadores</span>
                    <span>•</span>
                    <span>{subprocessData.totalTasks} tareas totales</span>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-white/20 rounded-full transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>


            {/* Agrupadores List */}
              {/* Scrollable Body Content */}
              <div className="p-6 space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 12rem)' }}>
              {subprocessData.agrupadores.map((agrupador, agrupadorIndex) => {
                const dates = getAgrupadorDates(agrupador);
                const progress = calculateAgrupadorProgress(agrupador);
                
                return (
                  <Card key={agrupadorIndex} className="border shadow-sm">
                    <CardHeader
                      className="cursor-pointer"
                      onClick={() => toggleAgrupador(agrupadorIndex)}
                      style={{ backgroundColor: `${subprocessData.color}05` }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          {expandedAgrupadores.includes(agrupadorIndex) ? (
                            <ChevronDown className="w-5 h-5" style={{ color: subprocessData.color }} />
                          ) : (
                            <ChevronRight className="w-5 h-5" style={{ color: subprocessData.color }} />
                          )}
                          <div>
                            <h2 className="text-lg font-semibold">{agrupador.nombre}</h2>
                            <div className="text-sm text-gray-500 flex">
                              <Calendar className="w-5 h-5" />
                              {formatDate(dates.start)}&nbsp; - &nbsp;
                              <Calendar className="w-5 h-5" />
                              {formatDate(dates.end)}
                            </div>
                          </div>
                        </div>
                        <div
                          className="px-3 py-1 rounded-full text-sm font-medium"
                          style={{
                            backgroundColor: `${subprocessData.color}20`,
                            color: subprocessData.color,
                          }}
                        >
                          {Math.round(progress)}% completado
                        </div>
                      </div>
                    </CardHeader>


                      {expandedAgrupadores.includes(agrupadorIndex) && (
                        <CardContent className="pt-4">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Tarea
                                </th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Responsables
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Fechas
                                </th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Estado
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {agrupador.tareas.map((tarea, tareaIndex) => {
                                const tareaStatus = calculateSemaphoreStatus(
                                  tarea.fecha_inicio,
                                  tarea.fecha_termino,
                                  tarea.isClosed
                                );

                                const semaphoreStatus = calculateSemaphoreStatus(tarea.fecha_inicio, tarea.fecha_termino, tarea.isClosed);
const statusColorClass = getStatusColor(semaphoreStatus);

                                
                                return (
                                  <React.Fragment key={tareaIndex}>
                                <tr className="hover:bg-gray-50">
                                  <td className="px-6 py-4">
                                    <div className="flex items-center">
                                      {tarea.nombre === 'TRAMITACIÓN' && tarea.subtasks && tarea.subtasks.length > 0 && (
                                        <button
                                          onClick={() => toggleTask(tarea.id)}
                                          className="mr-2"
                                        >
                                          {expandedTasks.includes(tarea.id) ? (
                                            <ChevronDown className="w-4 h-4" />
                                          ) : (
                                            <ChevronRight className="w-4 h-4" />
                                          )}
                                        </button>
                                      )}
                                      <div className="text-sm font-medium text-gray-900">
                                        {tarea.nombre}
                                        {tarea.subtasks && tarea.subtasks.length > 0 && (
                                          <span className="ml-2 text-xs text-gray-500">
                                            ({tarea.subtasks.length} subtareas)
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    <ResponsibleTooltip
                                      organismo={agrupador.organismo}
                                      responsibles={tarea.responsable}
                                      color={subprocessData.color}
                                    />
                                  </td>
                                  <td className="px-6 py-4">
                                    <div className="text-sm text-gray-500">
                                      <div>Inicio: {formatDate(tarea.fecha_inicio)}</div>
                                      <div>Término: {formatDate(tarea.fecha_termino)}</div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    <div className="flex justify-center">
                                      <div className="group relative">
                                        {tarea.isClosed && tarea.progreso === 100? (
                                          <CheckerboardPattern color={subprocessData.color} />
                                        ) : (
                                          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${getStatusColor(tareaStatus)}`}>
                                                {tareaStatus === SemaphoreStatus.Red ? (
                                                  <AlertTriangle className="w-4 h-4 text-white" />
                                                ) : tareaStatus === SemaphoreStatus.Orange ? (
                                                  <Clock className="w-4 h-4 text-white" />
                                                ) : tareaStatus === SemaphoreStatus.Yellow ? (
                                                  <Clock className="w-4 h-4 text-white" />
                                                ) : (
                                                  <CheckCircle className="w-4 h-4 text-white" />
                                                )}
                                              </div>
                                          
                                        )}
                                                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-[100]">
                                              {tarea.isClosed ? 'Completada' : getStatusTooltip(tareaStatus)}
                                            </div>

                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                {expandedTasks.includes(tarea.id) && tarea.subtasks && tarea.subtasks.length > 0 && (
                                  tarea.subtasks.map((subtask, subtaskIndex) => {
                                    const subtaskStatus = calculateSemaphoreStatus(
                                      subtask.fecha_inicio,
                                      subtask.fecha_termino,
                                      subtask.isClosed
                                    );
                                    
                                    return (
                                      <motion.tr
                                        key={`subtask-${tarea.id}-${subtaskIndex}`}
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="bg-gray-50"
                                      >
                                        <td className="px-6 py-3">
                                          <div className="flex items-center pl-6">
                                            <div 
                                              className="w-1 h-1 rounded-full mr-2"
                                              style={{ backgroundColor: subprocessData.color }}
                                            />
                                       <span className="text-sm text-gray-600">
                                        {subtask.nombre}
                                        {subtask.tipo === 'RESOLUCIÓN' && subtask.resolucion_tipo && (
                                          <span className={`ml-2 px-2 py-0.5 rounded text-xs font-medium 
                                            ${subtask.resolucion_tipo === 'APROBADO' ? 'bg-green-100 text-green-800' :
                                              subtask.resolucion_tipo === 'RECHAZADO' ? 'bg-red-100 text-red-800' :
                                              subtask.resolucion_tipo === 'DESISTIMIENTO' ? 'bg-gray-100 text-gray-800' :
                                              subtask.resolucion_tipo === 'SILENCIO ADMINISTRATIVO POSITIVO' ? 'bg-blue-100 text-blue-800' :
                                              subtask.resolucion_tipo === 'SILENCIO ADMINISTRATIVO NEGATIVO' ? 'bg-yellow-100 text-yellow-800' : 
                                              'bg-gray-100 text-gray-800'}`}
                                          >
                                            {subtask.resolucion_tipo}
                                          </span>
                                        )}
                                      </span>


                                          </div>
                                        </td>
                                        <td className="px-6 py-3">
                                          <div className="flex justify-center">
                                            <ResponsibleTooltip
                                              organismo={agrupador.organismo}
                                              responsibles={getSubtaskResponsible(subtask, tarea, agrupador)}
                                              color={subprocessData.color}
                                            />
                                          </div>
                                        </td>
                                        <td className="px-6 py-3">
                                          <div className="text-sm text-gray-600">
                                            <div>Inicio: {formatDate(subtask.fecha_inicio)}</div>
                                            <div>Término: {formatDate(subtask.fecha_termino)}</div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4">
                                        <div className="flex justify-center">
                                          <div className="group relative">
                                            {tarea.isClosed ? (
                                              <CheckerboardPattern color={subprocessData.color} />
                                            ) : (
                                              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${getStatusColor(tareaStatus)}`}>
                                                {tareaStatus === SemaphoreStatus.Red ? (
                                                  <AlertTriangle className="w-4 h-4 text-white" />
                                                ) : tareaStatus === SemaphoreStatus.Orange ? (
                                                  <Clock className="w-4 h-4 text-white" />
                                                ) : tareaStatus === SemaphoreStatus.Yellow ? (
                                                  <Clock className="w-4 h-4 text-white" />
                                                ) : (
                                                  <CheckCircle className="w-4 h-4 text-white" />
                                                )}
                                              </div>
                                            )}
                                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-[100]">
                                              {tarea.isClosed ? 'Completada' : getStatusTooltip(tareaStatus)}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </motion.tr>
                                    
                                    );
                                  })
                                )}
                            </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </CardContent>
                      )}
                    </Card>
                  );
                })}
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};




const SubprocessCard: React.FC<{ data: ChartData; index: number; onClick: () => void }> = ({ data, index, onClick }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const toggleCollapse = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <motion.div
      layout
      className="relative overflow-hidden rounded-lg shadow-sm hover:shadow-md h-fit"
      style={{ 
        backgroundColor: `${data.color}10`,
        alignSelf: 'flex-start' // This ensures the card takes only the height it needs
      }}
      transition={{ layout: { duration: 0.3, type: "spring" } }}
    >
      {/* Header - Always visible but compact when collapsed */}
           <div 
        className="absolute top-0 left-0 h-1 transition-all duration-300"
        style={{ 
          width: `${100}%`,
          backgroundColor: data.color
        }}
      />
      
     <motion.div
        layout="position"
        className={`p-4 ${isCollapsed ? 'pb-2' : ''}`}
        transition={{ layout: { duration: 0.3 } }}
      >
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-lg text-gray-500">
            {data.nombre}
          </h3>
          <div className="flex items-center space-x-2">
            <div 
              className="flex items-center justify-center w-12 h-12 rounded-full text-lg font-bold"
              style={{ 
                backgroundColor: `${data.color}20`,
                color: data.color
              }}
            >
              {Math.round(data.progress)}%
            </div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={toggleCollapse}
              className="p-1 hover:bg-gray-200 rounded-full transition-colors"
            >
              <motion.div
                animate={{ rotate: isCollapsed ? 0 : 180 }}
                transition={{ duration: 0.3 }}
              >
                <ChevronDown className="w-5 h-5 text-gray-500" />
              </motion.div>
            </motion.button>
          </div>
        </div>
      </motion.div>

      {/* Collapsible Content */}
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto", 
              opacity: 1,
              transition: {
                height: { duration: 0.3 },
                opacity: { duration: 0.2, delay: 0.1 }
              }
            }}
            exit={{ 
              height: 0, 
              opacity: 0,
              transition: {
                height: { duration: 0.3 },
                opacity: { duration: 0.2 }
              }
            }}
            className="overflow-hidden"
            style={{ alignSelf: 'stretch' }}
          >
            <motion.div 
              className="px-4 pb-4 space-y-3"
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -20 }}
              transition={{ duration: 0.2 }}
            >
              {/* Progress Bar Section */}
              <motion.div 
                className="relative pt-1"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 }}
              >
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span 
                      className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full"
                      style={{ 
                        backgroundColor: `${data.color}20`,
                        color: data.color
                      }}
                    >
                      Progreso
                    </span>
                  </div>
                  <div className="text-right">
                    <span 
                      className="text-xs font-semibold inline-block"
                      style={{ color: data.color }}
                    >
                      {data.progress.toFixed(1)}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${data.progress}%` }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ backgroundColor: data.color }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                  />
                </div>
              </motion.div>

              {/* Stats Section */}
              <motion.div 
                className="flex items-center justify-between px-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className="text-center">
                  <span className="block text-sm font-medium text-gray-500">Completadas</span>
                  <span className="block text-lg font-bold" style={{ color: data.color }}>
                    {data.completedTasks}
                  </span>
                </div>
                <div 
                  className="h-8 w-px"
                  style={{ backgroundColor: `${data.color}30` }}
                />
                <div className="text-center">
                  <span className="block text-sm font-medium text-gray-500">Total</span>
                  <span className="block text-lg font-bold" style={{ color: data.color }}>
                    {data.totalTasks}
                  </span>
                </div>
              </motion.div>

              {/* View Details Button */}
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                onClick={onClick}
                className="w-full mt-2 py-2 px-4 rounded-lg transition-colors text-sm font-medium"
                style={{
                  backgroundColor: `${data.color}20`,
                  color: data.color
                }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Ver detalles
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

  
const HuellaDigital: React.FC<Props> = ({ selectedProcess, processes }) => {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedSubprocess, setSelectedSubprocess] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subprocessDetails, setSubprocessDetails] = useState<any>(null);
  const [collapsedCards, setCollapsedCards] = useState<Set<number>>(new Set());

  // Function to toggle individual card collapse state
  const toggleCardCollapse = (index: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent card click event from firing
    setCollapsedCards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  

  const handleSubprocessClick = async (data: ChartData) => {
    try {
      const selectedProcessData = processes.find(p => p.nombreProceso === selectedProcess);
      if (!selectedProcessData) return;
  
      const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcessData.codigo}`);
      if (!response.ok) throw new Error('Error al obtener los datos');
  
      const fullData: ProcessData = await response.json();
      
      const subprocessData = fullData.subprocesos.find(sp => sp.nombre === data.nombre);
      if (!subprocessData) return;
  
      // Process agrupadores data with subtasks
   
      const processedAgrupadores = subprocessData.agrupadores
      .filter(agrupador => agrupador.enabled)
      .map(agrupador => ({
        ...agrupador,
        tareas: agrupador.tareas.map(tarea => ({
          ...tarea,
          subtasks: tarea.nombre === 'TRAMITACIÓN' ? (tarea.subtasks || []).map(subtask => ({
            ...subtask,
            // Asegurarse de mapear todos los campos necesarios
            start: subtask.fecha_inicio,
            end: subtask.fecha_termino,
            name: subtask.nombre,
            tipo: subtask.tipo || 'OTROS',
            resolucion_tipo: subtask.resolucion_tipo,
            isClosed: !!subtask.isClosed,
            responsible: subtask.responsible
          })) : []
        }))
      }));

      setSubprocessDetails({
        nombre: subprocessData.nombre,
        agrupadores: processedAgrupadores,
        color: data.color,
        progress: data.progress,
        totalTasks: data.totalTasks,
        completedTasks: data.completedTasks
      });
      
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error loading subprocess details:', error);
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedProcess) {
        setError('No se ha seleccionado un proyecto');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const selectedProcessData = processes.find(p => p.nombreProceso === selectedProcess);

        if (!selectedProcessData) {
          throw new Error('Proceso no encontrado');
        }

        const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcessData.codigo}`);
        if (!response.ok) throw new Error('Error al obtener los datos');

        const data: ProcessData = await response.json();
        
        if (!data.subprocesos || !Array.isArray(data.subprocesos)) {
          throw new Error('Estructura de datos inválida');
        }

        const processedData = data.subprocesos
          .map((subproceso: Subproceso) => {
            const enabledAgrupadores = subproceso.agrupadores.filter(ag => ag.enabled);
            const totalTasks = enabledAgrupadores.reduce(
              (sum, agrupador) => sum + agrupador.tareas.filter(t => t.enabled).length,
              0
            );

            // If no tasks, return null to filter out later
            if (totalTasks === 0) return null;

            const completedTasks = enabledAgrupadores.reduce(
              (sum, agrupador) => sum + agrupador.tareas.filter(t => t.enabled && t.isClosed && t.progreso === 100).length,
              0
            );

            const agrupadoresProgress = enabledAgrupadores
              .map(agrupador => {
                const enabledTasks = agrupador.tareas.filter(task => task.enabled);
                if (enabledTasks.length === 0) return 0;
                
                const completedTasks = enabledTasks.filter(task => task.isClosed && task.progreso === 100).length;
                return (completedTasks / enabledTasks.length) * 100;
              });

            const totalProgress = agrupadoresProgress.reduce((sum, progress) => sum + progress, 0);
            const avgProgress = enabledAgrupadores.length > 0 ? totalProgress / enabledAgrupadores.length : 0;

            return {
              nombre: subproceso.nombre,
              progress: avgProgress,
              totalTasks,
              completedTasks,
              color: subproceso.color || "#2196F3"
            };
          })
          .filter((item): item is ChartData => item !== null); // Filter out null values

        setChartData(processedData);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error desconocido');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedProcess, processes]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[500px]">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4 bg-red-50 rounded-lg">
        <p className="font-bold">Error</p>
        <p>{error}</p>
      </div>
    );
  }

  if (chartData.length === 0) {
    return (
      <div className="text-center p-8 bg-gray-50 rounded-lg">
        <p className="text-gray-600">No hay datos disponibles para mostrar</p>
      </div>
    );
  }

  const CustomBarTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
          <h3 className="font-bold text-gray-800">{data.nombre}</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-600">
              Progreso: <span className="font-medium">{data.progress.toFixed(1)}%</span>
            </p>
            <p className="text-sm text-gray-600">
              Tareas completadas: <span className="font-medium">{data.completedTasks}/{data.totalTasks}</span>
            </p>
          </div>
        </div>
      );
    }
    return null;
  };


  const renderVisualization = () => {
    return (
      <Card className="p-6 bg-gray-50/50">
        {chartData.length <= 2 ? (
          <CustomBarChart data={chartData} />
        ) : (
          <CustomRadarChart data={chartData} />
        )}
      </Card>
    );
  };


  


  const CustomBarChart: React.FC<{ data: ChartData[] }> = ({ data }) => {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <defs>
            {data.map((entry, index) => (
              <linearGradient
                key={`gradient-${index}`}
                id={`gradient-${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={entry.color}
                  stopOpacity={0.8}
                />
                <stop
                  offset="100%"
                  stopColor={entry.color}
                  stopOpacity={0.3}
                />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid 
            strokeDasharray="3 3" 
            vertical={false}
            stroke="#E5E7EB"
          />
          <XAxis
            dataKey="nombre"
            tick={{ fill: '#4B5563', fontSize: 12, fontWeight: 500 }}
            axisLine={false}
            tickLine={false}
            interval={0}
            height={60}
            textAnchor="end"
            angle={-30}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#4B5563', fontSize: 12 }}
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                return (
                  <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="font-bold text-gray-800 mb-2" style={{ color: data.color }}>
                      {data.nombre}
                    </h3>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600">
                        Progreso: <span className="font-medium">{data.progress.toFixed(1)}%</span>
                      </p>
                      <p className="text-sm text-gray-600">
                        Completadas: <span className="font-medium">{data.completedTasks} de {data.totalTasks}</span>
                      </p>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <Bar
            dataKey="progress"
            radius={[8, 8, 0, 0]}
            maxBarSize={80}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(#gradient-${index})`}
                stroke={entry.color}
                strokeWidth={1}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };
  
  const CustomRadarChart: React.FC<{ data: ChartData[] }> = ({ data }) => {
    const [hoveredAxis, setHoveredAxis] = useState<string | null>(null);
    const [selectedAxis, setSelectedAxis] = useState<string | null>(data[0]?.nombre || null);
 
  
    const CustomAxisTick = ({ x, y, payload }: any) => {
      const isHovered = hoveredAxis === payload.value;
      const isSelected = selectedAxis === payload.value;
      const subprocessData = data.find(d => d.nombre === payload.value);
      const adjustedY = y -30; // Ajusta hacia arriba, aumentando el valor negativo

      return ( 
        <g transform={`translate(${x},${adjustedY})`}>
          <text 
            x={0}
            y={0}
            dy={25}
            textAnchor="middle"
            fill={isSelected ? subprocessData?.color : isHovered ? subprocessData?.color || '#4B5563' : '#4B5563'}
            style={{
              fontSize: '15px',
              fontWeight: isSelected ? '600' : isHovered ? '500' : '500',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={() => setHoveredAxis(payload.value)}
            onMouseLeave={() => setHoveredAxis(null)}
            onClick={() => {
              setSelectedAxis(selectedAxis === payload.value ? null : payload.value);
            }}
          >
            {payload.value}
          </text>
        </g>
      );
    };
  
    return (
      <ResponsiveContainer width="100%" height={500}>
        <RadarChart cx="50%" cy="60%" outerRadius="100%" data={data}>
          {/* Renderizar primero las áreas de radar */}
          {data.map((entry, index) => {
            const isSelected = selectedAxis === entry.nombre;
            const isHovered = hoveredAxis === entry.nombre;
            const fillOpacity = isSelected ? 0.9 : isHovered ? 0.7 : 0.5;
  
            return (
              <Radar
                key={`radar-${index}`}
                name={entry.nombre}
                dataKey="progress"
                stroke={isSelected || isHovered ? entry.color : `${entry.color}80`}
                strokeWidth={isSelected || isHovered ? 2 : 1}
                fill={isSelected ? entry.color : `${entry.color}50`} // Cambia el `fill` dinámicamente
                fillOpacity={fillOpacity} // Cambia la opacidad según el estado
                dot={{
                  fill: entry.color,
                  stroke: 'white',
                  strokeWidth: 2,
                  r: isSelected ? 5 : 4,
                  strokeOpacity: 1,
                }}
                onMouseEnter={() => setHoveredAxis(entry.nombre)}
                onMouseLeave={() => setHoveredAxis(null)}
                onClick={() => setSelectedAxis(selectedAxis === entry.nombre ? null : entry.nombre)}
                style={{
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  padding: 60
                }}
              />
            );
          })}
  
          {/* Renderizar la cuadrícula por encima */}
          <PolarGrid strokeDasharray="3 3" gridType="polygon" stroke="#B7B8BC" />
          <PolarAngleAxis
            dataKey="nombre"
            tick={<CustomAxisTick />}
            tickLine={false}
            stroke="none"
          />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tick={{
              fill: '#4B5563',
              fontSize: 12,
            }}
            tickCount={6}
            axisLine={false}
            tickLine={false}
          />
  
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                return (
                  <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="font-bold text-gray-800 mb-2" style={{ color: data.color }}>
                      {data.nombre}
                    </h3>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600">
                        Progreso: <span className="font-medium">{data.progress.toFixed(1)}%</span>
                      </p>
                      <p className="text-sm text-gray-600">
                        Completadas: <span className="font-medium">{data.completedTasks} de {data.totalTasks}</span>
                      </p>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  };
  
 

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg">
      {/* Header Section */}
      <div className="mb-8">
        {selectedProcess && processes.find(p => p.nombreProceso === selectedProcess) && (
          <div className="border-b pb-6">
            <div className="flex items-baseline space-x-3 mb-3">
              <h2 className="text-2xl font-bold text-teal-600">Huella Digital</h2>
              <span className="text-gray-300">|</span>
              <h3 className="text-xl text-gray-700 font-medium">
                {processes.find(p => p.nombreProceso === selectedProcess)?.nombreProceso}
              </h3>
            </div>
            
            <div className="flex items-center space-x-4 text-sm">
              <span className="bg-gray-100 text-gray-600 px-3 py-1 rounded-md font-medium">
                Código: {processes.find(p => p.nombreProceso === selectedProcess)?.codigo}
              </span>
              <span className="bg-teal-50 text-teal-700 px-3 py-1 rounded-md font-medium">
                {processes.find(p => p.nombreProceso === selectedProcess)?.comuna}
              </span>
              <div className="flex items-center text-gray-500">
                <span>{chartData.length} subprocesos</span>
                <span className="mx-2">•</span>
                <span>{chartData.reduce((acc, curr) => acc + curr.totalTasks, 0)} tareas totales</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Chart Section */}
      <div className="border rounded-xl p-4 bg-gray-50">
        {renderVisualization()}
      </div>

      {/* Cards Grid */}
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        {chartData.map((data, index) => (
          <SubprocessCard key={index} data={data} index={index}             onClick={() => handleSubprocessClick(data)}
          />
        ))}
      </div>

      {/* Modal Component */}
      {subprocessDetails && (
        <SubprocessDetailModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          subprocessData={subprocessDetails}
          selectedProcess={selectedProcess}
        />
      )}
    </div>
  );
};

export default HuellaDigital;