import React, { useState, useEffect, useRef, useMemo, useCallback, RefObject } from 'react';
import { motion, AnimatePresence, progress, Reorder } from 'framer-motion';
import { AlertCircle, AlertTriangle, Check, CheckCircle2, ChevronDown, ChevronRight, ChevronUp, CircleDot, Clock, FileQuestion, X, Building, Info, Calendar, Loader2 } from 'lucide-react';
import { createPortal } from 'react-dom';
import { filter } from 'jszip';
import dateUtils from './dateUtils';
import CollapsibleContainer from './CollapsibleContainer';
import { debounce } from 'lodash'; // Asegúrate de importar lodash si no lo has hecho
import { group } from 'console';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../ui/button';
import { MdBusiness } from 'react-icons/md';



interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: SubTask;
  onChangeSubtask: (updatedSubtask: SubTask) => void;
  onAddNewSubtask: (option: NewSubtaskOption) => void;
  backgroundColor: string;
  mainTask: Task;
  groupProgress: number;
}


interface NewSubtaskOption {
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: User[] | string; 
  organismo: string;
}

const getNextSubtaskOptions = (
  currentType: string,
  parentTask: Task,
  currentSubtask: SubTask
): NewSubtaskOption[] => {
  // Helper function to generate correct name
  const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
    if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
    
    const existingSubtasks = parentTask.subtasks || [];
    const currentNumber = extractNumber(currentSubtask.name);
    
    if (type === 'OBSERVACIÓN') {
      return `OBSERVACIÓN ${currentNumber + 1}`;
    } else {
      return `RESPUESTA ${currentNumber}`;
    }
  };

  // Define available options based on current type
  switch (currentType) {
    case 'INGRESO':
      return [
        {
          type: 'OBSERVACIÓN',
          name: `OBSERVACIÓN 1`,
          resolutionType: '',
          responsable: [], 
          organismo: currentSubtask.organismo
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          // Para RESOLUCIÓN, el responsable se determinará después basado en el tipo de resolución
          responsable: [], 
          organismo: currentSubtask.organismo
        }
      ];

    case 'OBSERVACIÓN':
      const observationNumber = extractNumber(currentSubtask.name);
      return [
        {
          type: 'RESPUESTA',
          name: `RESPUESTA ${observationNumber}`,
          resolutionType: '',
          responsable: parentTask.responsible,
          organismo: currentSubtask.organismo
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [], 
          organismo: currentSubtask.organismo
        }
      ];

    case 'RESPUESTA':
      const responseNumber = extractNumber(currentSubtask.name);
      return [
        {
          type: 'OBSERVACIÓN',
          name: `OBSERVACIÓN ${responseNumber + 1}`,
          responsable: [], 
          organismo: currentSubtask.organismo,
          resolutionType: '' 
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [], 
          organismo: currentSubtask.organismo
        }
      ];

    default:
      return [];
  }
};




interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
  processName: string | undefined;          // Nombre del proceso
  subprocessName: string | undefined;       // Nombre del subproceso
  agrupadorName: string | undefined;  
}


interface CommentData {
  id: number;          // ID de la tarea o subtarea
  comments: string;    // Comentarios en formato JSON string
  type: 'task' | 'subtask'; // Indica si es tarea principal o subtarea
  tarea_id?: number;   // ID de la tarea padre (solo para subtareas)
}



interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}

type ReminderType = 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed';

interface ReminderInfo {
  type: ReminderType | null;
  value: string | null;
}


interface ReminderData {
  task_id: number;
  reminder_type: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value: string | null;
  reminder_code: string | null;
  reminder_calculated_date: string | null;
  reminder_active: boolean;
  reminder_triggered: boolean;
}

interface TaskUpdateData {
  id: number;
  isClosed: boolean;
  progreso: number;
  fecha_inicio: string;  // Campo agregado
  fecha_termino: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}

interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  dependencias: string;
  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}




const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  const today = dateUtils.getTodayLocal();

  // Convertir fechas de string a Date
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la fecha de inicio no ha llegado aún
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea está vencida
  if (today > endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular porcentaje transcurrido
  const totalTime = endDate.getTime() - startDate.getTime();
  const elapsedTime = today.getTime() - startDate.getTime();
  const progressPercentage = (elapsedTime / totalTime) * 100;

  // Determinar el estado del semáforo según los porcentajes
  if (progressPercentage >= 100) {
    return SemaphoreStatus.Red; // Vencida
  } else if (progressPercentage >= 80) {
    return SemaphoreStatus.Orange; // Muy cerca de vencer
  } else if (progressPercentage >= 50) {
    return SemaphoreStatus.Yellow; // Próxima a vencer
  }
  return SemaphoreStatus.Green; // En tiempo
};




const extractNumber = (name: string): number => {
  const match = name.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};




  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  




  const SubtaskModal: React.FC<SubtaskModalProps> = ({ 
    isOpen, 
    onClose, 
    subtask, 
    onChangeSubtask, 
    onAddNewSubtask,
    backgroundColor,
    mainTask,
    groupProgress
  }) => {
    const [showComments, setShowComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
    const [selectedResolutionType, setSelectedResolutionType] = useState('');
    const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showResolutionTypeDialog, setShowResolutionTypeDialog] = useState(false);
  
  
    const renderResponsible = () => {
      if (Array.isArray(subtask.responsible)) {
        return subtask.responsible.map(user => `${user.firstname} ${user.lastname}`).join(', ');
      } else if (typeof subtask.responsible === 'string') {
        return subtask.responsible;
      }
      return '';
    };
  
  
    // Función para actualizar la subtarea en la base de datos
    const updateSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: subtaskData,
            fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
            resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field
  
          })
        });
        console.log(JSON.stringify(subtaskData))
        if (!response.ok) {
          throw new Error('Error al actualizar la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en updateSubtaskInDB:', error);
        throw error;
      }
    };
  
    // Función para crear una nueva subtarea en la base de datos
    const createSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'create',
            subtask: subtaskData
            
          })
        });
        
  
        if (!response.ok) {
          throw new Error('Error al crear la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en createSubtaskInDB:', error);
        throw error;
      }
    };
  
    let subtaskCounter = 0; // Variable global o de estado para mantener el contador de subtareas
  
  
  // Función auxiliar para extraer el número de una observación o respuesta
  const extractNumber = (name: string): number => {
    const match = name.match(/\d+$/);
    return match ? parseInt(match[0]) : 0;
  };
  
  
  const getResolutionResponsible = (resolutionType: string | undefined, organismo: string, parentResponsible: string): string => {
    if (resolutionType === 'DESISTIMIENTO') {
      return parentResponsible;
    }
    return organismo;
  };
  
  const getNextSubtaskOptions = (
    currentType: string,
    parentTask: Task,
    currentSubtask: SubTask
  ): NewSubtaskOption[] => {
    // Helper function to generate correct name
    const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
      if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
      
      const existingSubtasks = parentTask.subtasks || [];
      const currentNumber = extractNumber(currentSubtask.name);
      
      if (type === 'OBSERVACIÓN') {
        return `OBSERVACIÓN ${currentNumber + 1}`;
      } else {
        return `RESPUESTA ${currentNumber}`;
      }
    };
  
    // Define available options based on current type
    switch (currentType) {
      case 'INGRESO':
        return [
          {
            type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN 1`,
            resolutionType: '',
            responsable: [], 
            organismo: currentSubtask.organismo
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            // Para RESOLUCIÓN, el responsable se determinará después basado en el tipo de resolución
            responsable: [], 
            organismo: currentSubtask.organismo
          }
        ];
  
      case 'OBSERVACIÓN':
        const observationNumber = extractNumber(currentSubtask.name);
        return [
          {
            type: 'RESPUESTA',
            name: `RESPUESTA ${observationNumber}`,
            resolutionType: '',
            responsable: parentTask.responsible,
            organismo: currentSubtask.organismo
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: [], 
            organismo: currentSubtask.organismo
          }
        ];
  
      case 'RESPUESTA':
        const responseNumber = extractNumber(currentSubtask.name);
        return [
          {
            type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN ${responseNumber + 1}`,
            responsable: [], 
            organismo: currentSubtask.organismo,
            resolutionType: '' 
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: [], 
            organismo: currentSubtask.organismo
          }
        ];
  
      default:
        return [];
    }
  };
  
    
  const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
  
  
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      const updatedSubtask = { ...subtask, [name]: value };
       
      try {
        const subtaskData = {
          id: updatedSubtask.id,
          tarea_id: mainTask.id,
          nombre: name === 'name' ? value : updatedSubtask.name,
          responsable: name === 'responsible' ? value : updatedSubtask.responsible,
          tipo: name === 'type' ? value : updatedSubtask.type,
          isClosed: name === 'isClosed' ? value : updatedSubtask.isClosed,
          dependencias: name === 'dependsOn' ? value : JSON.stringify(updatedSubtask.dependsOn)
          // Incluir otros campos necesarios...
        };
  
        await updateSubtaskInDB(subtaskData);
        onChangeSubtask(updatedSubtask);
      } catch (error) {
        console.error('Error al actualizar campo:', error);
        alert('Error al actualizar el campo. Por favor, intente nuevamente.');
      }
    };
  
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (subtask.isClosed) return;
    
      const { name, value } = e.target;
      const today = dateUtils.getTodayLocal();
      const selectedDate = dateUtils.parseLocalDate(value);
    
      if (selectedDate < today) {
        alert('La fecha no puede ser anterior a hoy.');
        return;
      }
    
      onChangeSubtask({ ...subtask, [name]: value });
    };
  
    
    
    const handleAddComment = async () => {
      if (!newComment.trim()) return;
    
      try {
        const timestamp = new Date().toLocaleString('es-CL', { 
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        
        const commentWithTimestamp = `[${timestamp}] User: ${newComment}`;
        let updatedComments = [];
    
        // Intentar obtener los comentarios actuales si existen
        if (subtask.comments) {
          try {
            updatedComments = JSON.parse(subtask.comments);
          } catch (error) {
            console.error('Error parsing existing comments:', error);
            updatedComments = [];
          }
        }
    
        // Agregar el nuevo comentario
        updatedComments.push(commentWithTimestamp);
        const newCommentsString = JSON.stringify(updatedComments);
    
        // Estructura para la sub-tarea que se enviará a la base de datos
        const subtaskData = {
          id: subtask.id,
          tarea_id: mainTask.id,
          comments: newCommentsString
        };
    
        // Actualizar comentarios en la base de datos
        await updateSubtaskInDB(subtaskData);
    
        // Actualizar la sub-tarea en el estado local
        onChangeSubtask({ 
          ...subtask, 
          comments: newCommentsString
        });
    
        // Limpiar el input de comentario
        setNewComment('');
      } catch (error) {
        console.error('Error al agregar comentario:', error);
        alert('Error al agregar el comentario. Por favor, intente nuevamente.');
      }
    };
  
    
    const handleCloseSubtask = async () => {
      try {
        setIsLoading(true);
        
        if (subtask.type === 'RESOLUCIÓN') {
          // Si es una resolución, mostrar el diálogo de selección de tipo
          setSelectedResolutionType('');
          setShowResolutionTypeDialog(true); // Necesitamos añadir este estado
        } else {
          
        // Obtener la fecha actual en zona horaria local
        const today = dateUtils.getTodayString();
    
        // Crear el objeto de datos actualizado
        const updatedSubtaskData = {
          ...subtask,
          tarea_id: mainTask.id,
          id: subtask.id,
          type: subtask.type,
          isClosed: true,
          progreso: 100,
          dependencias: JSON.stringify(subtask.dependsOn),
          end: today, // Usar la fecha actual como fecha de término
          fecha_termino: today // Asegurar que también se envíe en el formato esperado por el backend
        };
    
        // Actualizar en la base de datos
        await updateSubtaskInDB(updatedSubtaskData);
        
        // Actualizar el estado local
        onChangeSubtask(updatedSubtaskData);
    
          setShowNewSubtaskDialog(true);
      } 
      } catch (error) {
        console.error('Error al cerrar subtarea:', error);
        alert('Error al cerrar la subtarea. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
    
  
    const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
    
     
  
        try {
          setIsLoading(true);
          
          // Obtener la fecha de inicio (hoy) y la fecha de término (una semana después) usando dateUtils
          const formattedStartDate = dateUtils.getTodayString();            // Fecha de inicio: hoy en zona horaria local
          const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7); // Fecha de término: una semana después en zona horaria local
    
          const newSubtaskData = {
            tarea_id: mainTask.id,
            nombre: option.name,
            tipo: option.type,
            responsable: option.responsable,
            organismo: subtask.organismo,
            progreso: 0,
            fecha_inicio: formattedStartDate,  // Fecha de inicio: hoy
            fecha_termino: formattedEndDate,   // Fecha de término: una semana después
            duracion: 1,
            dependencias: JSON.stringify([{
              groupId: mainTask.id,
              taskId: mainTask.id,
              subtaskId: subtask.id
            }]),
            enabled: true,
            orden: subtask.orden + 1
          };
        //  alert(JSON.stringify(newSubtaskData))
          const result = await createSubtaskInDB(newSubtaskData);
          if (result.success) {
            // Crear la nueva subtarea con las fechas ajustadas
            const newSubtask: SubTask = {
              id: result.id,
              name: option.name,
              type: option.type,
              responsible: option.responsable,
              progreso: 0,
              start: formattedStartDate,   // Fecha de inicio en zona horaria local
              end: formattedEndDate,       // Fecha de término en zona horaria local
              duration: 1,
              organismo: subtask.organismo,
              enabled: true,
              orden: subtask.orden + 1,
              comments: '',
              followUpDate: '',
              followUp: false,
              semaphoreStatus: SemaphoreStatus.Green,
              isClosed: false,
              delayInDays: 0,
              dependsOn: subtask.dependsOn
            };
    
            // Llamar a onAddNewSubtask con el tipo de opción correcto
            onAddNewSubtask(option);
            
          }
        } catch (error) {
          console.error('Error al crear nueva subtarea:', error);
          alert('Error al crear la subtarea. Por favor, intente nuevamente.');
        } finally {
          setIsLoading(false);
          setShowNewSubtaskDialog(false);
          onClose();
        }
      
    };
    
  
  
    
    const handleOpenSubtask = async () => {
      try {
        setIsLoading(true);
        
        // Calcular la nueva fecha de término basada en la duración original
        const startDate = dateUtils.parseLocalDate(subtask.start);
        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + (subtask.duration * 7));
        const formattedEndDate = dateUtils.formatLocalDate(newEndDate);
  
        const updatedSubtaskData = {
          ...subtask,
          tarea_id: mainTask.id,
          id: subtask.id,
          isClosed: false,
          progreso: 0,
          end: formattedEndDate,
          fecha_termino: formattedEndDate,
          dependencias: JSON.stringify(subtask.dependsOn)
        };
  
        // Actualizar en la base de datos
        await updateSubtaskInDB(updatedSubtaskData);
        
        // Actualizar el estado local
        onChangeSubtask(updatedSubtaskData);
  
      } catch (error) {
        console.error('Error al abrir subtarea:', error);
        alert('Error al abrir la subtarea. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
  
  
    const createNewSubtask = (option: NewSubtaskOption) => {
      // En lugar de crear directamente la subtarea aquí,
      // llamamos a la función proporcionada por el padre
      onAddNewSubtask(option);
      
      // Cerrar los diálogos
      setShowNewSubtaskDialog(false);
      setSelectedResolutionType('');
      setSelectedNewSubtaskType(null);
      onClose();
    };
    const handleResolutionTypeSelection = async (resolutionType: string) => {
      try {
        setIsLoading(true);
        const today = dateUtils.getTodayString();
    
        // Crear el objeto de datos actualizado con el tipo de resolución
        const updatedSubtaskData = {
          ...subtask,
          tarea_id: mainTask.id,
          id: subtask.id,
          type: subtask.type,  
          dependencias: JSON.stringify(subtask.dependsOn),
          isClosed: true,
          progreso: 100,
          end: today,
          fecha_termino: today,
          resolutionType: resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO' | undefined , // Añadir el tipo de resolución
          resolucion_tipo: resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO' | undefined,
        };
    //    alert(JSON.stringify(updatedSubtaskData))
        // Actualizar en la base de datos
        await updateSubtaskInDB(updatedSubtaskData);
        
        // Actualizar el estado local
        onChangeSubtask(updatedSubtaskData);
    
        // Cerrar el diálogo y el modal
        setShowResolutionTypeDialog(false);
        onClose();
    
      } catch (error) {
        console.error('Error al cerrar resolución:', error);
        alert('Error al cerrar la resolución. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
    
  
    
    const handleSaveChanges = async () => {
      try {
        setIsLoading(true);
        const subtaskData = {
          id: subtask.id,
          tarea_id: mainTask.id,
          nombre: subtask.name,
          tipo: subtask.type,
          responsable: subtask.responsible,
          organismo: subtask.organismo,
          progreso: subtask.progreso,
          fecha_inicio: subtask.start,
          fecha_termino: subtask.end,
          duracion: subtask.duration,
          dependencias: JSON.stringify(subtask.dependsOn),
          enabled: true,
          resolucion_tipo: subtask.resolutionType,
          orden: subtask.orden,
          comments: subtask.comments,
          isClosed: subtask.isClosed,
          semaphoreStatus: subtask.semaphoreStatus,
        };
  
        
        await updateSubtaskInDB(subtaskData);
        onClose();
      } catch (error) {
        console.error('Error al guardar cambios:', error);
        alert('Error al guardar los cambios. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <AnimatePresence>
        {isOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <motion.div
              className="bg-white rounded-lg w-full max-w-2xl"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="text-white p-4 flex justify-between items-center rounded-t-lg" 
                   style={{ backgroundColor }}>
                <h2 className="text-xl font-bold">{subtask.name}</h2>
                <button onClick={onClose} className="text-white hover:text-gray-200">
                  <X size={24} />
                </button>
              </div>
              <form className="p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                    <input
                      type="text"
                      name="name"
                      value={subtask.name}
                      onChange={handleInputChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Responsable</label>
                    <input
          type="text"
          name="responsible"
          value={renderResponsible()}
          onChange={handleInputChange}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
        
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fecha de Inicio</label>
                    <input
                      type="date"
                      name="start"
                      value={subtask.start}
                      onChange={handleDateChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fecha de Término</label>
                    <input
                      type="date"
                      name="end"
                      value={subtask.end}
                      onChange={handleDateChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
             {/*    <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="date"
                      name="followUpDate"
                      value={subtask.followUpDate}
                      onChange={handleDateChange}
                      min={dateUtils.getTodayString()}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      disabled={subtask.isClosed}
                    />
                    <button
                      type="button"
                      onClick={handleResetFollowUpDate}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      disabled={subtask.isClosed}
                    >
                      <X className='text-red-500' />
                    </button>
                  </div>*/}
                </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Tipo</label>
                    <select
                      name="type"
                      value={subtask.type}
                      onChange={handleInputChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="INGRESO">INGRESO</option>
                      <option value="OBSERVACIÓN">OBSERVACIÓN</option>
                      <option value="RESPUESTA">RESPUESTA</option>
                      <option value="RESOLUCIÓN">RESOLUCIÓN</option>
                      <option value="OTROS">OTROS</option>
                    </select>
                  </div>
                  
                </div>
                
                {/* Comentarios (similar al TaskModal) */}
                <div className="mt-4">
                  <div className="flex items-center mb-2">
                    <div
                      className="flex items-center cursor-pointer text-indigo-600 hover:text-indigo-800"
                      onClick={() => setShowComments(!showComments)}
                    >
                      <label className="cursor-pointer block text-sm font-medium text-gray-700 mr-2">
                        Comentarios
                      </label>
                      {showComments ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </div>
                  </div>
                </div>
                <AnimatePresence>
                  {showComments && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                   <div className="mt-1 border border-gray-300 rounded-md p-2 max-h-40 overflow-y-auto">
    {subtask.comments && (
      (() => {
        try {
          const parsedComments = JSON.parse(subtask.comments);
          return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
            parsedComments.map((comment, index) => {
              const [datePart, userPart] = comment.split('] User:');
              const date = datePart.replace('[', '').trim();
              const content = userPart.trim();
              
              return (
                <div key={index} className="mb-2">
                  <p className="text-xs text-gray-500">{date}</p>
                  <p className="text-sm">{content}</p>
                </div>
              );
            })
          ) : (
            <p>No hay comentarios</p>
          );
        } catch (error) {
          console.error('Error parsing comments:', error);
          return <p>Error al cargar comentarios</p>;
        }
      })()
    )}
  </div>
  
                      <div className="mt-2 flex">
                        <input
                          type="text"
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          className="flex-grow border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-l-md"
                          placeholder="Agregar comentario..."
                        />
                        <button
                          type="button"
                          onClick={handleAddComment}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Agregar
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <div className="mt-4 flex justify-between p-6">
    <button
      type="button"
      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none  bg-red-600 hover:bg-red-700 focus:ring-2 focus:ring-offset-2 `}
      onClick={subtask.isClosed ? handleOpenSubtask : handleCloseSubtask}
     // disabled={!subtask.isClosed && subtask.semaphoreStatus === SemaphoreStatus.Gray}
    >
      {subtask.isClosed ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
    </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleSaveChanges}
                >
                  Guardar Cambios
                </button>
              </div>
              </form>
            </motion.div>
          </div>
        )}
  
  {showResolutionTypeDialog && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
      <motion.div
        className="bg-white rounded-lg p-6 w-full max-w-md relative"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <button
          onClick={() => setShowResolutionTypeDialog(false)}
          className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10"
        >
          <X size={24} />
        </button>
  
        <h3 className="text-lg font-semibold text-gray-900 mb-4">
          Seleccionar tipo de resolución
        </h3>
        <div className="space-y-2">
          {resolutionTypes.map((type) => (
            <button
              key={type.value}
              onClick={() => handleResolutionTypeSelection(type.value)}
              className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
            > 
              <div className={`mr-3 ${type.color}`}>{type.icon}</div>
              <span className={`font-medium ${type.color}`}>{type.label}</span>
            </button>
          ))}
        </div>
      </motion.div>
    </div>
  )}
  
  
         {/* Modal para seleccionar nueva subtarea */}
         <AnimatePresence>
          {showNewSubtaskDialog && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
              <motion.div
                className="bg-white rounded-lg p-6 w-full max-w-md"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
                <div className="space-y-4">
                {nextOptions.map((option) => (
                  <button
                    key={option.type}
                    className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                    onClick={() => handleNewSubtaskSelection(option)}
                  >
                    {option.name}
                  </button>
                ))}
                  <button
                    className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                    onClick={() => setShowNewSubtaskDialog(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </AnimatePresence>
    );
  };
  

  interface ReminderValue {
    type: 'specific-date' | 'relative-time' | 'progress-based';
    value: string;
  }
  
  const parseReminderValue = (reminderString: string): ReminderValue | null => {
    if (!reminderString) return null;
  
    if (reminderString.startsWith('relative:')) {
      return {
        type: 'relative-time',
        value: reminderString.replace('relative:', '')
      };
    } else if (reminderString.startsWith('progreso:')) {
      return {
        type: 'progress-based',
        value: reminderString.replace('progreso:', '')
      };
    } else {
      return {
        type: 'specific-date',
        value: reminderString
      };
    }
  };


  const lightenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  };


  
  const TaskModal: React.FC<TaskModalProps> = ({ 
    isOpen, 
    onClose, 
    task, 
    onChangeTask, 
    backgroundColor,
    allTasks,
    agrupadorId,
    daysDelayed,
    groupProgress,
    processName,         // Prop: Nombre del proceso
    subprocessName,      // Prop: Nombre del subproceso
    agrupadorName,  
  }) => {
    const [comments, setComments] = useState<string>(task.comments || '');
    const [newComment, setNewComment] = useState('');
    const [showComments, setShowComments] = useState(true); // Set to true by default for better visibility
    const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
    const [showReminderPicker, setShowReminderPicker] = useState(false);
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedTitle = sessionStorage.getItem('title');
    const storedLastName = sessionStorage.getItem('lastName');
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const commentsEndRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
    const [editedName, setEditedName] = useState(task.nombre);
    const storedUserId = sessionStorage.getItem('userId');
    const [localStartDate, setLocalStartDate] = useState<string>(task.fecha_inicio || '');
    const [localEndDate, setLocalEndDate] = useState<string>(task.fecha_termino || '');
    const [isLoading, setIsLoading] = useState(false);
    const [reminderType, setReminderType] = useState<'date' | 'relative' | 'progress'>('progress');
    const [selectedDate, setSelectedDate] = useState('');
    const [timeValue, setTimeValue] = useState(1);
    const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
    const [progressValue, setProgressValue] = useState(50);
    const [isLoadingReminder, setIsLoadingReminder] = useState(false);
  
  
    
  
  // Función para cargar los recordatorios de una tarea
  const loadTaskReminders = async (taskId: number): Promise<ReminderData | null> => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_task_reminders.php?taskId=${taskId}`);
      if (!response.ok) {
        throw new Error('Error loading task reminders');
      }
      const data = await response.json();
      return data.reminder || null;
    } catch (error) {
      console.error('Error loading reminders for task:', taskId, error);
      return null;
    }
  };
  
  // Función para cargar los recordatorios de una subtarea
  const loadSubtaskReminders = async (taskId: number, subtaskId: number): Promise<ReminderData | null> => {
    try {
      const response = await fetch(
        `http://localhost:3000/php/pages/adm_planificacion/get_subtask_reminders.php?taskId=${taskId}&subtaskId=${subtaskId}`
      );
      if (!response.ok) {
        throw new Error('Error loading subtask reminders');
      }
      const data = await response.json();
      return data.reminder || null;
    } catch (error) {
      console.error('Error loading reminders for subtask:', subtaskId, error);
      return null;
    }
  };
  
  
  
    const formatDate = (date: string) => {
      if (!date) return '';
      const parsedDate = new Date(date);
      return parsedDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };
  
    
      // Handle task name change
      const handleNameChange = async () => {
        if (editedName.trim() === '') {
          toast.error('El nombre no puede estar vacío');
          return;
        }
      
        try {
          const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_name.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: task.id, name: editedName }),
          });
      
          if (!response.ok) {
            throw new Error('Error al actualizar el nombre de la tarea');
          }
      
          // Actualizar el estado local de la tarea
          onChangeTask({ ...task, nombre: editedName });
          toast.success('Nombre de la tarea actualizado');
        } catch (error) {
          console.error('Error al actualizar el nombre:', error);
          toast.error('Hubo un error al guardar el nombre. Por favor, inténtalo nuevamente.');
        }
      };
  
      const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'start') {
          setLocalStartDate(value);
        } else if (name === 'end') {
          setLocalEndDate(value);
        }
      };
  
      const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(e.target.value);
      };
  
      const handleDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
      
        // Actualiza el estado local
        const updatedTask = { ...task, [name]: value };
        onChangeTask(updatedTask); // Actualizar estado local en el componente padre
      
        try {
          // Llamada al backend para sincronizar los cambios
          const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_dates.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              taskId: task.id,
              start: updatedTask.fecha_inicio,
              end: updatedTask.fecha_termino,
            }),
          });
      
          const result = await response.json();
      
          if (!response.ok || !result.success) {
            throw new Error(result.message || 'Error al actualizar las fechas');
          }
      
          console.log('Fechas actualizadas en el backend:', result.message);
        } catch (error) {
          console.error('Error al actualizar las fechas en el backend:', error);
          toast.error('Error al sincronizar las fechas con el servidor');
        }
      };
      
  
    const formatDateDDMMYYYY = (dateString: string) => {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    };
  
  
    const commentsContainerRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, [task.comments, isOpen]); // Agrega isOpen como dependencia
  
    
    
    useEffect(() => {
      if (commentsEndRef.current) {
        commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [comments]);
    

  // Función para cargar comentarios de una tarea
  const loadTaskComments = async (taskId: number): Promise<string[]> => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskId}`);
      
      if (!response.ok) {
        throw new Error('Error loading task comments');
      }
  
      const data: CommentResponse = await response.json();
      if (data.success && data.comments) {
        return data.comments;
      }
      return [];
    } catch (error) {
      console.error('Error loading task comments:', error);
      throw error;
    }
  };
  

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
  
    try {
      setIsLoadingComments(true);
      
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
  
      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      
      let currentComments = [];
      try {
        currentComments = task.comments ? JSON.parse(task.comments) : [];
      } catch (error) {
        console.error('Error parsing existing comments:', error);
        currentComments = [];
      }
  
      const updatedComments = [...currentComments, commentWithTimestamp];
      const newCommentsString = JSON.stringify(updatedComments);
  
      // Usar onChangeTask para actualizar el estado
      const updatedTask = {
        ...task,
        comments: newCommentsString
      };
      
      onChangeTask(updatedTask);
      setNewComment(''); // Limpiar el input inmediatamente
  
      // Forzar re-renderizado de comentarios
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
  
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Error al agregar el comentario');
    } finally {
      setIsLoadingComments(false);
    }
  };


  const loadComments = useCallback(async () => {
    if (!task.id) return;
    
    try {
      setIsLoadingComments(true);
      const comments = await loadTaskComments(task.id);
      onChangeTask({
        ...task, 
        fecha_inicio: task.fecha_inicio,
        fecha_termino: task.fecha_termino,
        comments: JSON.stringify(comments),
        isClosed: task.isClosed // Preservar explícitamente el estado
      });
    } catch (error) {
      console.error('Error loading comments:', error);
      toast.error('Error al cargar los comentarios');
    } finally {
      setIsLoadingComments(false);
    }
  }, [task.id, task.isClosed]); // Añadir task.isClosed a las dependencias
  

// Efecto para cargar comentarios cuando se abre el modal
useEffect(() => {
  if (isOpen) {
    loadComments();
  }
}, [isOpen, loadComments]);

// Efecto para scroll automático a nuevos comentarios
useEffect(() => {
  if (commentsEndRef.current) {
    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [task.comments]);
  
  // Cargar comentarios al abrir el modal
  
  
    const handleTaskUpdate = async (task: Task, updatedData: Partial<Task>): Promise<void> => {
      try {
        // Actualizar la tarea principal
        const taskUpdateData: TaskUpdateData = {
          id: task.id,
          isClosed: updatedData.isClosed ?? task.isClosed,
          progreso: updatedData.progreso ?? task.progreso,
          fecha_inicio: updatedData.fecha_inicio ?? task.fecha_inicio,
          fecha_termino: updatedData.fecha_termino ?? task.fecha_termino,
          followUpDate: updatedData.followUpDate ?? task.followUpDate,
          comments: updatedData.comments ?? task.comments,
          semaphoreStatus: updatedData.semaphoreStatus ?? task.semaphoreStatus,
          delayInDays: updatedData.delayInDays
        };
    
    
        // Si hay subtareas actualizadas, procesarlas
        if (updatedData.subtasks && task.subtasks) {
          for (const subtask of updatedData.subtasks) {
            const subtaskUpdateData: SubtaskUpdateData = {
              id: subtask.id!,
              tarea_id: task.id,
              nombre: subtask.name,
              tipo: subtask.type,
              responsible: subtask.responsible,
              organismo: subtask.organismo,
              progreso: subtask.progreso,
              fecha_inicio: subtask.start,
              fecha_termino: subtask.end,
              duracion: subtask.duration,
              dependencias: JSON.stringify(subtask.dependsOn),
              enabled: subtask.enabled,
              resolucion_tipo: subtask.resolutionType,
              orden: subtask.orden,
              comments: subtask.comments,
              isClosed: subtask.isClosed,
              followUpDate: subtask.followUpDate,
              semaphoreStatus: subtask.semaphoreStatus
            };
            if (task.subtasks) {
              for (const subtask of task.subtasks) {
                await updateSubtaskInDB(subtask);
              } 
            }
          }
        }
      } catch (error) {
        console.error('Error in handleTaskUpdate:', error);
        throw error;
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      onChangeTask({ ...task, [name]: value });
    };
  
  
  
  // Función para calcular los días entre dos fechas
  const calculateDurationInDays = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = end.getTime() - start.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  
  // Modificar la parte del renderizado del selector de progreso
  const renderProgressSelector = () => {
    const totalDays = calculateDurationInDays(task.fecha_inicio, task.fecha_termino);
    
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg">
          <h4 className="font-medium mb-2">Progreso basado en duración</h4>
          <p className="text-sm text-gray-600 mb-4">
            La tarea tiene una duración de {totalDays} días. 
            El recordatorio se activará cuando se alcance el porcentaje seleccionado del tiempo total.
          </p>
          
          <select
            value={progressValue}
            onChange={e => setProgressValue(parseInt(e.target.value))}
            className="w-full rounded-md border-gray-300"
            disabled={task.isClosed}
          >
            <option value={25}>25% del tiempo (Día {Math.ceil(totalDays * 0.25)})</option>
            <option value={50}>50% del tiempo (Día {Math.ceil(totalDays * 0.5)})</option>
            <option value={75}>75% del tiempo (Día {Math.ceil(totalDays * 0.75)})</option>
            <option value={90}>90% del tiempo (Día {Math.ceil(totalDays * 0.9)})</option>
          </select>
  
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
            <div className="flex">
              <Info className="h-5 w-5 text-blue-400 mr-2" />
              <p className="text-sm text-blue-700">
                El recordatorio se activará en el día {Math.ceil(totalDays * (progressValue / 100))} 
                de los {totalDays} días totales de la tarea.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
  // Función para generar el reminder_code
  const generateReminderCode = (type: ReminderType, value: string): string => {
    switch (type) {
      case 'specific-date':
        return `f-${value}`;
      case 'relative-time':
        return `rt-${value}`;
      case 'progress-based':
        return `p-${value}`;
      case 'task-expired':
        return `exp-${value}`;
      case 'task-closed':
        return `closed-${value}`;
      default:
        return '';
    }
  };
  
  // Handler de guardado actualizado
  const handleReminderSave = async () => {
    try {
      setIsLoadingReminder(true);
      const currentUser = sessionStorage.getItem('userId');
      let reminder: {
        type: ReminderType;
        value: string;
        code: string;
      };
  
      switch (reminderType) {
        case 'date':
          reminder = {
            type: 'specific-date',
            value: selectedDate,
            code: generateReminderCode('specific-date', selectedDate)
          };
          break;
        case 'relative':
          const relativeValue = `${timeValue}${timeUnit === 'weeks' ? 'w' : 'd'}`;
          reminder = {
            type: 'relative-time',
            value: relativeValue,
            code: generateReminderCode('relative-time', relativeValue)
          };
          break;
        case 'progress':
          reminder = {
            type: 'progress-based',
            value: progressValue.toString(),
            code: generateReminderCode('progress-based', progressValue.toString())
          };
          break;
        default:
          throw new Error('Tipo de recordatorio no válido');
      }
  
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_reminder.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          task_id: task.id,
          reminder_code: reminder.code,
          reminder_type: reminder.type,
          reminder_value: reminder.value,
          user_id: currentUser
        })
      });
  
      if (!response.ok) throw new Error('Error al guardar recordatorio');
  
      const result = await response.json();
      onChangeTask({
        ...task,
        reminder_code: reminder.code,
        reminder_type: reminder.type,
        reminder_value: reminder.value,
        reminder_calculated_date: result.reminder_calculated_date,
        reminder_active: true,
        reminder_triggered: false
      });
  
      toast.success('Recordatorio configurado exitosamente');
      setShowReminderPicker(false);
      onClose()
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al configurar recordatorio');
    } finally {
      setIsLoadingReminder(false);
    }
  };
  
  
    const renderTrackingContent = () => (
      <div className="p-6 space-y-6">
        <div className="bg-white p-4 rounded-lg border">
          <h4 className="font-medium mb-2">Configuración de Recordatorio</h4>
          
          <div className="flex gap-4 mb-6">
            {[
              { id: 'date', label: 'Fecha Específica', icon: Calendar },
              { id: 'relative', label: 'Tiempo Relativo', icon: Clock },
              { id: 'progress', label: 'Por Progreso', icon: AlertTriangle }
            ].map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setReminderType(id as typeof reminderType)}
                className={`flex-1 p-4 rounded-lg border-2 transition-all ${
                  reminderType === id 
                    ? 'border-teal-500 bg-teal-50 text-teal-700' 
                    : 'border-gray-200 hover:border-teal-200'
                }`}
                disabled={task.isClosed}
              >
                <Icon className="w-6 h-6 mx-auto mb-2" />
                <span className="text-sm font-medium block text-center">{label}</span>
              </button>
            ))}
          </div>
  
          {reminderType === 'date' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Seleccionar Fecha
              </label>
              <input
                type="date"
                value={selectedDate}
                onChange={e => setSelectedDate(e.target.value)}
                min={dateUtils.getTodayString()}
                className="w-full rounded-md border-gray-300"
                disabled={task.isClosed}
              />
            </div>
          )}
  
          {reminderType === 'relative' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Repetir Cada
              </label>
              <div className="flex gap-4">
                <input
                  type="number"
                  min="1"
                  value={timeValue}
                  onChange={e => setTimeValue(parseInt(e.target.value))}
                  className="w-24 rounded-md border-gray-300"
                  disabled={task.isClosed}
                />
                <select
                  value={timeUnit}
                  onChange={e => setTimeUnit(e.target.value as 'days' | 'weeks' | 'months')}
                  className="rounded-md border-gray-300"
                  disabled={task.isClosed}
                >
                  <option value="days">Días</option>
                  <option value="weeks">Semanas</option>
                  <option value="months">Meses</option>
                </select>
              </div>
            </div>
          )}
  
          {reminderType === 'progress' && (
            <div className="space-y-4">
              {renderProgressSelector ()}
            </div>
          )}
        </div>
  
        <div className="flex justify-end">
          <Button
            onClick={handleReminderSave}
            disabled={isLoadingReminder || task.isClosed}
            className="bg-teal-600 text-white hover:bg-teal-700"
          >
            {isLoadingReminder ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Guardando...
              </>
            ) : (
              'Guardar Recordatorio'
            )}
          </Button>
        </div>
      </div>
    );
  
  
  
    const parseExistingReminder = (code: string) => {
      if (code.startsWith('f')) {
        setReminderType('date');
        setSelectedDate(code.substring(1));
      } else if (code.startsWith('rt')) {
        setReminderType('relative');
        const [value, unit] = code.substring(2).split(':');
        setTimeValue(parseInt(value));
        setTimeUnit(unit as 'days' | 'weeks' | 'months');
      } else if (code.startsWith('p')) {
        setReminderType('progress');
        setProgressValue(parseInt(code.substring(1)));
      }
    };
  
    const calculateTaskProgress = () => {
      const startDate = new Date(task.fecha_inicio);
      const endDate = new Date(task.fecha_termino);
      const today = new Date();
      
      const totalDuration = endDate.getTime() - startDate.getTime();
      const elapsed = today.getTime() - startDate.getTime();
      
      return Math.round((elapsed / totalDuration) * 100);
    };
  
  
  
  
  
  
  
  const parseReminderCode = (reminderCode: string | null): {
    type: string | null;
    value: string | null;
  } => {
    if (!reminderCode) return { type: null, value: null };
  
    if (reminderCode.startsWith('f-')) {
      return {
        type: 'specific-date',
        value: reminderCode.substring(2)
      };
    }
  
    if (reminderCode.startsWith('rt-')) {
      return {
        type: 'relative-time',
        value: reminderCode.substring(3)
      };
    }
  
    if (reminderCode.startsWith('p-')) {
      return {
        type: 'progress-based',
        value: reminderCode.substring(2)
      };
    }
  
    if (reminderCode.startsWith('exp-')) {
      return {
        type: 'task-expired',
        value: reminderCode.substring(4)
      };
    }
  
    if (reminderCode.startsWith('closed-')) {
      return {
        type: 'task-closed',
        value: reminderCode.substring(7)
      };
    }
  
    return { type: null, value: null };
  };
  
  // Función para verificar el estado del recordatorio
  const checkReminderStatus = (item: Task | SubTask): {
    isActive: boolean;
    isTriggered: boolean;
  } => {
    if (!item.reminder_code) return { isActive: false, isTriggered: false };
  
    const { type, value } = parseReminderCode(item.reminder_code);
    if (!type || !value) return { isActive: false, isTriggered: false };
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    switch (type) {
      case 'specific-date': {
        const reminderDate = new Date(value);
        reminderDate.setHours(0, 0, 0, 0);
        return {
          isActive: true,
          isTriggered: today >= reminderDate
        };
      }
  
      case 'relative-time': {
        const [amount, unit] = value.split(/(\d+)/).filter(Boolean);
        const reminderDate = new Date();
        if (unit === 'w') {
          reminderDate.setDate(reminderDate.getDate() + (parseInt(amount) * 7));
        } else {
          reminderDate.setDate(reminderDate.getDate() + parseInt(amount));
        }
        return {
          isActive: true,
          isTriggered: today >= reminderDate
        };
      }
  
      case 'progress-based': {
        const targetProgress = parseInt(value);
        return {
          isActive: true,
          isTriggered: (item.progreso || 0) >= targetProgress
        };
      }
  
      case 'task-expired':
      case 'task-closed': {
        return {
          isActive: true,
          isTriggered: true
        };
      }
  
      default:
        return { isActive: false, isTriggered: false };
    }
  };
  
  const getReminderStatus = (item: Task | SubTask) => {
    // Si no hay recordatorio configurado
    if (!item.reminder_type || !item.reminder_value) {
      return {
        color: 'bg-gray-200',
        icon: null,
        tooltipText: 'Sin recordatorio'
      };
    }
  
    // Si el recordatorio está activo y se ha disparado
    if (item.reminder_active && item.reminder_triggered) {
      return {
        color: 'bg-red-500',
        icon: getReminderTypeIcon(item),
        tooltipText: getReminderTooltipText(item)
      };
    }
  
    // Si el recordatorio está activo pero no se ha disparado
    if (item.reminder_active && !item.reminder_triggered) {
      return {
        color: 'bg-yellow-500',
        icon: getReminderTypeIcon(item),
        tooltipText: getReminderTooltipText(item)
      };
    }
  
    // Si el recordatorio está inactivo
    return {
      color: 'bg-gray-200',
      icon: getReminderTypeIcon(item),
      tooltipText: 'Recordatorio inactivo'
    };
  };
  
  
  
  // Función para obtener el texto del tooltip
  const getReminderTooltipText = (item: Task | SubTask): string => {
    if (!item.reminder_type || !item.reminder_value) {
      return 'Sin recordatorio';
    }
  
    switch (item.reminder_type) {
      case 'specific-date':
        return `Recordatorio para: ${formatDate(item.reminder_calculated_date || '')}`;
      case 'relative-time': {
        const value = item.reminder_value;
        const unit = value.endsWith('w') ? 'semanas' : 'días';
        const amount = value.slice(0, -1);
        return `Recordatorio en ${amount} ${unit}`;
      }
      case 'progress-based':
        return `Recordar al ${item.reminder_value}% de progreso`;
      case 'task-expired':
        return 'Tarea vencida';
      case 'task-closed':
        return 'Tarea cerrada';
      default:
        return 'Sin recordatorio';
    }
  };
  
  
  
  // Función para obtener el ícono
  const getReminderTypeIcon = (item: Task | SubTask) => {
    if (!item.reminder_type) return null;
  
    switch (item.reminder_type) {
      case 'specific-date':
        return <Calendar className="w-4 h-4" />;
      case 'relative-time':
        return <Clock className="w-4 h-4" />;
      case 'progress-based':
        return <AlertTriangle className="w-4 h-4" />;
      case 'task-expired':
        return <AlertTriangle className="w-4 h-4" />;
      case 'task-closed':
        return <Check className="w-4 h-4" />;
      default:
        return null;
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  
  // Actualizar también la sección del renderizado de comentarios:
const renderComments = () => {
  if (!task.comments) {
    return <p className="text-gray-500 italic">No hay comentarios</p>;
  }

  try {
    const parsedComments = JSON.parse(task.comments);
    return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
      parsedComments.map((comment, index) => {
        const [datePart, userAndContent] = comment.split('] ');
        const date = datePart.replace('[', '').trim();
        const [user, ...contentParts] = userAndContent.split(':');
        const content = contentParts.join(':').trim();
        const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;

        return (
          <div
            key={index}
            className={`mb-3 last:mb-0 flex ${
              !isCurrentUser ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                isCurrentUser
                  ? 'bg-green-100 text-green-900'
                  : 'bg-blue-100 text-blue-900'
              }`}
              style={{
                maxWidth: 'calc(100% - 20px)',
                wordBreak: 'break-word',
              }}
            >
              <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                <span className="font-medium">{user}</span>
                <span className="text-gray-400">•</span>
                <span className="text-gray-400">{date}</span>
              </div>
              <p className="text-sm whitespace-pre-wrap">{content}</p>
            </div>
          </div>
        );
      })
    ) : (
      <p className="text-gray-500 italic">No hay comentarios</p>
    );
  } catch (error) {
    console.error('Error parsing comments:', error);
    return <p className="text-red-500">Error al cargar comentarios</p>;
  }
};

  
  
    // Función para actualizar comentarios de una tarea principal
  const updateTaskComments = async (taskData: CommentData): Promise<void> => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_comments.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: taskData.id,
          comments: taskData.comments
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating task comments');
      }
  
      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || 'Error updating task comments');
      }
    } catch (error) {
      console.error('Error in updateTaskComments:', error);
      throw error;
    }
  };
  
  
  
  
    const [updateTrigger, setUpdateTrigger] = useState(0);
  /*
    const handleCloseTask = async () => {
      if (task.semaphoreStatus === SemaphoreStatus.Gray) {
        alert("No se puede cerrar una tarea que aún no ha iniciado.");
        return;
      }
    
      // Crear fecha actual en zona horaria local
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
      const formattedDate = today.getFullYear() + '-' + 
        String(today.getMonth() + 1).padStart(2, '0') + '-' + 
        String(today.getDate()).padStart(2, '0');
    
      const updatedTask = { 
        ...task, 
        isClosed: true, 
        progreso: 100, 
        end: formattedDate 
      };
      
      try {
        // Actualizar la tarea en la base de datos
        await updateTaskInDatabase(updatedTask);
        onChangeTask(updatedTask);
        setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
    
        // Calcular y actualizar el progreso del agrupador
        // await updateAgrupadorProgress(task.id);
        
        onChangeTask(updatedTask);
      } catch (error) {
        console.error('Error al cerrar la tarea:', error);
        alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
      }
    };
  */
  
  
  useEffect(() => {
    if (task.followUpDate) {
      parseExistingReminder(task.followUpDate);
    }
  }, [task.followUpDate]);
  

useEffect(() => {
  const checkAndCloseMainTask = async () => {
    if (
      task.nombre === 'TRAMITACIÓN' && 
      task.subtasks && 
      task.subtasks.length > 0 && 
      !task.isClosed
    ) {
      const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
      
      if (allSubtasksClosed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const formattedDate = today.getFullYear() + '-' + 
          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
          String(today.getDate()).padStart(2, '0');

        const updatedTask = { 
          ...task, 
          isClosed: true, 
          progreso: 100, 
          fecha_termino: formattedDate 
        };
        
   
      }
    }
  };

  checkAndCloseMainTask();
}, [task.subtasks]);

  
const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

// First, let's define the modalVariants
const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 500, damping: 25 }
  },
  exit: { 
    opacity: 0, 
    scale: 0.8,
    transition: { duration: 0.2 }
  }
};

const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  
  
  useEffect(() => {
    if (isOpen) {
 //     refreshTaskData();
    }
  }, [isOpen]);
  
  
    // Efecto para sincronizar el estado local cuando cambian las props
    useEffect(() => {
      if (isOpen) {
        // Actualizar el estado local con los datos más recientes
        setLocalTask(task);
        setLocalStartDate(task.fecha_inicio);
        setLocalEndDate(task.fecha_termino);
        setEditedName(task.nombre);
      }
    }, [isOpen, task]);
    
  const [localTask, setLocalTask] = useState<Task>(task);
  
  
    // Función para cargar los datos más recientes de la tarea
    const refreshTaskData = async () => {
      try {
        const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.id}`);
        if (!response.ok) throw new Error('Error fetching task data');
        
        const updatedTaskData = await response.json();
        if (updatedTaskData.success) {
          const refreshedTask = {
            ...task,
            ...updatedTaskData.task,
            fecha_inicio: updatedTaskData.task.fecha_inicio,
            fecha_termino: updatedTaskData.task.fecha_termino
          };
          
          setLocalTask(refreshedTask);
          setLocalStartDate(refreshedTask.fecha_inicio);
          setLocalEndDate(refreshedTask.fecha_termino);
          setEditedName(refreshedTask.nombre);
          onChangeTask(refreshedTask); // Actualizar el estado global
        }
      } catch (error) {
        console.error('Error refreshing task data:', error);
      }
    };
  
  
  const handleCloseTask = async () => {
    try {
      // Validaciones...
      const today = dateUtils.getTodayString();
      
      const updatedTask = {
        ...localTask,
        isClosed: true,
        progreso: 100,
        fecha_termino: today
      };
  
      // Actualizar estado local inmediatamente
      setLocalTask(updatedTask);
      setLocalEndDate(today);
  
    
      // Actualizar las tareas dependientes...
      
      // Actualizar el estado global
      onChangeTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      toast.error('Error al cerrar la tarea');
      // Revertir cambios locales en caso de error
      setLocalTask(task);
      setLocalEndDate(task.fecha_termino);
    }
  };
  
  
  const canCloseTramitacionTask = (task: Task): boolean => {
    if (task.nombre !== 'TRAMITACIÓN') return true;
    if (!task.subtasks || task.subtasks.length === 0) return true;
    
    return task.subtasks.every(subtask => subtask.isClosed);
  };
  
  // Función para actualizar las fechas de las subtareas
  /*const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
  
    return subtasks.map(subtask => ({
      ...subtask,
      end: subtask.isClosed ? subtask.end : formattedDate // Solo actualizar fechas de subtareas abiertas
    }));
  };
  */
  
  // Función para abrir una tarea
  const handleOpenTask = async () => {
    try {
      // Usar dateUtils para parsear la fecha de inicio
      const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
      
      // Calcular la nueva fecha de término usando dateUtils
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (task.duracion * 7));
      const formattedEndDate = dateUtils.formatLocalDate(newEndDate);
  
      const updatedTask = { 
        ...task, 
        isClosed: false,
        progreso: 0,
        end: formattedEndDate
      };
  
      // Actualizar la tarea en la base de datos
      // Actualizar el estado local
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1);
      onChangeTask(updatedTask);
  
    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
    }
  };
  
    const handleResetFollowUpDate = () => {
      onChangeTask({ ...task, followUpDate: '' });
    };
  
  
  
    // Función para actualizar la subtarea en la base de datos
    const updateSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: subtaskData,
            fecha_inicio: subtaskData.start,
            fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
            resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field
  
          })
        });
        console.log(JSON.stringify(subtaskData))
        if (!response.ok) {
          throw new Error('Error al actualizar la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en updateSubtaskInDB:', error);
        throw error;
      }
    };
  
  
  
    const handleSaveChanges = async () => {
      try {
        const updatedTask = {
          ...task,
          fecha_inicio: localStartDate,
          fecha_termino: localEndDate,
          nombre: editedName,
          comments: task.comments, // Añadir comentarios
          followUpDate: task.followUpDate, // Añadir fecha de seguimiento 
          subtasks: task.subtasks, // Incluye subtareas
  
        };
       
        if (task.subtasks) {
          for (const subtask of task.subtasks) {
            await updateSubtaskInDB(subtask);
          } 
        }
  
       
        onChangeTask(updatedTask); // Actualizar el estado en el frontend
  
        onChangeTask(updatedTask);
        onClose();
      } catch (error) {
        console.error('Error al guardar los cambios:', error);
        alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
      }
    };
  
  
  
  const semaphoreStatus = calculateSemaphoreStatus(
    task.fecha_inicio,
    task.fecha_termino,
    task.id,
    task.dependencia,
    task.isClosed,
    allTasks
  );
  
  
    const formatFollowUpDate = (date: string): string => {
      if (!date) return 'No establecida';
      const followUpDate = new Date(date);
      const today = new Date();
      const diffTime = followUpDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
      if (diffDays < 0) {
        return `Hace ${Math.abs(diffDays)} días`;
      } else if (diffDays === 0) {
        return 'Hoy';
      } else if (diffDays === 1) {
        return 'Mañana';
      } else {
        return `En ${diffDays} días`;
      }
    };
  
    return (
      <AnimatePresence>
     {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={contentVariants}
          className="fixed inset-0 flex items-center justify-center  z-[10001] "
        >
            <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
            <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
            <motion.div 
              className="bg-white rounded-lg overflow-hidden shadow-xl"
              variants={contentVariants}
            >
       
  
              <div
                className="bg-teal-600 p-3 text-white"
               
              >
                       <div className="relative">
    {/* Botón de cierre */}
    <button
      onClick={onClose}
      className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
    >
      <X size={20} />
    </button>
  </div>
      <h3 className="text-base font-semibold flex items-center space-x-2">
        <MdBusiness className="text-white w-5 h-5" /> 
        <span className="text-sm opacity-80 tracking-wide">
          {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName?.toUpperCase()}`}
        </span>
      </h3>
      <div className="flex mt-4 mb-3">
  
      <div className="flex items-center">
        <span className="text-lg font-bold text-white">{task.nombre}</span>
      </div>
    {/* Estado de la tarea */}
  <div className="ml-3 flex items-center">
    <div
      className={`px-3 py-1 rounded-full text-sm font-medium ${
        task.progreso === 100 && task.isClosed
          ? `${backgroundColor} text-gray-800` // Estilo para tareas cerradas
          : semaphoreStatus === SemaphoreStatus.Red
          ? 'bg-red-100 text-red-700'
          : semaphoreStatus === SemaphoreStatus.Orange
          ? 'bg-orange-100 text-orange-700'
          : semaphoreStatus === SemaphoreStatus.Yellow
          ? 'bg-yellow-100 text-yellow-700'
          : semaphoreStatus === SemaphoreStatus.Green
          ? 'bg-green-100 text-green-700'
          : 'bg-gray-100 text-gray-700'
      }`}
         style={
        task.progreso === 100 && task.isClosed
          ? {
              backgroundImage: `repeating-conic-gradient(
                ${lightenColor(backgroundColor, 80)} 0% 25%, 
                #ffffff 25% 50%
              )`,
              backgroundSize: '10px 10px', // Tamaño del damero
            }
          : undefined
      }
    > 
   
      {task.progreso === 100 && task.isClosed
        ? 'Cerrada'
        : semaphoreStatus === SemaphoreStatus.Red
        ? `Retrasada por ${daysDelayed} día(s)`
        : semaphoreStatus === SemaphoreStatus.Orange
        ? 'Próxima a vencer'
        : semaphoreStatus === SemaphoreStatus.Yellow
        ? 'A tiempo, pero próxima'
        : semaphoreStatus === SemaphoreStatus.Green
        ? 'A tiempo'
        : 'No iniciada'}
    </div>
  </div>
  
      </div>
      <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
        <span className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          {formatDateDDMMYYYY(task.fecha_inicio)}
        </span>
        <span className="flex items-center gap-1">
          <Clock className="w-4 h-4" />
          {formatDateDDMMYYYY(task.fecha_termino)}
        </span>
  
      </div>
   
      </div>
    
    </motion.div>
  
  <div className="border-b">
            <nav className="flex">
              <button
                onClick={() => setActiveTab('details')}
                className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
              >
                Detalles
              </button>
              <button
                onClick={() => setActiveTab('followup')}
                className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
              >
                Seguimiento
              </button>
            </nav>
          </div>
  
          {activeTab === 'details' ? (
    <div className="p-4">
     
  
      {/* Grid de campos */}
      <div className="grid grid-cols-2 gap-3 mb-4">
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Nombre
          </label>
          <input
            type="text"
            value={editedName || ''}
            onChange={handleLocalNameChange}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed}
          />
        </div>
  
        <div></div>
  
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Fecha de Inicio
          </label>
          <input
            type="date"
            name="start"
            value={localStartDate}
            onChange={handleLocalDateChange}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed && task.progreso === 100}
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Fecha de Término
          </label>
          <input
            type="date"
            name="end"
            value={localEndDate}
            onChange={handleLocalDateChange}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed && task.progreso === 100}
          />
        </div>
      </div>
  
      {/* Actualizar sección de comentarios */}
      <div className="comments-section">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-medium">Comentarios</h3>
          {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
        </div>
  
        <div
          ref={commentsContainerRef}
          className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
        >
          {task.comments ? (
            (() => {
              try {
                const parsedComments = JSON.parse(task.comments);
                return Array.isArray(parsedComments) &&
                  parsedComments.length > 0 ? (
                  parsedComments.map((comment, index) => {
                    const [datePart, userAndContent] = comment.split('] ');
                    const date = datePart.replace('[', '').trim();
                    const [user, ...contentParts] = userAndContent.split(':');
                    const content = contentParts.join(':').trim();
                    const isCurrentUser =
                      user === `${storedFirstName} ${storedLastName}`;
                    return (
                      <div
                        key={index}
                        className={`mb-3 last:mb-0 flex ${
                          !isCurrentUser ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        <div
                          className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                            isCurrentUser
                              ? 'bg-green-100 text-green-900'
                              : 'bg-blue-100 text-blue-900'
                          }`}
                          style={{
                            maxWidth: 'calc(100% - 20px)', // Asegura que el texto no exceda el ancho del contenedor principal
                            wordBreak: 'break-word', // Permite dividir palabras largas
                          }}
                        >
                          <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                            <span className="font-medium">{user}</span>
                            <span className="text-gray-400">•</span>
                            <span className="text-gray-400">{date}</span>
                          </div>
                          <p className="text-sm whitespace-pre-wrap">{content}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-gray-500 italic">No hay comentarios</p>
                );
              } catch (error) {
                return (
                  <p className="text-red-500">Error al cargar comentarios</p>
                );
              }
            })()
          ) : (
            <p className="text-gray-500 italic">Cargando comentarios...</p>
          )}
        </div>
  
        <div className="mt-4 flex gap-2">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Escribir un comentario..."
            rows={2}
            className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
            disabled={task.isClosed}
            maxLength={250}
          />
          <button
            onClick={async () => {
              await handleAddComment();
              setNewComment(''); // Limpiar input después de agregar el comentario
            }}
            disabled={task.isClosed || !newComment.trim() || isLoadingComments}
            className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
          >
            Agregar
          </button>
        </div>
      </div>
       {/* Botones de acción */}
       <div className="flex justify-between mt-4 pt-2 border-t">
                <button
    type="button"
    className={`px-3 py-1.5 rounded text-sm font-medium ${
      task.isClosed
        ? 'bg-green-600 hover:bg-green-700 text-white'
        : task.semaphoreStatus === SemaphoreStatus.Gray || 
          (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
        ? 'bg-gray-400 text-white cursor-not-allowed'
        : 'bg-red-600 hover:bg-red-700 text-white'
    }`}
    onClick={task.isClosed ? handleOpenTask : handleCloseTask}
    disabled={
      !task.isClosed && 
      (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed || 
       (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
    }
  >
                    {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                  </button>
  
                  <button
                    type="button"
                    onClick={handleSaveChanges}
                    className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
                  >
                    Guardar Cambios
                  </button>
                </div>
    </div>
    
  ) :            
  <motion.div
  initial="hidden"
  animate="visible"
  exit="exit"
  variants={modalVariants}
  className="space-y-4 "
  >  { 
  renderTrackingContent()
  }
        
  </motion.div>
  }
  </Dialog>
  
  
  
                 </motion.div>
                 
            </motion.div>
            
        )}
      </AnimatePresence>
    );
  };
  





interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}


interface Task {
  id: number;
  nombre: string;
  description: string;
  responsible: User[] | string; 
  progreso: number;
  fecha_inicio: string;
  organismo: string;
  duracion: number;
  fecha_termino: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
  reminder_type?: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value?: string | null;
  reminder_calculated_date?: string | null;
  reminder_active?: boolean;
  reminder_code?: string; // Añadimos el campo reminder_code como opcional

  reminder_triggered?: boolean;
}



enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}



interface SubTask {
  id?: number;
  name: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progreso: number;
  start: string;
  end: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  reminder_type?: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value?: string | null;
  reminder_calculated_date?: string | null;
  reminder_active?: boolean;
  reminder_code?: string; // Añadimos el campo reminder_code como opcional

  reminder_triggered?: boolean;
}




const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'Silencio Administrativo Positivo',
    icon: <Clock className="h-5 w-5" />,
    color: 'text-blue-700',
    bgColor: 'bg-blue-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'Silencio Administrativo Negativo',
    icon: <Clock className="h-5 w-5" />,
    color: 'text-purple-700',
    bgColor: 'bg-purple-100'
  }
];



interface Agrupador {
  id: number;
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Task[];
  orden: number;
}
interface TaskItemProps {
  task: Task;
  status: 'inProgress' | 'pending' | 'warning' | 'success' | 'danger';
  onChangeTask: (updatedTask: Task) => void;
  onTaskClick: (task: Task) => void; // Nueva prop para manejar el clic
  color: string; // Add color prop

}

const TaskItem: React.FC<TaskItemProps> = React.memo(({ 
  task, 
  status, 
  onChangeTask, 
  onTaskClick, 
  color 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeSubtask, setActiveSubtask] = useState<SubTask | null>(null);

  // Función auxiliar para convertir fechas de string a Date
  const getDateFromString = (dateStr: string): Date => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    isClosed: boolean,
    isSubtask: boolean = false,
    parentTask?: Task
  ): SemaphoreStatus => {
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Validar y convertir `start` a Date
    let startDate = null;
    if (start) {
      const [startYear, startMonth, startDay] = start.split('-').map(Number);
      startDate = new Date(startYear, startMonth - 1, startDay);
      startDate.setHours(0, 0, 0, 0);
    }
  
    // Validar y convertir `end` a Date
    let endDate = null;
    if (end) {
      const [endYear, endMonth, endDay] = end.split('-').map(Number);
      endDate = new Date(endYear, endMonth - 1, endDay);
      endDate.setHours(0, 0, 0, 0);
    }
  
    // Si alguna fecha es inválida, devuelve `Gray` como predeterminado
    if (!startDate || !endDate) {
      return SemaphoreStatus.Gray;
    }
  
    // Para subtareas dentro de tareas como 'TRAMITACIÓN'
    if (parentTask) {
      if (parentTask.nombre === 'TRAMITACIÓN') {
        if (parentTask.semaphoreStatus === SemaphoreStatus.Gray || startDate > today) {
          return SemaphoreStatus.Gray;
        }
        
        const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
        if (today > endDate) return SemaphoreStatus.Red;
        if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
        if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
        return SemaphoreStatus.Green;
      }
    }
  
    // Para tareas principales
    if (today > endDate) {
      return SemaphoreStatus.Red;
    }
    if (startDate <= today) {
      const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
      if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
      return SemaphoreStatus.Green;
    }
  
    return SemaphoreStatus.Gray;
  };

 

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };

  // Procesar las subtareas con el estado del semáforo actualizado
  const processedSubtasks = useMemo(() => 
    task.subtasks?.map(subtask => ({
      ...subtask,
      semaphoreStatus: calculateSemaphoreStatus(
        subtask.start,
        subtask.end,
        subtask.isClosed,
        true,
        task
      )
    })) || [], [task, task.subtasks]
  );

  const getCheckerboardPattern = (color: string) => {
    const lightColor = lightenColor(color, 100);
    return {
      backgroundImage: `
        linear-gradient(45deg, ${lightColor} 25%, transparent 25%),
        linear-gradient(-45deg, ${lightColor} 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, ${lightColor} 75%),
        linear-gradient(-45deg, transparent 75%, ${lightColor} 75%)
      `,
      backgroundSize: '20px 20px',
      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
      backgroundColor: 'white'
    };
  };

  const getSemaphoreConfig = () => {
    if (task.isClosed && task.progreso === 100) {
      return {
        bg: 'relative',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: () => ({
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        }),
        closedTextBackground: 'bg-white',
      };
    }


    switch (task.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };

  const getSubtaskSemaphoreConfig = (subtask: SubTask) => {
    if (subtask.isClosed && subtask.progreso === 100) {
      return {
        bg: 'relative',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: () => ({
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        }),
        closedTextBackground: 'bg-white',
      };
    }
    switch (subtask.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };
  

  const config = getSemaphoreConfig();





  // Add handler for subtask click
  const handleSubtaskClick = (subtask: SubTask, e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveSubtask(subtask);
  };

  // Add handler for subtask changes
const handleChangeSubtask = (updatedSubtask: SubTask) => {
  if (!task.subtasks) return;
  
  const updatedSubtasks = task.subtasks.map(st => 
    st.id === updatedSubtask.id ? updatedSubtask : st
  );

  const updatedTask = {
    ...task,
    subtasks: updatedSubtasks
  };

  onChangeTask(updatedTask);
};

  // Add handler for new subtasks
  const handleAddNewSubtask = async (option: NewSubtaskOption) => {
    if (!task.subtasks) return;

    try {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      
      const newSubtaskData = {
        tarea_id: task.id,
        nombre: option.name,
        tipo: option.type,
        responsable: option.responsable,
        organismo: task.organismo,
        progreso: 0,
        fecha_inicio: formattedDate,
        fecha_termino: formattedDate,
        duracion: 1,
        enabled: true,
        orden: task.subtasks.length + 1
      };

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'create',
          subtask: newSubtaskData
        })
      });

      if (!response.ok) throw new Error('Failed to create subtask');

      const result = await response.json();
      
      if (result.success) {
        const newSubtask: SubTask = {
          id: result.id,
          name: option.name,
          type: option.type,
          responsible: option.responsable,
          progreso: 0,
          start: formattedDate,
          end: formattedDate,
          duration: 1,
          organismo: task.organismo,
          enabled: true,
          orden: task.subtasks.length + 1,
          comments: '',
          followUpDate: '',
          followUp: false,
          semaphoreStatus: SemaphoreStatus.Green,
          isClosed: false,
          delayInDays: 0,
          resolutionType: option.resolutionType as any
        };

        const updatedTask = {
          ...task,
          subtasks: [...task.subtasks, newSubtask]
        };

        onChangeTask(updatedTask);
      }
    } catch (error) {
      console.error('Error creating subtask:', error);
    }
  };


  const getResolutionTypeStyle = (resolutionType?: string) => {
    switch (resolutionType) {
      case 'APROBADO':
        return 'bg-green-100 text-green-800';
      case 'RECHAZADO':
        return 'bg-red-100 text-red-800';
      case 'DESISTIMIENTO':
        return 'bg-orange-100 text-orange-800';
      case 'SILENCIO ADMINISTRATIVO POSITIVO':
        return 'bg-blue-100 text-blue-800';
      case 'SILENCIO ADMINISTRATIVO NEGATIVO':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  


  return (
    <>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
        className={`p-4 rounded-xl mb-3 rounded-lg border ${config.bg} ${config.border} transition-all duration-200 cursor-pointer group`}
        onClick={(e) => {
          e.preventDefault();
          onTaskClick(task);
        }}
        style={task.isClosed && config.style ? config.style() : {}}
      >
        <div className={`flex items-center p-3 rounded-xl ${task.isClosed ? 'bg-white' : ''}`}>
        {task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0 && (
            <button 
              onClick={handleExpand} 
              className="mr-2 hover:bg-gray-200 rounded-full p-1 transition-colors"
            >
              {isExpanded ? 
                <ChevronDown size={30} className="text-gray-600" /> : 
                <ChevronRight size={30} className="text-gray-600" />
              }
            </button>
          )}
          <div className={`mr-2 ${config.text}`}>{config.icon}</div>
          <div className="flex-1">
            <div className={`font-medium ${config.text} text-sm`}>{task.nombre}</div>
            <div className="flex items-center justify-between mt-1">
              <span className={`text-xs ${config.text} opacity-75`}>{config.label}</span>
              <span className={`text-xs ${config.text} opacity-75`}>{task.progreso}%</span>
            </div>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isExpanded && processedSubtasks && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="ml-8 space-y-2"
          >
{processedSubtasks.map((subtask, index) => {
  const subtaskConfig = getSubtaskSemaphoreConfig(subtask);

  return (
    <motion.div
      key={subtask.id || index}
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -20, opacity: 0 }}
      className={`p-4 mb-3 rounded-lg border ${subtaskConfig.bg} ${subtaskConfig.border} transition-all duration-200 cursor-pointer group`}
      style={subtask.isClosed && subtaskConfig.style ? subtaskConfig.style() : {}}

    >
      <div 
        className={`p-3 rounded-xl ${subtaskConfig.text}`}
        style={{
          ...(subtask.isClosed && subtask.progreso === 100
            ? {
                backgroundColor: 'white',
              }
            : {
                backgroundColor: '', // Fondo blanco para subtareas normales
              }),
        }}
        onClick={(e) => handleSubtaskClick(subtask, e)}
      >
        <div className="flex items-center px-2 space-x-2">
          <div className={`mr-2 ${subtaskConfig.text}`}>
            {subtaskConfig.icon}
          </div>
          <div className="flex items-center space-x-2">
            <span className={`font-medium ${subtaskConfig.text} text-sm`}>
              {subtask.name}
            </span>
            {subtask.resolutionType && (
              <span className={`px-1 py-0.5 rounded text-xs ${getResolutionTypeStyle(subtask.resolutionType)} whitespace-nowrap`}>
                {subtask.resolutionType}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-between mt-1 px-5">
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtaskConfig.label}
          </span>
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtask.progreso}%
          </span>
        </div>
      </div>
    </motion.div>
  );
})}


          </motion.div>
        )}
      </AnimatePresence>
      {activeSubtask && createPortal(
        <SubtaskModal
          isOpen={activeSubtask !== null}
          onClose={() => setActiveSubtask(null)}
          subtask={activeSubtask}
          onChangeSubtask={handleChangeSubtask}
          onAddNewSubtask={handleAddNewSubtask}
          backgroundColor={color}
          mainTask={task}
          groupProgress={task.progreso}
        />,   document.body

      )}
    </>
    
  );
 
});


const getTaskStatus = (task: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  if (task.isClosed) return 'success';
  if (task.progreso > 0) return 'inProgress';
  return 'pending';
};


const convertSemaphoreStatus = (status: SemaphoreStatus): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  switch (status) {
    case SemaphoreStatus.Green:
      return 'inProgress';
    case SemaphoreStatus.Yellow:
      return 'warning';
    case SemaphoreStatus.Orange:
      return 'warning';
    case SemaphoreStatus.Red:
      return 'danger';
    case SemaphoreStatus.Gray:
    default:
      return 'pending';
  }
};




interface TaskColumnProps {
  title: string;
  tasks: Task[];
  onChangeTask: (task: Task) => void;
  color: string;
  onTaskClick: (task: Task) => void; // Nueva prop

}





const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Proceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        Este proceso no contiene información, por favor revise la planificación.
      </p>
    </div>
  );
};




const PlanningPanel: React.FC<ProjectTimelineProps> = ({ 
  selectedProcess, 
  setSelectedProcess, 
  processes, 
  subprocessColor,
  onUpdateTask
}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const previousProcessRef = useRef<string | null>(null);
  const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [collapsedStates, setCollapsedStates] = useState<Record<string, boolean>>({});

  
  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
          resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field

        })
      });
      console.log(JSON.stringify(subtaskData))
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };

  // Función para crear una nueva subtarea en la base de datos
  const createSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'create',
          subtask: subtaskData
          
        })
      });
      

      if (!response.ok) {
        throw new Error('Error al crear la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en createSubtaskInDB:', error);
      throw error;
    }
  };


  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    taskId: number, 
    dependencia: string | number | null, 
    isClosed: boolean,
    allTasks?: Task[],
    parentTask?: Task
  ): SemaphoreStatus => {
    // Si la tarea está cerrada, retornar gris
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Convertir fechas de string a Date
    const [startYear, startMonth, startDay] = start? (start.split('-').map(Number)): [0, 0 ,0];
    const startDate = new Date(startYear, startMonth - 1, startDay);
    startDate.setHours(0, 0, 0, 0);
  
    const [endYear, endMonth, endDay] = end? (end.split('-').map(Number)): [0, 0 ,0];
    const endDate = new Date(endYear, endMonth - 1, endDay);
    endDate.setHours(0, 0, 0, 0);
  
    // Para subtareas
    if (parentTask) {
      // Si es una subtarea de TRAMITACIÓN
      if (parentTask.nombre === 'TRAMITACIÓN') {
        // Si el padre está en gris o la fecha de inicio es futura, la subtarea está en gris
        if (parentTask.semaphoreStatus === SemaphoreStatus.Gray || startDate > today) {
          return SemaphoreStatus.Gray;
        }
        
        // Si el padre está activo (verde, amarillo o naranja)
        if (startDate <= today) {
          const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
          
          if (today > endDate) {
            return SemaphoreStatus.Red; // Vencida
          } else if (daysUntilEnd <= 2) {
            return SemaphoreStatus.Orange; // Por vencer (2 días o menos)
          } else if (daysUntilEnd <= 5) {
            return SemaphoreStatus.Yellow; // Próxima a vencer (5 días o menos)
          }
          return SemaphoreStatus.Green; // En tiempo
        }
        return SemaphoreStatus.Gray; // No iniciada
      }
    }
  
    // Para tareas principales y otras subtareas
    if (today > endDate) {
      return SemaphoreStatus.Red; // Vencida
    }
  
    if (startDate <= today) {
      const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      
      if (daysUntilEnd <= 2) {
        return SemaphoreStatus.Orange; // Por vencer (2 días o menos)
      } else if (daysUntilEnd <= 5) {
        return SemaphoreStatus.Yellow; // Próxima a vencer (5 días o menos)
      }
      return SemaphoreStatus.Green; // En tiempo
    }
  
    // Si la fecha de inicio aún no llega
    return SemaphoreStatus.Gray;
  };
  const handleColumnDragStart = (agrupadorId: string) => {
    setDraggedColumnId(agrupadorId);
  };

  const handleColumnDragEnter = (targetAgrupadorId: string) => {
    if (draggedColumnId && draggedColumnId !== targetAgrupadorId) {
      const updatedAgrupadores = [...currentAgrupadores];
      const draggedIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === draggedColumnId);
      const targetIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === targetAgrupadorId);
      
      if (draggedIndex !== -1 && targetIndex !== -1) {
        // Intercambiar posiciones
        [updatedAgrupadores[draggedIndex], updatedAgrupadores[targetIndex]] = 
        [updatedAgrupadores[targetIndex], updatedAgrupadores[draggedIndex]];
        
        setCurrentAgrupadores(updatedAgrupadores);
      }
    }
  };

  const handleColumnDragEnd = () => {
    setDraggedColumnId(null);
  };

  const updateAgrupadorOrder = async (agrupadorId: number, newOrder: number) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_order.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: agrupadorId,
          orden: newOrder
        }),
      });
      console.log(2)
      if (!response.ok) {
        throw new Error('Error al actualizar el orden');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error al actualizar el orden del agrupador:', error);
      throw error;
    }
  };

  
  
  const ColumnsContainer: React.FC<{
    agrupadores: Agrupador[];
    onReorder: (newOrder: Agrupador[]) => void;
    color: string;
    onTaskClick: (task: Task) => void;
    onChangeTask: (task: Task) => void;
  }> = ({ agrupadores, onReorder, color, onTaskClick, onChangeTask }) => {
    const [orderedAgrupadores, setOrderedAgrupadores] = useState(

      [...agrupadores].sort((a, b) => a.orden - b.orden)
    );
  
    const previousOrderRef = useRef<Agrupador[]>(orderedAgrupadores);
    const previousColorRef = useRef(color);
    const [isExpanded, setIsExpanded] = useState(true);
    const containerRef = React.useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    const [collapsedStates, setCollapsedStates] = useState<Record<string, boolean>>({});
    const [contentHeights, setContentHeights] = useState<Record<string, number>>({});
    const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});
  
    // Update heights when content changes
    const updateHeight = useCallback((id: string) => {
      const element = contentRefs.current[id];
      if (element) {
        setContentHeights(prev => ({
          ...prev,
          [id]: element.scrollHeight
        }));
      }
    }, []);
  
    // Update height on content change
    useEffect(() => {
      Object.keys(contentRefs.current).forEach(id => {
        updateHeight(id);
      });
    }, [orderedAgrupadores, updateHeight]);
  

    const contentVariants = {
      expanded: { 
        height: 'auto',
        opacity: 1,
        transition: {
          duration: 0.3,
          ease: "easeInOut"
        }
      },
      collapsed: { 
        height: 0,
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: "easeInOut"
        }
      }
    };

    const containerVariants = {
      expanded: { height: "auto", opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
      collapsed: { height: 0, opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } },
    };
    
    
    const headerVariants = {
      expanded: {
        backgroundColor: "#FFFFFF", // Cambia al color que quieras cuando está expandido
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        transition: { duration: 0.3, ease: "easeInOut" },
      },
      collapsed: {
        backgroundColor: "#F3F4F6", // Cambia al color que quieras cuando está colapsado
        boxShadow: "none",
        transition: { duration: 0.3, ease: "easeInOut" },
      },
    };
    

    // Función para actualizar la subtarea en la base de datos
  


    // Función para obtener el color del semáforo
    const getSemaphoreColor = (status: SemaphoreStatus): string => {
      switch (status) {
        case SemaphoreStatus.Green:
          return 'bg-green-100 hover:bg-green-200 border-green-300 text-green-800';
        case SemaphoreStatus.Yellow:
          return 'bg-yellow-100 hover:bg-yellow-200 border-yellow-300 text-yellow-800';
        case SemaphoreStatus.Orange:
          return 'bg-orange-100 hover:bg-orange-200 border-orange-300 text-orange-800';
        case SemaphoreStatus.Red:
          return 'bg-red-100 hover:bg-red-200 border-red-300 text-red-800';
        case SemaphoreStatus.Gray:
        default:
          return 'bg-gray-100 hover:bg-gray-200 border-gray-300 text-gray-800';
      }
    };
  
    const updateOrdersInDatabase = async (updatedAgrupadores: Agrupador[]) => {
      try {
        // Preparar los datos para enviar en un solo request
        const orderUpdates = updatedAgrupadores.map((agrupador, index) => ({
          id: agrupador.id,
          orden: index + 1
        }));
  
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_order.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ updates: orderUpdates })
        });
       
        if (!response.ok) {
          throw new Error('Failed to update orders');
        }
  
        const result = await response.json();
        console.log('Órdenes actualizados en BD:', orderUpdates);
        
      } catch (error) {
        console.error('Error al actualizar los órdenes:', error);
      }
    };
  
    // Handler para el reordenamiento visual mientras se arrastra
    const handleReorder = (reorderedItems: Agrupador[]) => {
      setOrderedAgrupadores(reorderedItems);
      
      // Comparar el orden actual con el anterior
      const hasOrderChanged = JSON.stringify(reorderedItems.map(a => a.id)) !== 
                            JSON.stringify(previousOrderRef.current.map(a => a.id));
  
      if (hasOrderChanged) {
        // Actualizar el orden en la base de datos solo si ha cambiado
        const updatedAgrupadores = reorderedItems.map((agrupador, index) => ({
          ...agrupador,
          orden: index + 1
        }));
  
        updateOrdersInDatabase(updatedAgrupadores);
        onReorder(updatedAgrupadores);
        previousOrderRef.current = reorderedItems;
      }
    };
  
    // Handler para cuando se suelta el elemento
    const handleDragEnd = () => {
      const updatedAgrupadores = orderedAgrupadores.map((agrupador, index) => ({
        ...agrupador,
        orden: index + 1
      }));
  console.log(updatedAgrupadores)
      // Actualizar estado local con los nuevos órdenes
      setOrderedAgrupadores(updatedAgrupadores);
      onReorder(updatedAgrupadores);
      
      // Actualizar la base de datos
      updateOrdersInDatabase(updatedAgrupadores);
    };

    const lightenColor = (color: string, amount: number): string => {
      return '#' + color.replace(/^#/, '').replace(/../g, color => 
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      );
    };
  
    useEffect(() => {
      previousColorRef.current = color;
    }, [color]);
  

    React.useEffect(() => {
      if (containerRef.current) {
        const container = containerRef.current;
      }
    }, [agrupadores]);

    return (
      <Reorder.Group
      as="div"
      axis="x" 
      values={orderedAgrupadores}
      onReorder={handleReorder}
      className="flex gap-4 p-4 overflow-x-auto"
      layoutScroll
    >
         {orderedAgrupadores.map((agrupador) => (
      <Reorder.Item
        key={agrupador.id}
        value={agrupador}
        layoutId={`agrupador-${agrupador.id}`}
        className="list-none"
        initial={false} // Remove initial animation
        animate={{ scale: 1 }} // Simplify animation
        transition={{
          type: "tween",
          duration: 0.2
        }}
      >
    <motion.div
          className="w-80 shadow-xl rounded-xl border my-1 overflow-hidden"
          initial={false}
          animate={{
            backgroundColor: lightenColor(color, 80),
            borderColor: color,
          }}
          style={{
            borderWidth: '1px'
          }}
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          {/* Header */}
          <motion.div 
              className="p-4 cursor-pointer"
              onClick={() => {
                setCollapsedStates(prev => ({
                  ...prev,
                  [agrupador.id]: !prev[agrupador.id]
                }));
                updateHeight(agrupador.id.toString());
              }}
            >
        {/* Header */}
    
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-gray-700">
              {agrupador.nombre}
            </h3>
            <motion.div
      animate={{ 
        rotate: collapsedStates[agrupador.id] ? 0 : 180,
      }}
      transition={{
        type: "spring",
        stiffness: 200,
        damping: 15
      }}
    >
      <ChevronDown size={20} style={{ color }} />
    </motion.div>

          </div>
          <span className="text-sm text-gray-500 flex items-center gap-1">
            <Building size={14} />
            {agrupador.organismo}
          </span>
        </motion.div>
        {/* Contenido colapsable */}
        <motion.div
  animate={{
    height: collapsedStates[agrupador.id] ? 0 : "auto",
    opacity: collapsedStates[agrupador.id] ? 0 : 1,
  }}
  initial={false}
  transition={{
    height: {
      duration: 0.4,
      ease: [0.32, 0.72, 0, 1] // Custom easing curve
    },
    opacity: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }}
  className="overflow-hidden"
>
    
                <div className="px-4 pb-4 space-y-3">
                  {agrupador.tareas.map((task) => {
                    const semaphoreStatus = calculateSemaphoreStatus(
                      task.fecha_inicio,
                      task.fecha_termino,
                      task.id,
                      task.dependencia,
                      task.isClosed,
                      orderedAgrupadores.flatMap(ag => ag.tareas)
                    );
                    
                    return (
                      <TaskItem
                        key={task.id}
                        task={{
                          ...task,
                          semaphoreStatus
                        }}
                        status={convertSemaphoreStatus(semaphoreStatus)}
                        onChangeTask={onChangeTask}
                        onTaskClick={onTaskClick}
                        color={color}
                      />
                    );
                  })}
                </div>
              </motion.div>
        </motion.div>

          
      </Reorder.Item> 
    ))}
  </Reorder.Group>
  );
};
  
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };


  // Agregar estados para el modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

// Frontend: Update the handleTaskChange function
const handleTaskChange = useCallback(async (updatedTask: Task) => {
  try {
    // Create the payload with correct field mappings
    const updatePayload = {
      id: updatedTask.id,
      name: updatedTask.nombre,
      isClosed: updatedTask.isClosed ? 1 : 0,
      progress: updatedTask.progreso,
      start: updatedTask.fecha_inicio,
      end: updatedTask.fecha_termino,
      followUpDate: updatedTask.followUpDate || null,
      semaphoreStatus: updatedTask.semaphoreStatus,
      delayInDays: updatedTask.delayInDays || 0,
      comments: updatedTask.comments || null
    };

    // Update local state first
    const newAgrupadores = currentAgrupadores.map(agrupador => ({
      ...agrupador,
      tareas: agrupador.tareas.map(tarea => 
        tarea.id === updatedTask.id ? updatedTask : tarea
      )
    }));
    
    setCurrentAgrupadores(newAgrupadores);

    // Send update to backend
    const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatePayload)
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    
    if (!result.success) {
      throw new Error(result.error || 'Error updating task');
    }

    // Notify parent if callback exists
    if (onUpdateTask) {
      onUpdateTask(updatedTask);
    }

    // Update subtasks if they exist
    if (updatedTask.subtasks) {
      for (const subtask of updatedTask.subtasks) {
        if (subtask.id) {
          await updateSubtaskInDB(subtask);
        }
      }
    }

  } catch (error) {
    console.error('Error updating task:', error);
    toast.error('Error al actualizar la tarea');
    
    // Revert local state on error
    const originalAgrupadores = currentAgrupadores.map(agrupador => ({
      ...agrupador,
      tareas: agrupador.tareas.map(tarea => 
        tarea.id === updatedTask.id ? tarea : tarea
      )
    }));
    setCurrentAgrupadores(originalAgrupadores);
  }
}, [currentAgrupadores, onUpdateTask]);


  // Agregar manejadores para el modal
  const handleTaskClick = (task: Task) => {
    // Encontrar el agrupador que contiene la tarea
    const agrupador = currentAgrupadores.find(ag => 
      ag.tareas.some(t => t.id === task.id)
    );
    
    // Calcular el progreso del grupo
    const groupProgress = calculateAverageProgress(agrupador?.tareas || []);
    
    // Calcular días de retraso
    const daysDelayed = calculateDaysDelayed(task);

    // Obtener todas las tareas para las dependencias
    const allTasks = currentAgrupadores.flatMap(ag => ag.tareas);

    // Configurar la tarea seleccionada
    setSelectedTask(task);
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };



  // Función para cargar los datos del subproceso seleccionado
  const loadSubprocessData = useCallback((subprocess: Subproceso) => {
    if (!subprocess || !allAgrupadores) return;
    
    const agrupadoresForSubprocess = allAgrupadores.filter(
      agrupador => agrupador.subproceso === subprocess.subproceso
    );
    setCurrentAgrupadores(agrupadoresForSubprocess);
  }, [allAgrupadores]);

  // Efecto para inicializar los datos cuando se cargan los subprocesos
  useEffect(() => {
    if (!filteredSubprocesos || !allAgrupadores) return;
    if (filteredSubprocesos.length === 0 || allAgrupadores.length === 0) return;
  
    try {
      // Intentar recuperar el subproceso guardado
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      let targetIndex = 0;
      
      if (savedSubprocess) {
        const foundIndex = filteredSubprocesos.findIndex(sp => 
          sp && sp.subproceso && sp.subproceso === savedSubprocess
        );
        if (foundIndex !== -1) {
          targetIndex = foundIndex;
        }
      }
  
      // Validar que el subproceso objetivo existe
      const targetSubprocess = filteredSubprocesos[targetIndex];
      if (!targetSubprocess || !targetSubprocess.subproceso) {
        console.error('Subproceso objetivo no válido');
        return;
      }
  
      // Filtrar agrupadores válidos
      const agrupadoresForSubprocess = allAgrupadores.filter(
        agrupador => agrupador && agrupador.subproceso === targetSubprocess.subproceso
      );
  
      // Actualizar estados
      setActiveSubprocess(targetIndex);
      setCurrentAgrupadores(agrupadoresForSubprocess);
  
      // Guardar en localStorage solo si es válido
      if (targetSubprocess.subproceso) {
        localStorage.setItem('selectedSubprocess', targetSubprocess.subproceso);
      }
    } catch (error) {
      console.error('Error al inicializar subproceso:', error);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  // Efecto para guardar el subproceso seleccionado


  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);

  const resetSubprocessState = () => {
    setActiveSubprocess(0);
    setCurrentAgrupadores([]);
    localStorage.removeItem('selectedSubprocessId');
  };


  const loadProcessData = async (processName: string) => {
    setIsLoading(true);
  
    if (processName !== previousProcessRef.current) {
      if (previousProcessRef.current !== null) {
        resetSubprocessState();
      }
    } else {
      setIsLoading(false);
      return;
    }
  
    if (processName === "") {
      resetState();
      setIsLoading(false);
      return;
    }
  
    const selectedProcess = processes.find(p => p.nombreProceso === processName);
    if (selectedProcess) {
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);
      
      try {
        const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        // Filtrar solo los subprocesos con agrupadores habilitados
        const fetchedSubprocesos: Subproceso[] = data.subprocesos
          .filter((sp: any) => sp.agrupadores.some((agr: any) => agr.enabled))
          .map((sp: any) => ({
            id: sp.id,
            subproceso: sp.nombre,
            color: sp.color || getDefaultColor(sp.id)
          }));
        setSubprocesos(fetchedSubprocesos);
  
        // Procesar los agrupadores
        const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: any, spIndex: number) =>
          subproceso.agrupadores
            .filter((agrupador: any) => agrupador.enabled)
            .map((agrupador: any, agrIndex: number) => ({
              id: agrupador.id || (spIndex * 1000 + agrIndex),
              nombre: agrupador.nombre,
              fecha_inicio: formatDate(getEarliestStartDate(agrupador.tareas)),
              fecha_termino: formatDate(getLatestEndDate(agrupador.tareas)),
              progreso: calculateAverageProgress(agrupador.tareas),
              subproceso: subproceso.nombre,
              enabled: agrupador.enabled,
              descriptor: agrupador.descriptor,
              organismo: agrupador.organismo,
              tareas: agrupador.tareas.map((tarea: any) => ({
                ...tarea,
                nombre: tarea.nombre,
                responsable: tarea.responsable,
                progreso: tarea.progreso,
                fecha_inicio: tarea.fecha_inicio,
                fecha_termino: tarea.fecha_termino,
                duracion: tarea.duracion,
                organismo: tarea.organismo,
                enabled: tarea.enabled,
                isClosed: Boolean(tarea.isClosed),
                dependencia: tarea.dependencia,
                subtasks: tarea.subtasks ? tarea.subtasks.map((subtask: any) => ({
                  id: subtask.id,
                  name: subtask.nombre,
                  responsible: subtask.responsable,
                  progreso: subtask.progreso,
                  start: subtask.fecha_inicio,
                  end: subtask.fecha_termino,
                  duration: subtask.duracion,
                  organismo: tarea.organismo,
                  enabled: subtask.enabled,
                  type: subtask.tipo,
                  resolutionType: subtask.resolucion_tipo,
                  orden: subtask.orden,
                  comments: subtask.comments || '',
                  followUpDate: subtask.followUpDate || '',
                  followUp: Boolean(subtask.followUp),
                  isClosed: Boolean(subtask.isClosed),
                  delayInDays: 0,
                  semaphoreStatus: SemaphoreStatus.Gray
                })) : []
              })),
              orden: agrupador.orden
            }))
        );
  
        console.log(JSON.stringify(allAgrupadores))
        setAllAgrupadores(allAgrupadores);
  
        // Filtrar los subprocesos que tienen agrupadores
        const filteredSubs = fetchedSubprocesos.filter(subproceso => 
          allAgrupadores.some(agrupador => agrupador.subproceso === subproceso.subproceso)
        );
        setFilteredSubprocesos(filteredSubs);
  
        // Ahora el useEffect se encargará de inicializar los datos correctamente
        setIsProcessLoaded(true);
  
      } catch (error) {
        console.error('Error al cargar el proceso:', error);
        resetState();
      }
    }
    previousProcessRef.current = processName;
    setIsLoading(false);
  };
  
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  const getEarliestStartDate = (tareas: Task[]): string => {
    return tareas.reduce((earliest, tarea) => 
      tarea.fecha_inicio < earliest ? tarea.fecha_inicio : earliest
    , tareas[0]?.fecha_inicio || '');
  };

  const getLatestEndDate = (tareas: Task[]): string => {
    return tareas.reduce((latest, tarea) => 
      tarea.fecha_termino > latest ? tarea.fecha_termino : latest
    , tareas[0]?.fecha_termino || '');
  };

  const calculateAverageProgress = (tareas: Task[]): number => {
    if (tareas.length === 0) return 0;
    const totalCompletedTasks = tareas.reduce((sum, tarea) => sum + (tarea.isClosed ? 1 : 0), 0);
    return Math.round((totalCompletedTasks / tareas.length) * 100);
  };

  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };
  
  const calculateDaysDelayed = (task: Task): number => {
    const endDate = new Date(task.fecha_termino);
    const today = new Date();
    return Math.max(0, Math.floor((today.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)));
  };
  
  const calculateGroupProgress = (task: Task): number => {
    const agrupador = currentAgrupadores.find(a => a.tareas.some(t => t.id === task.id));
    return agrupador ? agrupador.progreso : 0;
  };
  
  
  const allTasks = currentAgrupadores.flatMap(agrupador => agrupador.tareas);

  const daysDelayed = selectedTask ? calculateDaysDelayed(selectedTask) : 0;

  const inputColor = filteredSubprocesos[activeSubprocess]?.color || subprocessColor;
  const agrupadorId = allAgrupadores.find(agrupador => 
    agrupador.tareas.some(t => t.id === selectedTask?.id)
  )?.id || 0; // Devuelve 0 si no se encuentra el agrupador
  
  const groupProgress = selectedTask ? calculateGroupProgress(selectedTask) : 0;


const handleSubprocessChange = (index: number) => {
  const selectedSubprocess = filteredSubprocesos[index];
  if (!selectedSubprocess) return;

  // Obtener agrupadores para el subproceso seleccionado
  const agrupadoresForSubprocess = allAgrupadores.filter(
    agrupador => agrupador.subproceso === selectedSubprocess.subproceso
  );

  // Actualizar estados
  setActiveSubprocess(index);
  setCurrentAgrupadores(agrupadoresForSubprocess);

  // Guardar en localStorage
  localStorage.setItem('selectedSubprocess', selectedSubprocess.subproceso);
};

  // Mantiene las columnas originales pero ahora puede usar los datos de la BD
  const getTaskStatus = (tarea: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
    if (tarea.isClosed) return 'success';
    if (tarea.progreso > 0) return 'inProgress';
    return 'pending';
  };

  const tableVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };

  const containerVariants = {
    expanded: {
      height: "auto",
      opacity: 1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    collapsed: {
      height: 0,
      opacity: 0,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white w-full rounded-lg p-3 mx-auto"
    >
      {isLoading ? (
        <div>Cargando...</div>
      ) : isProcessLoaded && filteredSubprocesos.length > 0 ? (
        <>
          <div className="flex mb-8 ml-8 mt-3">
            {filteredSubprocesos.map((subprocess, index) => (
              <SubprocessTab
                key={index}
                label={subprocess.subproceso}
                isActive={activeSubprocess === index}
                onClick={() => handleSubprocessChange(index)}
                color={subprocess.color}
                index={index}
                totalTabs={filteredSubprocesos.length}
                activeIndex={activeSubprocess}
              />
            ))}
          </div>
          
          {currentAgrupadores.length > 0 ? (
  <Reorder.Group
    as="div"
    axis="x"
    values={currentAgrupadores.map(ag => ag.id.toString())}
    onReorder={(newOrder) => {
      const reorderedAgrupadores = newOrder.map(id => 
        currentAgrupadores.find(ag => ag.id.toString() === id)!
      );
      setCurrentAgrupadores(reorderedAgrupadores);
    }}
  
    className="overflow-x-auto w-full"
  >
    
   <ColumnsContainer
      agrupadores={currentAgrupadores}
      onReorder={setCurrentAgrupadores}
      color={filteredSubprocesos[activeSubprocess]?.color || subprocessColor}
      onTaskClick={handleTaskClick}
      onChangeTask={handleTaskChange}
    />
  </Reorder.Group>
) : (
  <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
)}
        </>
      ) : (
        <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
      )}
       {/* Agregar el modal */}
       {selectedTask && (
      <TaskModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal}        
        task={selectedTask}
        onChangeTask={handleTaskChange} // Pasamos la función aquí
        backgroundColor={inputColor}
        allTasks={allTasks}
        agrupadorId={selectedTask.id}
        daysDelayed={daysDelayed}
        groupProgress={groupProgress}
        processName={selectedNombreProyecto}
        subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso}
        agrupadorName={currentAgrupadores.find(ag => 
          ag.tareas.some(t => t.id === selectedTask.id)
        )?.nombre}
      />
    )}
    </motion.div>
  );
};

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
  
}
  
  interface SubprocessTabProps {
    label: string;
    isActive: boolean;
    onClick: () => void;
    color: string;
    index: number;
    totalTabs: number;
    activeIndex: number;
  }
  
  const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
    label, 
    isActive, 
    onClick, 
    color, 
    index, 
    totalTabs
  }) => {
    const [scale, setScale] = useState(1);
    const activeScale = 1.185;
    const fixedOffset = 20;
  
    useEffect(() => {
      if (isActive) {
        setScale(activeScale);
      } else {
        setScale(1);
      }
    }, [isActive]);
  
    const buttonStyle: React.CSSProperties = {
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      padding: '0 30px 0 30px',
      fontSize: '14px',
      fontWeight: 'medium',
      color: isActive ? '#ffffff' : '#115E59',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      overflow: 'visible',
      transition: 'all 0.3s',
      marginRight: `-${fixedOffset}px`,
      zIndex: isActive ? totalTabs : index,
      whiteSpace: 'nowrap',
    };
  
    const shapeStyle: React.CSSProperties = {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: color,
      opacity: isActive ? 1 : 0.3,
      transition: 'opacity 0.3s',
      clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
    };
  
    return (
      <motion.button
        onClick={onClick}
        className="relative"
        animate={{ 
          scale,
          zIndex: isActive ? totalTabs : index,
        }}
        whileHover={{ scale: scale * 1.02 }}
        whileTap={{ scale: scale * 0.98 }}
        transition={{ duration: 0.2 }}
        style={buttonStyle}
      >
        <div style={shapeStyle} />
        <span className="relative z-10 px-2">{label}</span>
      </motion.button>
    );
  };
  
  interface ProjectTimelineProps {
    selectedProcess: string;
    setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
    processes: Process[];
    subprocessColor: string;
    onUpdateTask?: (updatedTask: Task) => void;

  }
  
  export default PlanningPanel;